import React, { useRef, useState } from "react";
import { range } from "../../engine/elements";
import { GameEntity } from "../../engine/entities";
import { GamePosition } from "../../engine/position";
import { useRoomState } from "../../engine/roomState";
import { ViewEntities } from "../../engine/views";
import { CircleSelectorObjectS } from "../../objects/circle";
import { RectObjectS } from "../../objects/rect";
import { TransformObjectS } from "../../objects/transform";
import { TriangleObjectS } from "../../objects/triangle";
import { backgammonColors } from "./data";
import { BackgammonAction } from "./resolvers";
import { backgammonAreas as areas, backgammonElementTypes as types, BackgammonState } from "./shared";
import { backgammonCanMoveFrom, backgammonCanMoveTo, backgammonGetMaxDist } from "./utils";

export const useBackgammonPlayerButtonsS: ViewEntities = (generator, { playerIndex }) => {
  const [s, dispatch] = useRoomState<BackgammonState, BackgammonAction>();
  const [selection, setSelection] = useState(-1);
  const selected = selection !== -1;
  const timeoutRef = useRef<any>();

  if (s.phase === "INIT" || s.pending) return [];

  if (playerIndex === "all") playerIndex = s.data.currentPlayer;

  if (s.data.currentPlayer !== playerIndex) return [];

  const entities: GameEntity[] = [];

  const dices = s
    .on("DICES")
    .on("BOARD")
    .getElements()
    .forceType(types.dice)
    .excludeData({ played: 1 })
    .mapData("value");

  if (dices[1] === dices[0]) dices.pop();

  const dist = backgammonGetMaxDist(s, playerIndex);

  let canPlay = false;
  for (const fromIndex of range(26)) {
    if (selected && selection !== fromIndex) continue;
    const [canMoveFrom, fromCount] = backgammonCanMoveFrom(s, playerIndex, fromIndex, dist);
    let playable = false;
    if (canMoveFrom) {
      for (const dice of dices) {
        const toIndex = playerIndex ? fromIndex + dice : fromIndex - dice;
        const [canMoveTo, toCount] = backgammonCanMoveTo(s, playerIndex, fromIndex, toIndex, dist);
        if (canMoveTo) {
          playable = true;
          if (selected) {
            entities.push(
              toCount === -1
                ? generator.fromPlacement(
                    `from_${fromIndex}_${fromCount}_to_${toIndex}_${toCount}`,
                    areas.out.getPlacement([playerIndex, -0.5]),
                    <CircleSelectorObjectS
                      size={77}
                      onPress={() => {
                        dispatch({ type: "MOVE", fromIndex, toIndex, playerIndex });
                        setSelection(-1);
                      }}
                      visible
                    />,
                    s.movingIndex,
                  )
                : generator.fromPlacement(
                    `from_${fromIndex}_${fromCount}_to_${toIndex}_${toCount}`,
                    areas.board.getPlacement([toIndex], toCount),
                    <CircleSelectorObjectS
                      size={70}
                      onPress={() => {
                        dispatch({ type: "MOVE", fromIndex, toIndex, playerIndex });
                        setSelection(-1);
                      }}
                      visible
                    />,
                    s.movingIndex,
                  ),
            );
          }
        }
      }
    }
    if (playable) {
      canPlay = true;
      entities.push(
        generator.fromPlacement(
          `from_${fromIndex}_${fromCount}`,
          areas.board.getPlacement([fromIndex], fromCount - 1),
          <CircleSelectorObjectS
            size={70}
            selected={selection === fromIndex}
            onPress={() => {
              setSelection(selection === fromIndex ? -1 : fromIndex);
            }}
            visible
          />,
          s.movingIndex,
        ),
      );
    } else if (selected) {
      setSelection(-1);
    }
  }

  if (timeoutRef.current) {
    clearTimeout(timeoutRef.current);
  }
  if (!canPlay) {
    timeoutRef.current = setTimeout(() => dispatch({ type: "NO_MOVE", playerIndex }), 1000);
  }
  return entities;
};

// background

export const useBackgammonBackgroundS: ViewEntities = () => {
  return [
    {
      _id: "background",
      position: new GamePosition(0, 0),
      content: backGammonBoard,
    },
  ];
};

const boardColor1 = "#666666";
const boardColor2 = "#999999";
const backHeight = 900;
const backBorder = 30;
const backPadding = 10;
const sectionWidth = 90;
const backWidth = 14 * sectionWidth + 5 * backBorder + 4 * backPadding;

const getBackTriangle = (index: number) => (
  <TransformObjectS key={index} x={index * sectionWidth}>
    <TriangleObjectS fill={backgammonColors[index % 2]} width={sectionWidth} height={backHeight / 3} />
  </TransformObjectS>
);

const backGammonBoard = (
  <>
    <RectObjectS width={backWidth} height={backHeight} radius={8} fill={boardColor1} />
    <TransformObjectS x={-3 * sectionWidth - 0.5 * backBorder - backPadding}>
      <RectObjectS
        width={6 * sectionWidth + 2 * backPadding}
        height={backHeight - 2 * backBorder}
        radius={4}
        fill={boardColor2}
      />
    </TransformObjectS>
    <TransformObjectS x={3 * sectionWidth + 0.5 * backBorder + backPadding}>
      <RectObjectS
        width={6 * sectionWidth + 2 * backPadding}
        height={backHeight - 2 * backBorder}
        radius={4}
        fill={boardColor2}
      />
    </TransformObjectS>
    <TransformObjectS x={6.5 * sectionWidth + 1.5 * backBorder + 2 * backPadding}>
      <RectObjectS width={sectionWidth} height={backHeight - 2 * backBorder} radius={4} fill={boardColor2} />
    </TransformObjectS>
    <TransformObjectS x={-6.5 * sectionWidth - 1.5 * backBorder - 2 * backPadding}>
      <RectObjectS width={sectionWidth} height={backHeight - 2 * backBorder} radius={4} fill={boardColor2} />
    </TransformObjectS>
    <TransformObjectS
      x={-backBorder / 2 - 5.5 * sectionWidth - backPadding}
      y={-backHeight / 3 + backBorder + backPadding}
    >
      {range(6).map(getBackTriangle)}
    </TransformObjectS>
    <TransformObjectS
      x={+backBorder / 2 + 0.5 * sectionWidth + backPadding}
      y={-backHeight / 3 + backBorder + backPadding}
    >
      {range(6).map(getBackTriangle)}
    </TransformObjectS>
    <TransformObjectS
      x={-backBorder / 2 - 0.5 * sectionWidth - backPadding}
      y={+backHeight / 3 - backBorder - backPadding}
      rotation={180}
    >
      {range(6).map(getBackTriangle)}
    </TransformObjectS>
    <TransformObjectS
      x={+backBorder / 2 + 5.5 * sectionWidth + backPadding}
      y={+backHeight / 3 - backBorder - backPadding}
      rotation={180}
    >
      {range(6).map(getBackTriangle)}
    </TransformObjectS>
  </>
);
