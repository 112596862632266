import React from "react";
import { AreaGetPosition, AreaGetPositionSuperSet, GameArea } from "./area";
import { GameElement } from "./element";
import { GameElementType } from "./elementType";
import { EntityGenerator, GameEntity } from "./entities";
import { useRoomState } from "./roomState";
import { GameState } from "./state";

export type ViewEntities<C = any> = (generator: EntityGenerator, context: C) => GameEntity[];

export interface RoomView {
  _id: string;
  label: string;
  view: GameView;
  context?: object;
}

export class GameView<S extends GameState = any> {
  private areas: Dictionary<AreaGetPosition> = {};
  private entities: ViewEntities[] = [];
  private useContext = (context: any) => context;
  private elementTypesIndex: { [s: string]: React.ComponentType<any> } = {};

  constructor() {
    this.entities.push((generator, context) => {
      const [s] = useRoomState<S>();

      return s.getElementsArray().map((element: GameElement) => {
        const Component = this.elementTypesIndex[element.type];
        const content = <Component {...element} context={context} />;
        return generator.fromPlacement(
          element._id,
          element.placement,
          content,
          element.movingIndex,
          element,
          element.version,
        );
      });
    });
  }

  public fork() {
    const view = new GameView<S>();
    view.areas = { ...this.areas };
    view.entities = [...this.entities];
    view.useContext = this.useContext;
    view.elementTypesIndex = { ...this.elementTypesIndex };
    return view;
  }

  public setElementType(type: GameElementType, component: React.ComponentType<any>) {
    this.elementTypesIndex[type.name] = component;
    return this;
  }

  public setArea(area: GameArea, getPosition: AreaGetPosition) {
    this.areas[area.name] = getPosition;
    return this;
  }

  public superSetArea(area: GameArea, getPosition: AreaGetPositionSuperSet) {
    const previousGetPosition = this.areas[area.name];
    if (!previousGetPosition) throw new Error("No previous GetPosition");
    this.areas[area.name] = (indexes: number[], ctx: any, source: any) => {
      const previousPosition = previousGetPosition(indexes, ctx, source);
      return getPosition(previousPosition, indexes, ctx, source);
    };

    return this;
  }

  public setAllAreasFrom(view: GameView) {
    this.areas = { ...this.areas, ...view.areas };
    return this;
  }

  public setAreaFrom(area: GameArea, view: GameView) {
    this.areas[area.name] = view.areas[area.name];
    return this;
  }

  public addEntities(useEntities: ViewEntities) {
    this.entities.push(useEntities);
    return this;
  }

  public addContext(useContext: GameView["useContext"]) {
    const useParentContext = this.useContext;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    this.useContext = (context: any) => useContext(useParentContext(context));
    return this;
  }

  public useEntities(context = {}) {
    const context_ = this.useContext(context);
    const generator = new EntityGenerator(this.areas, context_);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return this.entities.flatMap((useEntities) => useEntities(generator, context_));
  }
}
