import { set, cloneDeep } from "lodash";
import { GameElement } from "./element";
import { Elements } from "./elements";
import { GamePlacement } from "./entities";
import { RandomState } from "./random";
import { GameAction } from "./resolvers";
import { AnyStack, createAnyStack, MapStackDef } from "./stack2";
import { getFn } from "./utils";

export type GameStateAction =
  | { type: "SET_DATA"; data: any }
  | { type: "UPDATE_DATA"; key: string; value: any }
  | { type: "SET_PHASE"; phase: any }
  | { type: "ADD_ELEMENTS"; elements: GameElement[] }
  | { type: "WAIT"; time: number }
  | { type: "SET_ELEMENT_PLACEMENT"; elementId: string; placement: GamePlacement }
  | { type: "SET_ELEMENT_PLACEMENT_DATA"; elementId: string; index: number; value: number }
  | { type: "SET_ELEMENT_DATA"; elementId: string; data: any };

export interface GameStateObject {
  phase: any;
  data: any;
  elements: any[];
  randomState: RandomState;
}

export interface GameStatePatch {
  action: GameAction;
  subActions: GameStateAction[];
  randomState: RandomState;
}

interface GameStatePatchState {
  patchIndex: number;
  subActionIndex: number;
  waitUntill: number;
}

export class GameState<
  E extends GameElement = any,
  D extends object = any,
  P extends string = any,
  S extends MapStackDef<E, any> = MapStackDef<E, any>
> {
  public fn = getFn(this);

  // current state
  private stacks: AnyStack<S, GameState<E, D, P, S>, E> = {} as any;
  public phase: P = "INIT" as any;
  public data = {} as D;
  public movingIndex = 0;

  // patches
  public pending = false;
  public currentSubActions: GameStateAction[] = [];
  public patchState: GameStatePatchState = {
    patchIndex: -1,
    subActionIndex: -1,
    waitUntill: 0,
  };

  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  declare Element: E;

  private elements: E[] = [];
  public randomState: any;

  constructor(private stack: S, object?: GameStateObject) {
    if (object) {
      this.randomState = object.randomState;
      this.phase = object.phase;
      this.elements = object.elements;
      this.data = object.data;
    }
    this.stacks = createAnyStack(this, this.stack, {}) as any;
  }

  public clone() {
    const object = cloneDeep(this.toObject());
    return new GameState<E, D, P, S>(this.stack, object);
  }

  public toObject(): GameStateObject {
    return {
      randomState: this.randomState,
      phase: this.phase,
      data: this.data,
      elements: this.elements,
    };
  }

  public on<N extends keyof S["config"]["children"]>(
    group: number | N | { name: N },
  ): AnyStack<S["config"]["children"][N], GameState<E, D, P, S>, E> {
    return this.stacks.on(group) as any;
  }

  public reloadStacks() {
    this.stacks = createAnyStack(this, this.stack, {}) as any;
  }

  /** Get an instance of Elements with all the elements */
  public getElements(): Elements<GameState<E, D, P, any>, E> {
    return new Elements<GameState<E, D, P, any>, E>(this, this.elements);
  }

  /** Remove some elements */
  public remove(elements: E[]) {
    elements.forEach((element) => {
      const index = this.elements.indexOf(element);
      this.elements.splice(index, 1);
    });
  }

  /** Get the array of elements, use with caution */
  public getElementsArray() {
    return this.elements;
  }

  /** Set state data, this will be merge with current data */
  public setData(data: Partial<D>) {
    this.dispatch({ type: "SET_DATA", data });
    return this;
  }

  /** Update deep data, see lodash.set */
  public updateData(key: string, value: any) {
    this.dispatch({ type: "UPDATE_DATA", key, value });
    return this;
  }

  /** Set phase */
  public setPhase(phase: string) {
    this.dispatch({ type: "SET_PHASE", phase });
    return this;
  }

  /** Add state elements */
  public addElements(elements: E[]): Elements<this, E> {
    this.dispatch({ type: "ADD_ELEMENTS", elements });
    return new Elements(this, elements);
  }

  /** Add a pause */
  public wait(time = 100) {
    this.dispatch({ type: "WAIT", time });
    return this;
  }

  /** Set element placement */
  public setElementPlacement(element: E, placement: GamePlacement) {
    this.dispatch({ type: "SET_ELEMENT_PLACEMENT", elementId: element._id, placement });
    return this;
  }

  /** Update specific element placement data*/
  public setElementPlacementData(element: E, index: number, value: number) {
    this.dispatch({ type: "SET_ELEMENT_PLACEMENT_DATA", elementId: element._id, index, value });
  }

  /** Set element data, this will be merge with current data */
  public setElementData<EE extends GameElement>(
    element: EE,
    data: E extends { type: EE["type"] } ? Partial<E["data"]> : never,
  ) {
    this.dispatch({ type: "SET_ELEMENT_DATA", elementId: element._id, data });
    return this;
  }

  /** [Internal] apply action */
  public dispatch(action: GameStateAction) {
    this.currentSubActions.push(action);
    this.reducer(action);
    return this;
  }

  private reducer(action: GameStateAction) {
    switch (action.type) {
      case "SET_DATA": {
        const { data } = action;
        Object.assign(this.data, data);
        return this;
      }
      case "UPDATE_DATA": {
        const { key, value } = action;
        set(this.data, key, value);
        return this;
      }
      case "SET_PHASE": {
        const { phase } = action;
        this.phase = phase;
        return this;
      }
      case "ADD_ELEMENTS": {
        const { elements } = action;
        this.elements.push(...(elements as E[]));
        return this;
      }
      case "WAIT": {
        return this;
      }
      case "SET_ELEMENT_PLACEMENT": {
        const { elementId, placement } = action;
        const element = this.elements.find((element) => element._id === elementId)!;
        element.placement = placement;
        element.version++;
        element.movingIndex = this.movingIndex++;
        return this;
      }
      case "SET_ELEMENT_PLACEMENT_DATA": {
        const { elementId, index, value } = action;
        const element = this.elements.find((element) => element._id === elementId)!;
        element.placement.data[index] = value;
        element.version++;
        element.movingIndex = this.movingIndex++;
        return this;
      }
      case "SET_ELEMENT_DATA": {
        const { elementId, data } = action;
        const element = this.elements.find((element) => element._id === elementId)!;
        element.data = { ...element.data, ...data };
        element.version++;
        return this;
      }
      default:
        return this;
    }
  }
}
