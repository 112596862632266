import React, { useState } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { Surface, Card, Divider, Portal, Button } from "react-native-paper";
import { useRoom } from "../../engine/room";
import { ViewerS } from "../../engine/viewerS";
import { LayoutPage } from "../components/Layout";
import { RoomChat } from "../components/RoomChat";
import { RoomToolbar } from "../components/RoomToolbar";
import { AddDeviceDialog } from "../dialogs/AddDeviceDialog";
import { Participants } from "../screens/ParticipantsScreen";
import { paperTheme } from "../theme";

const styles = StyleSheet.create({
  toolbarContainer: {
    flexDirection: "row",
    justifyContent: "center",
    left: 328,
    position: "absolute",
    right: 101,
    top: 28,
  },
  panel: {
    borderRadius: 8,
    bottom: 32,
    overflow: "hidden",
    position: "absolute",
    right: 32,
    top: 100,
    width: 350,
  },
  participants: {
    flexGrow: 1,
  },
  viewer: {
    borderRadius: 8,
    bottom: 32,
    left: 32,
    overflow: "hidden",
    position: "absolute",
    right: 398, //32
    top: 100,
  },
  cardTitle: { color: paperTheme.colors.primary },
  addButton: {
    position: "absolute",
    top: 8,
    right: 8,
  },
});

export const RoomPage = () => {
  return (
    <LayoutPage>
      <View style={styles.toolbarContainer}>
        <RoomToolbar />
      </View>
      <Surface style={styles.viewer}>
        <ViewerS />
      </Surface>
      <Card style={styles.panel}>
        <Card.Title title="Participants" titleStyle={styles.cardTitle}></Card.Title>
        <AddParticipant />
        <Divider />
        <Card.Content style={{ padding: 0, flexGrow: 1 }}>
          <ScrollView style={styles.participants}>
            <Participants />
          </ScrollView>
          <RoomChat />
        </Card.Content>
      </Card>
    </LayoutPage>
  );
};

const AddParticipant = () => {
  const [addVisible, setAddVisible] = useState(false);
  const [{ isHost }] = useRoom();

  if (!isHost) return null;

  return (
    <>
      <Button icon="account-plus-outline" onPress={() => setAddVisible(true)} style={styles.addButton}>
        Ajouter
      </Button>
      <Portal>
        <AddDeviceDialog visible={!!addVisible} onDismiss={() => setAddVisible(false)} />
      </Portal>
    </>
  );
};
