import { MaterialCommunityIcons } from "@expo/vector-icons";
import React, { CSSProperties } from "react";
import { ImageBackground, StyleSheet, View, Platform } from "react-native";
import { Text, TouchableRipple } from "react-native-paper";
import { GameDefinition } from "../../engine/definition";

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },
  cover: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "#ffffff",
  },
  content: {
    width: "100%",
    height: "100%",
  },
  info: {
    position: "absolute",
    bottom: 0,
    height: 56,
    left: 8,
    right: 8,
  },
  infoText: {
    color: "#ffffff",
    fontSize: 16,
  },
  infoDetails: {
    marginTop: 5,
    flexDirection: "row",
    alignItems: "center",
  },
  infoDetailsIcon: {
    color: "#ffffff",
    marginRight: 2,
  },
  infoDetailsText: {
    color: "#ffffff",
    marginRight: 5,
  },
  infoBack: {
    position: "absolute",
    bottom: 0,
    height: 64,
    left: 0,
    right: 0,
    backgroundColor: "#000000",
    opacity: 0.5,
  },
  stage: {
    position: "absolute",
    right: 0,
    top: 0,
    transform: [{ rotate: "45deg" }, { translateX: 15 }, { translateY: -5 }],
    width: 54,
  },
  stageText: {
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "center",
    color: "white",
  },
});

const stageWeb: CSSProperties = {
  position: "absolute",
  right: 0,
  top: 0,
  width: 54,
  textAlign: "center",
  transform: "rotate(45deg) translate(15px,-5px)",
};

export const GameItem = ({ game, onPress }: { game: GameDefinition; onPress? }) => {
  return (
    <View style={styles.container}>
      <ImageBackground source={game.cover} style={styles.cover} resizeMode={"contain"} />
      <View style={styles.infoBack} />
      <View style={styles.info}>
        <Text style={styles.infoText}>{game.label}</Text>
        <View style={styles.infoDetails}>
          <MaterialCommunityIcons name={"account-group"} style={styles.infoDetailsIcon} />
          <Text style={styles.infoDetailsText}>{game.players}</Text>
          <MaterialCommunityIcons name={"timer"} style={styles.infoDetailsIcon} />
          <Text style={styles.infoDetailsText}>{game.timer}</Text>
        </View>
      </View>
      {Platform.OS === "web" ? (
        <div
          style={{
            ...stageWeb,
            backgroundColor: stageColors[game.stage],
          }}
        >
          <Text style={styles.stageText}>{stageTexts[game.stage]}</Text>
        </div>
      ) : (
        <View style={{ ...styles.stage, backgroundColor: stageColors[game.stage] }}>
          <Text style={styles.stageText}>{stageTexts[game.stage]}</Text>
        </View>
      )}
      <TouchableRipple disabled={!onPress} onPress={onPress} delayPressIn={0} style={styles.content}>
        <View style={styles.content} />
      </TouchableRipple>
    </View>
  );
};

const stageColors = ["black", "red", "blue", "green"];
const stageTexts = ["Draft", "Dev", "Alpha", "Beta"];
