import React, { useState } from "react";
import { Platform, StyleSheet } from "react-native";
import { Dialog, Text, Button, IconButton, Portal } from "react-native-paper";
import { GameDefinition } from "../../engine/definition";
import { Link } from "../components/Link";
import { GameVideoDialog } from "./GameVideoDialog";

const styles = StyleSheet.create({
  dialog: { width: "100%", maxWidth: 600, alignSelf: "center" },
});

interface Props {
  visible: boolean;
  onDismiss: () => any;
  game: GameDefinition;
}

export const GameDescriptionDialog = ({ visible, onDismiss, game }: Props) => {
  const [visibleVideo, setVisibleVideo] = useState(false);

  return (
    <Dialog {...{ visible, onDismiss }} style={styles.dialog}>
      <Dialog.Title>{game.label}</Dialog.Title>
      <Dialog.Content>
        {game.description && game.description.map((text, index) => <Text key={index}>{text}</Text>)}
      </Dialog.Content>
      <Dialog.Content style={{ flexDirection: "row", alignContent: "center" }}>
        <IconButton icon="thumb-up" onPress={() => null} style={{ minWidth: 40 }} />
        <IconButton icon="thumb-down" onPress={() => null} style={{ minWidth: 40 }} />
        {game.youtube &&
          (Platform.OS === "web" ? (
            <Button icon="youtube" color="black" onPress={() => setVisibleVideo(true)}>
              Vidéo
            </Button>
          ) : (
            <Link icon="youtube" color="black" href={`https://www.youtube.com/watch?v=${game.youtube}`} target="_blank">
              Règles
            </Link>
          ))}
        {game.rules && (
          <Link icon="file-document" color="black" href={game.rules} target="_blank">
            Règles
          </Link>
        )}
        {game.amazon && (
          <Link icon="cart" color="black" href={"https://www.amazon.fr/dp/" + game.amazon} target="_blank">
            Achat
          </Link>
        )}
        {Platform.OS === "web" && game.youtube && (
          <Portal>
            <GameVideoDialog visible={visibleVideo} onDismiss={() => setVisibleVideo(false)} youtubeId={game.youtube} />
          </Portal>
        )}
      </Dialog.Content>
    </Dialog>
  );
};
