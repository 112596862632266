import React from "react";
import { RectObjectS } from "../../objects/rect";
import { TextObjectS } from "../../objects/text";
import { codeNameColors } from "./data";

export const CodeNameWordObjectS = ({ data: { word, visible, type }, context: { asSpy } }) => {
  return asSpy ? (
    <>
      <RectObjectS
        width={200}
        height={100}
        radius={8}
        fill={codeNameColors[type]}
        stroke="black"
        strokeWidth={1}
        opacity={visible ? 0.5 : undefined}
      />
      <TextObjectS text={word} size={28} bold={true} italic={true} opacity={0.5} />
    </>
  ) : (
    <>
      <RectObjectS width={160} height={80} radius={8} fill={codeNameColors.word} stroke="black" />
      <RectObjectS width={140} height={60} radius={6} stroke="#333333" />
      <TextObjectS text={word} fill={"#333333"} size={24} />
    </>
  );
};

export const CodeNameAgentObjectS = ({ data: { type } }) => {
  return <RectObjectS width={200} height={100} radius={8} fill={codeNameColors[type]} stroke="black" />;
};
