import React, { useState } from "react";
import { View } from "react-native";
import { Menu, TouchableRipple } from "react-native-paper";
import { useUser } from "../../engine/user";
import { MenuOffset } from "./MenuOffset";
import { UserAvatar } from "./UserAvatar";

export const User = ({ size = 32 }) => {
  const [user, userDispatch] = useUser();
  const [visible, setVisible] = useState(false);

  if (!user) return null;

  return (
    <View>
      <MenuOffset visible={visible} onDismiss={() => setVisible(false)} top={size + 16} width={size}>
        <Menu.Item
          onPress={() => {
            userDispatch({ type: "logout" });
            setVisible(false);
          }}
          title="Déconnexion"
        />
      </MenuOffset>
      <TouchableRipple
        onPress={() => setVisible(true)}
        style={{ borderRadius: size / 2, width: size, height: size, margin: -1 }}
      >
        <UserAvatar user={user} size={size} />
      </TouchableRipple>
    </View>
  );
};
