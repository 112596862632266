export const buzzerColors = ["#e91e63", "#2196f3", "#4caf50", "#ffc107", "#6200ea", "#00b8d4", "#aeea00", "#ff6d00"];
export const buzzerBackColors = [
  "#f48fb1",
  "#90caf9",
  "#a5d6a7",
  "#ffe082",
  "#b39ddb",
  "#80deea",
  "#e6ee9c",
  "#ffcc80",
];
