import React from "react";
import { View, StyleSheet } from "react-native";
import { Menu } from "react-native-paper";

const styles = StyleSheet.create({
  anchor: { height: 1 },
  container: {
    height: 0,
    position: "relative",
    width: 0,
  },
  content: { position: "absolute" },
});

export const MenuOffset = ({
  top = 0,
  left = 0,
  width = 1,
  visible,
  onDismiss,
  contentStyle = undefined as any,
  children,
}) => {
  return (
    <View style={styles.container}>
      <View style={[styles.content, { top, left }]}>
        <Menu {...{ visible, onDismiss, contentStyle }} anchor={<View style={[styles.anchor, { width }]} />}>
          {children}
        </Menu>
      </View>
    </View>
  );
};
