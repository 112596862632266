import _ from "lodash";
import React, { useState } from "react";
import { ScrollView } from "react-native";
import { Portal } from "react-native-paper";
import { DeviceData } from "../../engine/device";
import { useRoom } from "../../engine/room";
import { Participant } from "../components/Participant";
import { ChangeViewDialog } from "../dialogs/ChangeViewDialog";

export const Participants = () => {
  const [{ game, settings, isHost, useDevices }, roomDispatch] = useRoom();
  const devices = useDevices();
  const [deviceToMove, setDeviceToMove] = useState<DeviceData | null>(null);

  if (!game) return null;

  const indexedParticipants = _.groupBy(devices, "viewId");
  const views = game.getViewsS(settings);

  return (
    <ScrollView>
      {views.map((view) =>
        indexedParticipants[view._id]
          ? indexedParticipants[view._id].map((device) => (
              <Participant
                key={device._id}
                device={device}
                view={view}
                onPress={isHost ? () => setDeviceToMove(device) : undefined}
              />
            ))
          : null,
      )}
      <Portal>
        <ChangeViewDialog
          visible={!!deviceToMove}
          onDismiss={() => setDeviceToMove(null)}
          views={views}
          device={deviceToMove!}
          onSelect={(viewId) => {
            roomDispatch({ type: "setDeviceView", device: deviceToMove!, viewId });
            setDeviceToMove(null);
          }}
        />
      </Portal>
    </ScrollView>
  );
};
