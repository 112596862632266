import React from "react";
import { CommonPathProps } from "react-native-svg";
import { SelectorObjectS } from "../engine/svg";
import { RectObjectS, RectSelectorObjectS } from "./rect";

interface Props extends CommonPathProps {
  height: number;
  image?: any;
}

export const CardObjectS = ({ height, stroke, fill, image, ...rest }: Props) => {
  const border = 0.0666 * height;
  const borderFill = image ? "white" : fill === undefined ? "none" : fill === "transparent" ? "transparent" : "white";
  return (
    <>
      <RectObjectS width={0.666 * height} height={height} radius={border} fill={borderFill} stroke={stroke} />
      <RectObjectS
        width={0.666 * height - border}
        height={height - border}
        radius={border / 2}
        fill={fill ?? "none"}
        image={image}
        {...rest}
      />
    </>
  );
};

export const CardSelectorObjectS = ({ height, ...rest }: Props & SelectorObjectS) => {
  return <RectSelectorObjectS width={0.666 * height} height={height} radius={0.0666 * height} {...rest} />;
};
