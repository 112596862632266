import React, { useState } from "react";
import { range } from "../../../engine/elements";
import { GameEntity } from "../../../engine/entities";
import { useRoomState } from "../../../engine/roomState";
import { ViewEntities } from "../../../engine/views";
import { CardObject } from "../../../objects/_old.viewT/card";
import { CardFaceObject } from "../../../objects/_old.viewT/cardFace";
import { CardSelectorObject } from "../../../objects/_old.viewT/cardSelector";
import { RectSelectorObject } from "../../../objects/_old.viewT/rectSelector";
import { TextObject } from "../../../objects/_old.viewT/text";
import { TransformObject } from "../../../objects/_old.viewT/transform";
import { theGameColors } from "../data";
import { TheGameAction } from "../resolvers";
import { TheGamePhase, TheGameState, theGameAreas as areas } from "../shared";

export const useTheGamePlayerButtons: ViewEntities = (generator, { playerIndex }) => {
  const [s, dispatch] = useRoomState<TheGameState, TheGameAction>();

  const playerCards = s
    .on("HAND")
    .on(playerIndex)
    .toArray();

  const [selected, setSelected] = useState<number | null>(null);
  if (selected && !playerCards.find((card) => card.data.value === selected)) {
    setSelected(null);
  }

  const selectSpot = (spotIndex) => {
    if (!selected) return;
    dispatch({ type: "PLAY", value: selected, spotIndex, playerIndex });
  };

  const buttons: GameEntity[] = [
    ...playerCards.map((card) => {
      return generator.overElement(
        card,
        <CardSelectorObject
          height={300}
          selected={selected === card.data.value}
          onClick={() => setSelected(selected === card.data.value ? null : card.data.value)}
        />,
      );
    }),
    ...range(4)
      .filter((spotIndex) => {
        if (s.phase !== TheGamePhase.PLAY || !selected) return false;
        const diff = (spotIndex <= 1 ? 1 : -1) * (s.data.spotsValues[spotIndex] - selected);
        return !(diff > 0 && diff !== 10);
      })
      .map((spotIndex) => {
        const lastCard = s
          .on("SPOT")
          .on(spotIndex)
          .getElements()
          .getFirst();

        const content = <CardSelectorObject height={300} onClick={() => selectSpot(spotIndex)} />;
        return lastCard
          ? generator.overElement(lastCard, content)
          : generator.fromPlacement("clone_spot_" + spotIndex, areas.spot.getPlacement([spotIndex], -1), content);
      }),
  ];

  const lastCard = s
    .on("DECK")
    .getElements()
    .getFirst();
  if (s.phase === TheGamePhase.PLAY && lastCard) {
    buttons.push(
      generator.overElement(
        lastCard,
        <RectSelectorObject height={300} width={200} onClick={() => dispatch({ type: "DRAW", playerIndex })} />,
      ),
    );
  }

  return buttons;
};

export const useTheGameSpots: ViewEntities = (generator) =>
  range(4).map((index) => {
    return generator.fromPlacement(
      "spot_" + index,
      areas.spot.getPlacement([index, -1]),
      spots[index],
      0,
      undefined,
      1,
    );
  });

const spots = range(4).map((index) => (
  <>
    <TransformObject y={index <= 1 ? 190 : -190} z={1}>
      <TextObject text={index <= 1 ? "UP" : "DOWN"} color={theGameColors.spotDirection} size={30} />
    </TransformObject>
    <CardObject height={300}>
      <CardFaceObject height={300} innerBorder={theGameColors.card}>
        <TextObject text={index <= 1 ? "1" : "100"} color={theGameColors.card} size={90} />
      </CardFaceObject>
    </CardObject>
  </>
));
