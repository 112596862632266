import React from "react";
import { range } from "../../engine/elements";
import { GamePosition } from "../../engine/position";
import { useRoomState } from "../../engine/roomState";
import { ViewEntities } from "../../engine/views";
import { ButtonObjectS } from "../../objects/button";
import { RectSelectorObjectS } from "../../objects/rect";
import { TextObjectS } from "../../objects/text";
import { BuzzerAction } from "./resolvers";
import { buzzerAreas, BuzzerState } from "./shared";

export const useBuzzerControlers: ViewEntities = () => {
  const [s, dispatch] = useRoomState<BuzzerState, BuzzerAction>();

  if (s.phase === "INIT" || s.pending || s.data.activated === -1) return [];

  return ["LOCK" as "LOCK", "POINT" as "POINT", "CANCEL" as "CANCEL"].map((type, index) => ({
    _id: type,
    position: new GamePosition(250 * (index - 1)),
    movingIndex: s.movingIndex,
    content: (
      <ButtonObjectS onPress={() => dispatch({ type })}>
        <TextObjectS text={type} />
      </ButtonObjectS>
    ),
  }));
};

export const useBuzzerPlayer: ViewEntities = (generator) => {
  const [s, dispatch] = useRoomState<BuzzerState, BuzzerAction>();
  if (s.phase === "INIT" || s.pending || s.data.activated !== -1) return [];

  return range(s.data.nbPlayers).map((playerIndex) =>
    generator.fromPlacement(
      "buzz_" + playerIndex,
      buzzerAreas.board.getPlacement([playerIndex], 1),
      <RectSelectorObjectS
        width={100}
        height={50}
        onPress={() => {
          dispatch({ type: "BUZZ", playerIndex });
        }}
      />,
      s.movingIndex,
    ),
  );
};
