import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { Text, Button, List, Portal, IconButton } from "react-native-paper";
import { GameDefinition } from "../../engine/definition";
import { useRoom } from "../../engine/room";
import { useUser } from "../../engine/user";
import { SelectGameDialog } from "../dialogs/SelectGameDialog";
import { paperTheme } from "../theme";

const styles = StyleSheet.create({
  create: { margin: 24 },
  facebook: { margin: 24, marginTop: 0, backgroundColor: "#3b5998" },
  icon: { margin: -4, marginRight: 8 },
  noGame: { color: paperTheme.colors.disabled, fontStyle: "italic" },
  noUser: { marginTop: 24 },
  settingsRight: { alignItems: "center", flexDirection: "row" },
  settingsRightText: { fontSize: 18 },
  text: { margin: 24, marginTop: 0, textAlign: "center" },
});

export const NewGameScreen = () => {
  const [user, userDispatch] = useUser();
  const [room, roomDispatch] = useRoom();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [game, setGame] = useState<GameDefinition | null>(room?.game);
  const [settingsData, setSettingsData] = useState<object>(room?.settings ?? {});

  if (!user) {
    return (
      <View style={styles.noUser}>
        <Button
          loading={loading}
          onPress={() => {
            setLoading(true);
            userDispatch({ type: "facebookLogin" }).finally(() => setLoading(false));
          }}
          mode="contained"
          icon="facebook"
          style={styles.facebook}
        >
          Connexion
        </Button>
        <Text style={styles.text}>Connectez-vous pour pouvoir créer une nouvelle partie !</Text>
      </View>
    );
  }

  return (
    <View>
      <Portal>
        <SelectGameDialog
          visible={visible}
          onDismiss={() => setVisible(false)}
          onSelect={(newGame) => {
            if (newGame !== game) {
              setGame(newGame);
              setSettingsData(
                newGame.settingsFields
                  ? Object.fromEntries(newGame.settingsFields.map((setting) => [setting.name, setting.defaultValue]))
                  : {},
              );
            }
            setVisible(false);
          }}
        />
      </Portal>
      <List.Section>
        <List.Subheader>Choix du jeu</List.Subheader>
        <List.Item
          title={game ? game.label : "Selectionnez un jeu"}
          titleStyle={game ? undefined : styles.noGame}
          right={(props) => <List.Icon {...props} icon="chevron-down" style={styles.icon} />}
          onPress={() => {
            setVisible(true);
          }}
        />
      </List.Section>
      {game && game.settingsFields && (
        <List.Section>
          <List.Subheader>Paramètres</List.Subheader>
          {game.settingsFields.map((setting) => {
            const value = settingsData[setting.name];
            return setting.type === "number" ? (
              <List.Item
                key={setting.name}
                title={setting.label}
                right={() => (
                  <View style={styles.settingsRight}>
                    <IconButton
                      icon="minus"
                      disabled={value === setting.min}
                      onPress={() => setSettingsData({ ...settingsData, [setting.name]: value - 1 })}
                    />
                    <Text style={styles.settingsRightText}>{value}</Text>
                    <IconButton
                      icon="plus"
                      disabled={value === setting.max}
                      onPress={() => setSettingsData({ ...settingsData, [setting.name]: value + 1 })}
                    />
                  </View>
                )}
              />
            ) : null;
          })}
        </List.Section>
      )}
      <Button
        disabled={!game}
        mode="contained"
        style={styles.create}
        onPress={() => {
          roomDispatch({ type: "createRoom", game: game!, settings: settingsData });
        }}
      >
        Créer
      </Button>
    </View>
  );
};
