import { GameDefinition } from "../../engine/definition";
import cover from "./assets/cover.jpg";
import { BuzzerAction, buzzerResolvers } from "./resolvers";
import { BuzzerState, buzzerStackDef, BuzzerSettings } from "./shared";
import { buzzerGetViewsS } from "./views";

export const buzzerGame: GameDefinition<BuzzerState, BuzzerAction, BuzzerSettings> = {
  _id: "buzzer",
  label: "Buzzer",
  players: "2",
  timer: "15mn",
  // description: [""],
  // youtube: "iczFArXfrGk",
  // amazon: "B002D2Q4NK",
  // rules: "http://www.jeu-buzzer.net/regles-buzzer.html",
  cover,
  stage: 2,
  version: 1,
  getViewsS: buzzerGetViewsS,
  stackDef: buzzerStackDef,
  resolvers: buzzerResolvers,
  settingsFields: [{ name: "nbPlayers", label: "Joueurs", type: "number", defaultValue: 2, min: 2, max: 6 }],
};
