import React, { useMemo } from "react";
import { Path, CommonPathProps } from "react-native-svg";
import { PathD, selectorDefaultProps } from "../engine/svg";
import { ImageObjectS } from "./image";
import { TouchableObjectS } from "./touchable";

interface Props extends CommonPathProps {
  height: number;
  image?: any;
  radius?: number;
  opacity?: number;
}

export const HexagonObjectS = ({ height, radius, onPress, image, ...rest }: Props) => {
  const d = useMemo(() => getHexagonPath(height, radius), [height, radius]);

  return (
    <TouchableObjectS onPress={onPress}>
      {image && <ImageObjectS width={0.866 * height} height={height} image={image} d={d} />}
      <Path fill="none" d={d} {...rest} />
    </TouchableObjectS>
  );
};

export const HexagonSelectorObjectS = (props: Props) => {
  return <HexagonObjectS {...selectorDefaultProps} {...props} />;
};

export const getHexagonPath = (height = 0, radius = 0) => {
  const d = new PathD();

  const dx = 0.5 * 0.866 * height;
  const dy = 0.5 * height;
  const r = Math.min(radius, dy);

  const rc = 0.866 * r;
  const rs = 0.5 * r;

  if (r) {
    d.moveTo(-rc, -dy + rs)
      .curveTo(rc, -dy + rs, 0, -dy)
      .lineTo(dx - rc, -dy / 2 - rs)
      .curveTo(dx, -dy / 2 + r, dx, -dy / 2)
      .lineTo(dx, dy / 2 - r)
      .curveTo(dx - rc, dy / 2 + rs, dx, dy / 2)
      .lineTo(rc, dy - rs)
      .curveTo(-rc, dy - rs, 0, dy)
      .lineTo(-dx + rc, dy / 2 + rs)
      .curveTo(-dx, dy / 2 - r, -dx, dy / 2)
      .lineTo(-dx, -dy / 2 + r)
      .curveTo(-dx + rc, -dy / 2 - rs, -dx, -dy / 2)
      .close();
  } else {
    d.moveTo(0, -dy)
      .lineTo(dx, -dy / 2)
      .lineTo(dx, dy / 2)
      .lineTo(0, dy)
      .lineTo(-dx, dy / 2)
      .lineTo(-dx, -dy / 2)
      .close();
  }

  return d.toString();
};
