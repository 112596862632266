import { useMemo } from "react";

const rootCache = new Map<Function, Map<string, any>>();

const getId = (params) => Object.entries(params).flat().join("_");

export const createUseFactory = <T, V>(factory: (params: T) => V) => {
  let factoryCache = rootCache.get(factory);
  if (!factoryCache) {
    factoryCache = new Map<string, any>();
    rootCache.set(factory, factoryCache);
  }

  return (params: T) => {
    const id = getId(params);
    return useMemo(() => {
      let value = factoryCache!.get(id) as V;
      if (!value) {
        value = factory(params);
        factoryCache!.set(id, value);
      }
      return value;
    }, [factory, id]);
  };
};
