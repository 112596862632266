import React, { CSSProperties } from "react";
import { Shape } from "three";
import { getShapeGeometry } from "../../engine/_old.viewT/geometry";
import { useRenderer } from "../../engine/contexts";
import { createUseFactory } from "../../engine/factory";
import { GeometryObject } from "./geometry";

interface Props {
  width: number;
  height: number;
  depth?: number;
  border?: string;
  radius?: number;
  depthRadius?: number;
  color: string;
  backColor?: string;
  image?: string;
  opacity?: number;
  children?: any;
  shadow?: boolean;
  style?: CSSProperties;
}

export const RectObject = ({
  width,
  height,
  depth = 1,
  depthRadius = Math.min(depth / 3, 2),
  radius = 2 * depthRadius,
  ...rest
}: Props) => {
  const { engine } = useRenderer();
  const repeat = 1 / Math.max(width, height);
  const geometry = useRectFactory({ width, height, depth, depthRadius, radius, density: engine.density });

  return (
    <GeometryObject
      {...{ geometry, depth, imageCenter: [-width / 2, -height / 2], imageRepeat: [repeat, repeat] }}
      {...rest}
    />
  );
};

const rectFactory = ({ width, height, depth, depthRadius, radius, density }) => {
  const shape = getRectShape(width, height, depthRadius, radius);

  return getShapeGeometry({ shape, depth, depthRadius, shapeSegments: radius / 2, density });
};

export const getRectShape = (width, height, depthRadius, radius) => {
  const shape = new Shape();

  const dx = width / 2 - depthRadius;
  const dy = height / 2 - depthRadius;
  const r = Math.min(radius, Math.max(radius - depthRadius, 0.1), dx, dy);

  if (r) {
    shape.absarc(-dx + r, -dy + r, r, -Math.PI, -Math.PI / 2, false);
    shape.absarc(dx - r, -dy + r, r, -Math.PI / 2, 0, false);
    shape.absarc(dx - r, dy - r, r, 0, Math.PI / 2, false);
    shape.absarc(-dx + r, dy - r, r, Math.PI / 2, Math.PI, false);
  } else {
    shape.moveTo(-dx, -dy);
    shape.lineTo(dx, -dy);
    shape.lineTo(dx, dy);
    shape.lineTo(-dx, dy);
  }

  shape.autoClose = true;
  return shape;
};

export const useRectFactory = createUseFactory(rectFactory);
