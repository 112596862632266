import { range } from "../../../engine/elements";
import { GamePosition } from "../../../engine/position";
import { useRoomState } from "../../../engine/roomState";
import { GameView } from "../../../engine/views";
import { TheGameState, theGameAreas as areas, TheGameSettings } from "../shared";
import { useTheGamePlayerButtons, useTheGameSpots } from "./entities";

export const theGameGetViewsT = ({ nbPlayers }: TheGameSettings) => [
  ...range(nbPlayers).map((playerIndex) => ({
    _id: "player/" + playerIndex,
    label: "Joueur " + (playerIndex + 1),
    view: playerView,
    context: { playerIndex },
  })),
  { _id: "board", label: "Observateurs", view: boardView },
];

const useContext = (context: any) => {
  const [s] = useRoomState<TheGameState>();
  const {
    data: { cardsPerPlayer, nbPlayers },
  } = s;

  return {
    ...context,
    cardsPerPlayer,
    nbPlayers,
  };
};

const dx = 215;

const rootView = new GameView<TheGameState>()
  .setArea(areas.deck, ([x, z]) => new GamePosition(0, -100, z).addLine(dx, x - 2))
  .setArea(areas.spot, ([x, z]) => new GamePosition(0, -100, z + 1).addLine(dx, x - 2))
  .setArea(areas.playerHand, ([p, z], { nbPlayers, cardsPerPlayer }) =>
    new GamePosition(0, -1650, z)
      .addCenteredInlineArc(1800, 1800, 7, 0, nbPlayers, p)
      .addCenteredInlineArc(300, 300, 2, 0, cardsPerPlayer, z),
  );

// board

const boardView = rootView
  .fork()
  .addEntities(useTheGameSpots)
  .addContext(useContext);

// player

const playerView = rootView
  .fork()
  .addEntities(useTheGameSpots)
  .addEntities(useTheGamePlayerButtons)
  .addContext(useContext)
  .superSetArea(areas.deck, (parent) => parent && parent.addY(150))
  .superSetArea(areas.spot, (parent) => parent && parent.addY(150))
  .superSetArea(areas.playerHand, (parent, [p, z], { playerIndex, cardsPerPlayer }) =>
    p === playerIndex
      ? new GamePosition(0, -3400, z).addCenteredInlineArc(3000, 3000, 2.5, 0, cardsPerPlayer, z)
      : parent && parent.addY(50),
  );
