import React from "react";
import { Text, TextProps } from "react-native-svg";

interface Props extends TextProps {
  size?: number;
  bold?: boolean;
  italic?: boolean;
  text: string;
}

export const TextObjectS = (props: Props) => {
  const { text, size = 20, bold, italic } = props;
  return (
    <Text
      y={size / 3}
      textAnchor="middle"
      fontSize={size}
      fontWeight={bold ? "bold" : undefined}
      fontStyle={italic ? "italic" : undefined}
      {...props}
    >
      {text}
    </Text>
  );
};
