import React, { useMemo } from "react";
import { ImageBackground, StyleSheet } from "react-native";
import table2Image from "../apps/assets/table2.jpg";
import { useDevice } from "./device";
import { RendererS } from "./rendererS";
import { useRoom } from "./room";
import { useRoomState } from "./roomState";

const style = StyleSheet.create({
  background: { flexGrow: 1 },
});

export const ViewerS = () => {
  const { viewId } = useDevice();
  const [room] = useRoom();
  const [s] = useRoomState();
  const loaded = !!s;

  const roomView = useMemo(() => {
    if (!room) return null;
    const views = room.game.getViewsS(room.settings);
    return views.find((view) => view._id === viewId);
  }, [room, viewId]);

  return useMemo(
    () =>
      loaded && room && roomView ? (
        <ImageBackground source={table2Image} style={style.background}>
          <RendererS
            key={`${room.game._id}_${viewId}`}
            useEntities={() => roomView.view.useEntities(roomView.context)}
          />
        </ImageBackground>
      ) : null,
    [loaded, room, roomView, viewId],
  );
};
