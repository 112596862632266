import React, { useEffect, useMemo, useRef } from "react";
import { Group } from "three";
import { getObjectPosition, setObjectPosition } from "../../engine/_old.viewT/object";
import { ObjectTransition } from "../../engine/_old.viewT/transitions";
import { useRenderer } from "../../engine/contexts";
import { GamePosition } from "../../engine/position";
import { GroupObject } from "./group";
import { RectObject } from "./rect";

interface Props {
  height: number;
  color?: string;
  visible?: boolean;
  children?: any;
}

export const CardObject = ({ height, color = "#ffffff", visible = true, children }: Props) => {
  const { engine } = useRenderer();
  const group = useMemo(() => new Group(), []);

  const loadedRef = useRef(false);

  useEffect(() => {
    const position = new GamePosition(0, 0, visible ? 0 : 1, visible ? 0 : 180);
    if (!loadedRef.current) {
      setObjectPosition(group, position);
      loadedRef.current = true;
    } else {
      const from = getObjectPosition(group, group.parent!);
      const top = new GamePosition(0, 0, height / 2);
      const transition = new ObjectTransition(group, from, 500).linearTo(position).triangle(top, 0, 0.3, 0.7, 1);
      engine.transitions.push(transition);
    }
  }, [engine.transitions, group, height, visible]);

  return (
    <GroupObject group={group}>
      <RectObject width={0.666 * height} height={height} depth={1} color={color} radius={0.04 * height} />
      {children}
    </GroupObject>
  );
};
