import { range } from "../../engine/elements";
import { gridDistribution } from "../../engine/points";
import { GameResolvers, LoadAction, InvalidAction } from "../../engine/resolvers";
import { BuzzerState, BuzzerSettings, buzzerElementTypes as types, BuzzerPhase, buzzerAreas } from "./shared";

export type BuzzerAction =
  | LoadAction<BuzzerSettings>
  | { type: "START" }
  | { type: "BUZZ"; playerIndex: number }
  | { type: "UPDATE_LOCK"; playerIndex: number; locked: boolean }
  | { type: "UPDATE_SCORE"; playerIndex: number; score: number }
  | { type: "LOCK" }
  | { type: "POINT" }
  | { type: "CANCEL" };

export type BuzzerResolvers = GameResolvers<BuzzerState, BuzzerAction>;

const initResolvers: BuzzerResolvers = {
  LOAD: (s, { settings: { nbPlayers } }) => {
    s.setData({ nbPlayers, activated: -1 });
    s.addElements(
      range(nbPlayers).map((playerIndex) =>
        types.buzzer.create(`buzzer_${playerIndex}`, { playerIndex, locked: false, score: 0, active: false }),
      ),
    ).distributeTo(buzzerAreas.board, gridDistribution(nbPlayers));
  },
  START: (s) => {
    s.setPhase(BuzzerPhase.PLAY);
  },
};

const playResolvers: BuzzerResolvers = {
  BUZZ: (s, { playerIndex }) => {
    // check if any activated
    if (
      s
        .getElements()
        .filterData({ active: true })
        .count()
    ) {
      throw new InvalidAction();
    }

    // activate
    s.getElements()
      .filterData({ playerIndex, locked: false })
      .take(1)
      .setData({ active: true });

    s.setData({ activated: playerIndex });
  },
  UPDATE_LOCK: (s, { playerIndex, locked }) => {
    s.getElements()
      .filterData({ playerIndex })
      .setData({ locked });
  },
  UPDATE_SCORE: (s, { playerIndex, score }) => {
    s.getElements()
      .filterData({ playerIndex })
      .setData({ score });
  },
  LOCK: (s) => {
    if (s.data.activated === -1) throw new InvalidAction();

    const playerIndex = s.data.activated;
    s.getElements()
      .filterData({ playerIndex, active: true })
      .take(1)
      .setData({ active: false, locked: true });

    s.setData({ activated: -1 });
  },
  POINT: (s) => {
    if (s.data.activated === -1) throw new InvalidAction();

    const playerIndex = s.data.activated;
    s.getElements()
      .filterData({ playerIndex, active: true })
      .take(1)
      .incData("score")
      .setData({ active: false });

    s.setData({ activated: -1 });

    s.getElements()
      .filterData({ locked: true })
      .setData({ locked: false });
  },
  CANCEL: (s) => {
    s.setData({ activated: -1 });

    s.getElements()
      .filterData({ locked: true })
      .setData({ locked: false });

    s.getElements()
      .filterData({ active: true })
      .setData({ active: false });

    s.setData({ activated: -1 });
  },
};

export const buzzerResolvers = {
  INIT: initResolvers,
  PLAY: playResolvers,
};
