import React from "react";
import { G, GProps } from "react-native-svg";

interface Props extends GProps {
  children: React.ReactNode;
}

export const TransformObjectS = (props: Props) => {
  const { children, y = 0 } = props;
  return (
    <G {...props} y={-y}>
      {children}
    </G>
  );
};
