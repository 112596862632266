import { GameArea } from "../../engine/area";
import { GameElementType, ElementOf } from "../../engine/elementType";
import { mapStack, arrayStack, areaStack } from "../../engine/stack2";
import { GameState } from "../../engine/state";
import { CodeNameAgentType } from "./data";

// areas

export const codeNameAreas = {
  board: new GameArea("BOARD", 2),
  deck: new GameArea("DECK", 1),
};
const areas = codeNameAreas;

// stacks

export const codeNameStackDef = mapStack({
  children: {
    DECK: arrayStack({
      length: 5,
      keys: { BLUE: 0, RED: 1, NEUTRAL: 2, ASSASSIN: 3, WORD: 4 },
      area: areas.deck,
      getGroup: (element: CodeNameElement) =>
        codeNameElementTypes.agent.is(element) ? element.data.type : element.type,
    }),
    BOARD: areaStack({ area: areas.board }),
  },
});

// elements

export const codeNameElementTypes = {
  word: new GameElementType<"WORD", { word: string; type: CodeNameAgentType; visible: boolean }>("WORD"),
  agent: new GameElementType<"AGENT", { type: CodeNameAgentType }>("AGENT"),
};

export type CodeNameElement =
  | ElementOf<typeof codeNameElementTypes.word>
  | ElementOf<typeof codeNameElementTypes.agent>;

// state

export enum CodeNamePhase {
  INIT = "INIT",
  PLAY = "PLAY",
}

interface CodeNameData {
  firstTeam: CodeNameAgentType;
}

export type CodeNameState = GameState<CodeNameElement, CodeNameData, CodeNamePhase, typeof codeNameStackDef>;
