import React from "react";
import { PixelRatio, StyleSheet, ViewStyle } from "react-native";
import { Avatar } from "react-native-paper";

const ratio = Math.ceil(PixelRatio.get());

const styles = StyleSheet.create({
  text: { backgroundColor: "#ffffff" },
  image: { backgroundColor: "#ffffff" },
});

interface UserAvatarProps {
  user: Pick<firebase.User, "displayName" | "photoURL">;
  size: number;
  style?: ViewStyle;
}

export const UserAvatar = ({ user, size, style }: UserAvatarProps) => {
  return user.photoURL ? (
    <Avatar.Image
      size={size}
      source={typeof user.photoURL === "string" ? { uri: `${user.photoURL}?height=${size * ratio}` } : user.photoURL}
      style={style ? { ...style, ...styles.image } : styles.image}
    />
  ) : (
    <Avatar.Text
      size={size}
      style={style ? { ...style, ...styles.text } : styles.text}
      label={user
        .displayName!.split(" ")
        .map((s) => s[0])
        .join("")}
    />
  );
};
