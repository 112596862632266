import {
  LineBasicMaterial,
  MeshBasicMaterial,
  MeshLambertMaterial,
  MeshLambertMaterialParameters,
  RepeatWrapping,
  Texture,
  FrontSide,
} from "three";
import { createUseFactory } from "../factory";
import { ThreeEngine } from "./three";

interface Props {
  color: string;
  image?: any;
  engine?: ThreeEngine;
  imageCenter?: [number, number];
  imageRepeat?: [number, number];
  opacity?: number;
  side?: typeof FrontSide;
  basic?: boolean;
}

const simpleMeshMaterialFactory = ({
  color,
  image,
  engine,
  imageCenter = [0, 0],
  imageRepeat = [1, 1],
  opacity = 1,
  side = FrontSide,
  basic = false,
}: Props) => {
  const opacityObject = opacity < 1 ? { transparent: true, opacity } : {};
  if (basic) {
    return new MeshBasicMaterial({ color, side, ...opacityObject });
  }

  let texture: Texture | undefined = undefined;
  if (image && engine) {
    texture = engine.loadTexture(image);
    texture.center.set(...imageCenter);
    texture.repeat.set(...imageRepeat);
    texture.wrapS = RepeatWrapping;
    texture.wrapT = RepeatWrapping;
  }
  const parameters: MeshLambertMaterialParameters = {
    reflectivity: 0,
    side,
    // specular: 0.5,
    ...opacityObject,
  };
  return new MeshLambertMaterial(texture ? { ...parameters, map: texture } : { ...parameters, color });
};

export const simpleLineMaterialFactory = ({ color, linewidth = 1 }) => {
  return new LineBasicMaterial({ color, linewidth });
};

export const useSimpleMaterialFactory = createUseFactory(simpleMeshMaterialFactory);

export const invisibleMaterial = new MeshBasicMaterial({ visible: false });
