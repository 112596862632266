import React, { useState } from "react";
import { AnimatedObjectState, AnimatedObjectS } from "../objects/animated";
import { GameEntity } from "./entities";

interface Props {
  entity: GameEntity;
  animatedStateMap: Map<string, AnimatedObjectState>;
}

export const EntityS = ({ entity, animatedStateMap }: Props) => {
  let animatedState = animatedStateMap.get(entity._id);

  if (!entity.position) return null;

  if (!animatedState) {
    animatedState = {} as AnimatedObjectState;
    animatedStateMap.set(entity._id, animatedState);
  }

  return (
    <AnimatedObjectS target={entity.position} state={animatedState}>
      {entity.content}
    </AnimatedObjectS>
  );
};

export const useRenderEntity = () => {
  const [animatedStateMap] = useState(new Map<string, AnimatedObjectState>());
  const [cache] = useState(new Map<string, [GameEntity, React.ReactNode]>());

  return (entity: GameEntity) => {
    const res = cache.get(entity._id);
    if (!res || entity.version === undefined || res[0].version !== entity.version) {
      const element = <EntityS key={entity._id} entity={entity} animatedStateMap={animatedStateMap} />;
      cache.set(entity._id, [entity, element]);
      return element;
    } else {
      return res[1];
    }
  };
};
