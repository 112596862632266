import seedRandom from "seedrandom";
import { GameState } from "./state";

export type RandomState = {};

export const createRandomState = () => {
  const random = seedRandom(Math.random().toString(), { state: true });
  return random.state() as any;
};

export const randomInt = (state: GameState) => (n: number) => {
  const result = random(state);
  return Math.floor(result * n);
};

export const randomBoolean = (state: GameState) => () => {
  const result = random(state);
  return result >= 0.5;
};

export const randomD6 = (state: GameState) => () => {
  return randomInt(state)(6) + 1;
};

export const random = (state: GameState) => {
  if (!state.randomState) throw new Error("Missing random state");
  const random = seedRandom("", { state: state.randomState });
  const result = random();
  state.randomState = random.state();
  return result;
};

export const shuffle = (state: GameState) => <T>(source: T[]): T[] => {
  if (!state.randomState) throw new Error("Missing random state");
  const a = [...source];
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(random(state) * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};
