import { GameDefinition } from "../../engine/definition";
import { theGameGetViewsT } from "./_old.viewT/views";
import cover from "./assets/cover.jpg";
import { TheGameAction, theGameResolvers } from "./resolvers";
import { TheGameState, theGameStackDef } from "./shared";
import { theGameGetViewsS } from "./views";

export const theGameGame: GameDefinition<TheGameState, TheGameAction> = {
  _id: "theGame",
  label: "The Game",
  players: "1-6",
  timer: "30mn",
  description: [
    "La règle est d’une simplicité diabolique : 98 cartes à poser sur 4 piles, 2 ascendantes, 2 descendantes. Une seule manière d’inverser le sens d’une pile : jouer une carte exactement supérieure/inférieure de 10 à celle au sommet.",
    "Mais le problème est que chaque joueur a des cartes en mains, doit en jouer au moins 2 durant son tour et ne peut pas révéler le contenu de sa main. Les joueurs peuvent discuter, mais juste pour se dire des trucs comme « ne joue pas sur cette pile » ou « pas trop gros ici, s'il te plait ».",
    "La tâche semble impossible au départ et le suspense est présent jusqu'à la dernière carte.",
  ],
  amazon: "B017EKZ1TS",
  youtube: "JmyCPwJKMr4",
  rules: "http://jeuxstrategie1.free.fr/jeu_the_game/regle.pdf",
  cover,
  stage: 2,
  version: 2,
  getViewsT: theGameGetViewsT,
  getViewsS: theGameGetViewsS,
  stackDef: theGameStackDef,
  resolvers: theGameResolvers,
  settingsFields: [{ name: "nbPlayers", label: "Nbr joueurs", type: "number", defaultValue: 2, min: 1, max: 6 }],
};
