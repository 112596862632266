import React from "react";
import { GameEntity } from "../../engine/entities";
import { elementToPoint } from "../../engine/points";
import { useRoomState } from "../../engine/roomState";
import { ViewEntities } from "../../engine/views";
import { RectSelectorObjectS } from "../../objects/rect";
import { CodeNameAction } from "./resolvers";
import { codeNameElementTypes as types, CodeNamePhase, CodeNameState } from "./shared";

export const useCodeNameButtons: ViewEntities = (generator, { asSpy }): GameEntity[] => {
  const [s, dispatch] = useRoomState<CodeNameState, CodeNameAction>();
  if (s.phase !== CodeNamePhase.PLAY || s.pending) return [];

  return s
    .getElements()
    .filterType(types.word)
    .filterData({ visible: false })
    .toArray()
    .map((word) =>
      generator.overElement(
        word,
        <RectSelectorObjectS
          width={asSpy ? 200 : 160}
          height={asSpy ? 100 : 80}
          radius={8}
          onPress={() => dispatch({ type: "DISCOVER", point: elementToPoint(word) })}
        />,
      ),
    );
};
