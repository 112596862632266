import React from "react";
import { StyleSheet } from "react-native";
import { Dialog, Text, Button } from "react-native-paper";
import { useRoom } from "../../engine/room";

const styles = StyleSheet.create({
  dialog: { width: "100%", maxWidth: 300, alignSelf: "center" },
});

interface Props {
  visible: boolean;
  onDismiss: () => any;
}

export const LeaveDialog = ({ visible, onDismiss }: Props) => {
  const [, roomDispatch] = useRoom();

  return (
    <Dialog {...{ visible, onDismiss }} onDismiss={onDismiss} style={styles.dialog}>
      <Dialog.Title>Quitter</Dialog.Title>
      <Dialog.Content>
        <Text>Êtes-vous sûr de vouloir quitter cette partie ?</Text>
      </Dialog.Content>
      <Dialog.Actions>
        <Button onPress={onDismiss}>Annuler</Button>
        <Button onPress={() => roomDispatch({ type: "leaveRoom" })}>Quitter</Button>
      </Dialog.Actions>
    </Dialog>
  );
};
