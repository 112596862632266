import React from "react";
import { RectSelectorObject } from "./rectSelector";

interface Props {
  height: number;
  around?: boolean;
  onClick: () => void;
  visible?: boolean;
  selected?: boolean;
  children?: any;
  style?: any;
}

export const CardSelectorObject = ({ height, ...rest }: Props) => {
  return <RectSelectorObject height={height} width={0.666 * height} radius={0.04 * height} depth={1} {...rest} />;
};
