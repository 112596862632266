import React from "react";
import { StyleSheet } from "react-native";
import { RectObject } from "../../../objects/_old.viewT/rect";
import { RectLineObject } from "../../../objects/_old.viewT/rectLine";
import { TextObject } from "../../../objects/_old.viewT/text";
import { codeNameColors } from "../data";

const styles = StyleSheet.create({
  wordBorderLine: { alignItems: "flex-end", justifyContent: "flex-end" },
  wordText: { marginRight: 4, textTransform: "capitalize" },
});

export const CodeNameWordObjectT = ({ data: { word, visible, type }, context: { asSpy } }) => {
  return asSpy ? (
    <RectObject
      width={200}
      height={100}
      radius={8}
      depth={5}
      color={codeNameColors[type]}
      opacity={visible ? 0.5 : undefined}
    >
      <TextObject text={word} size={28} bold={true} italic={true} opacity={0.5} />
    </RectObject>
  ) : (
    <RectObject width={160} height={80} radius={8} color={codeNameColors.word}>
      <RectLineObject width={140} height={60} radius={8} color={"#333333"} style={styles.wordBorderLine}>
        <TextObject text={word} color={"#333333"} size={24} style={styles.wordText} />
      </RectLineObject>
    </RectObject>
  );
};

export const CodeNameAgentObjectT = ({ data: { type } }) => {
  return <RectObject width={200} height={100} depth={5} radius={8} color={codeNameColors[type]} />;
};
