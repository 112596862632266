import * as Facebook from "expo-facebook";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import { useAuthState } from "react-firebase-hooks/auth";
import { Platform } from "react-native";

// config

export const firebaseConfig = {
  apiKey: "AIzaSyCWhcIb0xq4D72YvhZQKgKrBQfzmN_7Eh8",
  authDomain: "gameflix-7097e.firebaseapp.com",
  databaseURL: "https://gameflix-7097e.firebaseio.com",
  projectId: "gameflix-7097e",
  storageBucket: "gameflix-7097e.appspot.com",
  messagingSenderId: "425208120482",
  appId: "1:425208120482:web:7bce780ec8ce4a5d",
};

firebase.initializeApp(firebaseConfig);

firebase.auth().languageCode = "fr_FR";

const facebookAppId = "763749457360061";
const facebookAppName = "Gmflx";
const facebookPermissions = ["public_profile", "email"];

// firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
// firebase.firestore().enablePersistence();

// auth

const facebookProvider = new firebase.auth.FacebookAuthProvider();

export const logout = async () => {
  await firebase.auth().signOut();
};

export const useFirebaseAuth = () => {
  return useAuthState(firebase.auth());
};

export const facebookLogin = async () => {
  return Platform.OS === "web" ? facebookLoginPopup() : facebookLoginMobile();
};

export const facebookLoginPopup = async () => {
  await firebase.auth().signInWithPopup(facebookProvider);
};

export const facebookLoginMobile = async () => {
  await Facebook.initializeAsync(facebookAppId, facebookAppName);
  const res = await Facebook.logInWithReadPermissionsAsync({
    permissions: facebookPermissions,
  });

  if (res.type === "success") {
    const credential = firebase.auth.FacebookAuthProvider.credential(res.token);
    await firebase.auth().signInWithCredential(credential);
  }
};

// firestore

export const collections = {
  devices: firebase.firestore().collection("devices"),
  rooms: firebase.firestore().collection("rooms"),
};

export const firebaseDatabase = firebase.database();
