import { AreaGetPosition } from "./area";
import { GamePlacement } from "./entities";

export class GamePosition {
  public scale = 1;

  public groupId = 0;

  constructor(public x = 0, public y = 0, public z = 0, public rx = 0, public ry = 0, public rz = 0) {}

  public add(position: GamePosition | null) {
    if (!position) return this;
    this.x += position.x;
    this.y += position.y;
    this.z += position.z;
    this.rx += position.rx;
    this.ry += position.ry;
    this.rz += position.rz;
    this.scale *= position.scale;
    return this;
  }

  public multiply(factor) {
    this.x *= factor;
    this.y *= factor;
    this.z *= factor;
    this.rx *= factor;
    this.ry *= factor;
    this.rz *= factor;
    this.scale = Math.exp(Math.log(this.scale) * factor);
    return this;
  }

  public getDiff(from: GamePosition) {
    return this.clone().add(from.clone().multiply(-1));
  }

  public set(position: Partial<GamePosition>) {
    Object.assign(this, position);
    return this;
  }

  public clone() {
    return new GamePosition().set(this);
  }
  public toArray() {
    return [this.x, this.y, this.z, this.scale, this.rz, this.rx, this.groupId];
  }

  public inFront() {
    this.groupId = 1;
    return this;
  }

  public addX(dx) {
    this.x += dx;
    return this;
  }
  public addY(dy) {
    this.y += dy;
    return this;
  }
  public addZ(dz) {
    // this.x += z / 2;
    // this.y += -z / 4;
    this.z += dz;
    return this;
  }
  public multiplyScale(scale) {
    this.scale *= scale;
    return this;
  }
  public addScale(scale) {
    this.scale += scale;
    return this;
  }
  public addRz(rotate) {
    this.rz += rotate;
    return this;
  }
  public addRX(rotateX) {
    this.rx += rotateX;
    return this;
  }

  public addGrid(dx, dy, x, y) {
    this.x += x * dx;
    this.y += y * dy;
    return this;
  }
  public addLine(dx, x) {
    return this.addGrid(dx, 0, x, 0);
  }
  public addVerticalLine(dy, y) {
    return this.addGrid(0, dy, 0, y);
  }
  public addInlineGrid(dx, dy, columns, i) {
    const x = i % columns;
    const y = (i - x) / columns;
    return this.addGrid(dx, dy, x, y);
  }

  public addHexGrid(diag, x, y) {
    this.x += x * 0.866 * diag;
    this.y -= y * 0.75 * diag;
    return this;
  }
  public addHexCornerGrid(diag, x, y) {
    this.x += (x - 0.5) * (26 / 30) * diag;
    this.y -= y * (0.75 * diag) - (((x * 2 + y) % 2) + 1) * (diag / 4);
    return this;
  }
  public addHexBorderGrid(diag, x, y) {
    this.x += (x - 0.5) * 0.866 * diag;
    this.y -= (y - 0.5) * 0.75 * diag;
    this.rz += (y * 2) % 2 === 1 ? 90 : ((x + 0.25) * 2) % 2 === y % 2 ? -30 : +30;
    return this;
  }

  public addInlineOval(radiusX: number, radiusY: number, elements: number, i: number) {
    return this.addInlineArc(radiusX, radiusY, 360, 0, elements + 1, i);
  }
  public addCenteredInlineArc(
    radiusX: number,
    radiusY: number,
    dist: number,
    offset: number,
    elements: number,
    i: number,
  ) {
    return this.addInlineArc(radiusX, radiusY, -dist * elements, 0.5 * dist * elements + offset, elements, i);
  }
  public addInlineArc(radiusX: number, radiusY: number, range: number, start: number, elements: number, i: number) {
    const place = elements === 1 ? 0.5 : i / (elements - 1);
    const angle = place * range + start + this.rz + 90;
    this.x += radiusX * Math.cos(degToRad * angle);
    this.y += radiusY * Math.sin(degToRad * angle);
    this.rz = angle - 90;
    return this;
  }

  public scaleAll(scale) {
    this.x *= scale;
    this.y *= scale;
    this.scale *= scale;
    return this;
  }

  public isEqual(position: GamePosition) {
    return this.x === position.x && this.y === position.y && this.z === position.z;
  }
}

export const degToRad = (2 * Math.PI) / 360;

export const getViewPosition = (
  viewPosition: Dictionary<AreaGetPosition>,
  placement: GamePlacement,
  context: any = {},
  source: any = {},
) => {
  const getPosition = viewPosition[placement.area];
  return getPosition ? getPosition(placement.data, context, source) : null;
};
