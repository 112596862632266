import { GameElement } from "./element";
import { range } from "./elements";

export class GameElementType<T extends string = any, D extends object = any> {
  constructor(public name: T) {}

  public create(_id: string, data: D): GameElement<D, T> {
    return { _id, data, type: this.name, placement: { area: "", data: [] }, version: 1, movingIndex: 0 };
  }

  public is = (element: GameElement): element is GameElement<D, T> => {
    return element.type === this.name;
  };
}

export type ElementOf<T extends GameElementType> = ReturnType<T["create"]>;
export type ElementsOf<T extends GameElementType> = ReturnType<T["create"]>[];
export type ComponentOf<T extends GameElementType, C = never> = ReturnType<T["create"]> & { context: C };

export const cards32 = [7, 8, 9, 10, 11, 12, 13, 1];
export const cards54 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

export interface CardData {
  color: number;
  value: number;
}

export const createCards32 = () =>
  range(4)
    .flatMap((color) => cards32.map((value) => [color, value]))
    .map(([color, value]) => ({ _id: `card_${color}_${value}`, data: { value, color } }));
export const createCards54 = () =>
  range(4)
    .flatMap((color) => cards54.map((value) => [color, value]))
    .map(([color, value]) => ({ _id: `card_${color}_${value}`, data: { value, color } }));
