import React, { useMemo } from "react";
import { Path, CommonPathProps } from "react-native-svg";
import { PathD, selectorDefaultProps, SelectorObjectS } from "../engine/svg";
import { ImageObjectS } from "./image";
import { TouchableObjectS } from "./touchable";

interface Props extends CommonPathProps {
  width: number;
  height: number;
  image?: any;
  radius?: number;
  opacity?: number;
}

export const RectObjectS = ({ width, height, radius, onPress, image, ...rest }: Props) => {
  const d = useMemo(() => getRectPath(width, height, radius), [height, radius, width]);

  return (
    <TouchableObjectS onPress={onPress}>
      {image && <ImageObjectS width={width} height={height} image={image} d={d} />}
      <Path fill="none" d={d} {...rest} />
    </TouchableObjectS>
  );
};

export const RectSelectorObjectS = ({ visible, selected, ...rest }: Props & SelectorObjectS) => {
  return (
    <RectObjectS fill={selected ? "grey" : visible ? "white" : "transparent"} {...selectorDefaultProps} {...rest} />
  );
};

export const getRectPath = (width = 0, height = 0, radius = 0) => {
  const d = new PathD();

  const dx = width / 2;
  const dy = height / 2;
  const r = Math.min(radius, dx, dy);
  const dxr = dx - r;
  const dyr = dy - r;

  if (r) {
    d.moveTo(dxr, -dy)
      .curveTo(dx, -dyr, dx, -dy)
      .lineTo(dx, dyr)
      .curveTo(dxr, dy, dx, dy)
      .lineTo(-dxr, dy)
      .curveTo(-dx, dyr, -dx, dy)
      .lineTo(-dx, -dyr)
      .curveTo(-dxr, -dy, -dx, -dy)
      .close();
  } else {
    d.moveTo(dx, -dy)
      .lineTo(dx, dy)
      .lineTo(-dx, dy)
      .lineTo(-dx, -dy)
      .close();
  }

  return d.toString();
};
