import { GameArea } from "../../engine/area";
import { GameElementType, ElementOf } from "../../engine/elementType";
import { Point } from "../../engine/points";
import { mapStack, arrayStack, stack, areaStack } from "../../engine/stack2";
import { GameState } from "../../engine/state";
import { CataneTileType, CataneCardType, CataneDevCardType, CatanePawnType } from "./data";

export type CataneContext = { playerIndex: number };
export type CataneSettings = { nbPlayers: number };

// areas

export const cataneAreas = {
  boardTile: new GameArea("BOARD_TILE", 2),
  boardCorner: new GameArea("BOARD_CORNER", 2),
  boardBorder: new GameArea("BOARD_BORDER", 2),
  deck: new GameArea("DECK", 1),
  dice: new GameArea("DICE", 1),
  playerBoard: new GameArea("PLAYER_BOARD", 2),
  playerHand: new GameArea("PLAYER_HAND", 1),
};
const areas = cataneAreas;

// elements

export const cataneElementTypes = {
  tile: new GameElementType<"TILE", { type: CataneTileType }>("TILE"),
  card: new GameElementType<"CARD", { type: CataneCardType }>("CARD"),
  devCard: new GameElementType<"DEV_CARD", { type: CataneDevCardType }>("DEV_CARD"),
  token: new GameElementType<"TOKEN", { value: number }>("TOKEN"),
  pawn: new GameElementType<"PAWN", { type: CatanePawnType; playerIndex: number }>("PAWN"),
  dice: new GameElementType<"DICE", { value: number; count: number }>("DICE"),
  thief: new GameElementType<"THIEF", {}>("THIEF"),
  port: new GameElementType<"PORT", { type: CataneTileType | null }>("PORT"),
};
const types = cataneElementTypes;

export type CataneElement =
  | ElementOf<typeof cataneElementTypes.tile>
  | ElementOf<typeof cataneElementTypes.token>
  | ElementOf<typeof cataneElementTypes.card>
  | ElementOf<typeof cataneElementTypes.devCard>
  | ElementOf<typeof cataneElementTypes.pawn>
  | ElementOf<typeof cataneElementTypes.thief>
  | ElementOf<typeof cataneElementTypes.dice>
  | ElementOf<typeof cataneElementTypes.port>;

// stacks

const compareCards = (a, b) => a.data.type.localeCompare(b.data.type) || a._id.localeCompare(b._id);
const sortCards = (elements: CataneElement[]) => [
  ...elements.filter(types.devCard.is).sort(compareCards),
  ...elements.filter(types.card.is).sort(compareCards),
];

export const cataneStackDef = mapStack({
  children: {
    DECK: arrayStack({
      length: 12,
      area: areas.deck,
      keys: { ORE: 0, CLAY: 1, CORN: 2, WOOD: 3, WOOL: 4, DEV_CARD: 5, TILE: 7, PORT: 9, TOKEN: 10, THIEF: 11 },
      getGroup: (element) => (types.card.is(element) ? element.data.type : element.type),
    }),
    PLAYER_HAND: arrayStack({
      length: 4,
      area: areas.playerHand,
      forceSort: sortCards,
    }),
    PLAYER_BOARD: arrayStack({
      length: 4,
      area: areas.playerBoard,
      getGroup: (element) => element.data.playerIndex,
      getChild: (i) =>
        arrayStack({
          length: 5,
          keys: { ROAD: 1, COLONY: 2, CITY: 3, DEV_CARD: 4 },
          getGroup: (element) => (types.pawn.is(element) ? element.data.type : element.type),
          getChild: (j) => stack({ data: [i, j] }),
        }),
    }),
    DICE: areaStack({ area: areas.dice }),
    BOARD_TILE: areaStack({ area: areas.boardTile }),
    BOARD_BORDER: areaStack({ area: areas.boardBorder }),
    BOARD_CORNER: areaStack({ area: areas.boardCorner }),
  },
});

// state

export interface CataneData {
  nbPlayers: number;
  firstPlayer: number;
  players: CataneDataPlayer[];
}

export interface CataneDataPlayer {
  installation: number;
  secondColony?: Point;
  moveThief?: boolean;
  discardCards?: number;
  stealFrom: number[] | null;
  freeRoads?: number;
  freeResources?: number;
  monopoly?: boolean;
}

export enum CatanePhase {
  INIT = "INIT",
  PLAY = "PLAY",
}

export type CataneState = GameState<CataneElement, CataneData, CatanePhase, typeof cataneStackDef>;
