import React from "react";
import { CircleObject } from "../../../objects/_old.viewT/circle";
import { DiceObject } from "../../../objects/_old.viewT/dices";
import { backgammonColors } from "../data";

export const BackgammonPawnObjectT = ({ data: { playerIndex } }) => (
  <CircleObject size={70} depth={10} color={backgammonColors[playerIndex]} />
);

export const BackgammonDiceObjectT = ({ data: { playerIndex, played, value, count } }) => (
  <DiceObject value={value} color={backgammonColors[playerIndex]} size={40} played={played} count={count} />
);
