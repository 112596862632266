import React, { useEffect, useMemo } from "react";
import { RendererProvider, useRenderer } from "../../engine/contexts";

export const GroupObject = ({ group, children }) => {
  const renderer = useRenderer();

  useEffect(() => {
    renderer.group.add(group);
    return () => {
      renderer.group.remove(group);
    };
  }, [group, renderer]);

  const value = useMemo(() => ({ ...renderer, group: group }), [group, renderer]);

  return <RendererProvider value={value}>{children}</RendererProvider>;
};
