import React from "react";
import { View, StyleSheet } from "react-native";
import { List, Text } from "react-native-paper";
import { useDevice, DeviceData } from "../../engine/device";
import { RoomView } from "../../engine/views";
import { UserAvatar } from "./UserAvatar";

const styles = StyleSheet.create({
  description: {
    flexDirection: "row",
  },
  isYou: {
    backgroundColor: "grey",
    borderRadius: 5,
    justifyContent: "center",
    marginRight: 5,
    paddingLeft: 4,
    paddingRight: 4,
  },
  isYouText: {
    color: "white",
    fontSize: 12,
  },
  user: { margin: 4, marginLeft: 8 },
});

interface Props {
  device: DeviceData;
  view: RoomView;
  onPress?: () => any;
}

export const Participant = ({ device, view, onPress }: Props) => {
  const { _id: deviceId } = useDevice();

  return (
    <List.Item
      title={device.user}
      description={() => (
        <View style={styles.description}>
          {deviceId === device._id && (
            <View style={styles.isYou}>
              <Text style={styles.isYouText}>Vous</Text>
            </View>
          )}
          <Text>{view.label}</Text>
        </View>
      )}
      onPress={onPress}
      left={() => (
        <UserAvatar user={{ displayName: device.user, photoURL: device.userPhotoURL }} style={styles.user} size={48} />
      )}
    />
  );
};
