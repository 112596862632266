import React from "react";
import { ImageBackground, StyleSheet, Image } from "react-native";
import { Text, Surface } from "react-native-paper";
import iconImage from "../assets/icon.png";
import backgroundImage from "../assets/welcome.jpg";
import { paperTheme } from "../theme";
import { User } from "./User";

const styles = StyleSheet.create({
  logo: { position: "absolute", top: 20, left: 30, width: 60, height: 60 },
  title: {
    position: "absolute",
    top: 18,
    left: 100,
    fontFamily: "caesar-dressing",
    fontSize: 50,
    color: paperTheme.colors.primary,
  },
  user: { position: "absolute", top: 28, right: 30, elevation: 7, padding: 0, borderRadius: 24 },
});

export const LayoutPage = ({ children }) => {
  return (
    <ImageBackground source={backgroundImage} style={{ flexGrow: 1 }}>
      {children}
      <Image source={iconImage} style={styles.logo} />
      <Text style={styles.title}>GAMEFLIX</Text>
      <Surface style={styles.user}>
        <User size={48} />
      </Surface>
    </ImageBackground>
  );
};
