import React from "react";
import { ClipPath, Path, Image, ImageProps } from "react-native-svg";
import { useSvgId } from "../engine/svg";

interface Props extends ImageProps {
  width: number;
  height: number;
  image: any;
  d: string;
  opacity?: number;
}

export const ImageObjectS = ({ width, height, image, d, ...rest }: Props) => {
  const id = useSvgId();

  return (
    <>
      <Image
        x={-width / 2}
        y={-height / 2}
        width={width}
        height={height}
        preserveAspectRatio="xMidYMid slice"
        href={image}
        clipPath={`url(#${id})`}
        {...rest}
      />
      <ClipPath id={id}>
        <Path fill="none" d={d} />
      </ClipPath>
    </>
  );
};
