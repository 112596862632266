import React from "react";
import { useCircleFactory } from "./circle";
import { GeometrySelectorObject } from "./geometrySelector";

interface SelectorProps {
  size: number;
  depth?: number;
  depthRadius?: number;
  onClick: () => void;
  visible?: boolean;
  selected?: boolean;
  children?: any;
  style?: any;
}

export const CircleSelectorObject = ({
  size,
  depth = 1,
  depthRadius = Math.min(depth / 3, 2),
  ...rest
}: SelectorProps) => {
  const geometry = useCircleFactory({ size, depth, depthRadius });

  return <GeometrySelectorObject {...{ geometry, depth }} {...rest} />;
};
