import _ from "lodash";
import { filterIds, range } from "../../engine/elements";
import { arrayDistribution, createPoints, excludePoints, hexGridDistribution, Point } from "../../engine/points";
import { GameResolvers, InvalidAction, LoadAction } from "../../engine/resolvers";
import {
  CataneCardType,
  cataneCosts,
  CataneDevCardType,
  CatanePawnType,
  catanePortsPoints,
  CataneTileType,
  cataneTokenPoints,
  cataneTilesTypes,
  cataneTokens,
  cataneCardsTypes,
  cataneDevCardTypes,
  catanePortTypes,
  catanePawnTypes,
} from "./data";
import {
  cataneElementTypes as types,
  cataneAreas as areas,
  CataneDataPlayer,
  CatanePhase,
  CataneState,
  CataneSettings,
} from "./shared";

export type CataneAction =
  | LoadAction<CataneSettings>
  | { type: "START" }
  | { type: "PLACE_PAWN"; playerIndex: number; pawnType: CatanePawnType; point: Point }
  | { type: "ROLL_DICE"; playerIndex: number }
  | { type: "PICK_DEV_CARD"; playerIndex: number }
  | { type: "MOVE_THIEF"; playerIndex: number; point: Point }
  | { type: "DISCARD_CARDS"; playerIndex: number; selection: string[] }
  | { type: "STEAL"; playerIndex: number; opponentIndex: number }
  | { type: "GIVE"; playerIndex: number; opponentIndex: number; selection: string[] }
  | { type: "EXCHANGE"; playerIndex: number; selection: string[]; wantedType: CataneCardType }
  | { type: "PLAY_DEV_CARD"; playerIndex: number; cardType: CataneDevCardType }
  | { type: "FREE_RESOURCE"; playerIndex: number; wantedType: CataneCardType }
  | { type: "MONOPOLY"; playerIndex: number; wantedType: CataneCardType };

export type CataneResolvers = GameResolvers<CataneState, CataneAction>;

const initResolvers: CataneResolvers = {
  LOAD: (s, { settings: { nbPlayers } }) => {
    // data
    s.setData({
      firstPlayer: s.fn.randomInt(nbPlayers),
      nbPlayers,
      players: range(nbPlayers).map(() => ({
        installation: 0,
        stealFrom: null,
      })),
    });

    // tiles
    s.addElements(cataneTilesTypes.map((type, index) => types.tile.create(`tile_${type}_${index}`, { type })));
    // tokens
    s.addElements(
      cataneTokens
        .slice()
        .reverse()
        .map((value, index) => types.token.create(`token_${index}`, { value })),
    );
    // thief
    s.addElements([types.thief.create(`thief`, {})]);
    // cards
    s.addElements(cataneCardsTypes.map((type, index) => types.card.create(`card_${type}_${index}`, { type })));
    // dev cards
    s.addElements(cataneDevCardTypes.map((type, index) => types.devCard.create(`devCard_${type}_${index}`, { type })));
    // ports
    s.addElements(catanePortTypes.map((type, index) => types.port.create(`port_${type}_${index}`, { type })));
    // dices
    s.addElements(range(2).map((index) => types.dice.create(`dice_${index}`, { value: 6, count: 0 })));
    // pawns
    s.addElements(
      range(nbPlayers).flatMap((playerIndex) =>
        catanePawnTypes.map((type, index) =>
          types.pawn.create(`pawn_${playerIndex}_${type}_${index}`, { type, playerIndex }),
        ),
      ),
    );

    // place
    s.getElements()
      .excludeType(types.dice)
      .excludeType(types.pawn)
      .moveTo(s.on("DECK"));
    s.getElements()
      .filterType(types.dice)
      .moveTo(s.on("DICE").on([0]));
    for (const playerIndex of range(nbPlayers)) {
      s.getElements()
        .filterType(types.pawn)
        .filterData({ playerIndex })
        .moveTo(s.on("PLAYER_BOARD").on(playerIndex));
    }

    // shuffle
    s.on("DECK")
      .on(types.devCard)
      .shuffle();
    s.on("DECK")
      .on(types.tile)
      .shuffle();
    s.on("DECK")
      .on(types.port)
      .shuffle();
  },
  START: (s) => {
    s.setPhase(CatanePhase.PLAY);

    // dices
    if (s.data.firstPlayer) {
      s.getElements()
        .filterType(types.dice)
        .moveTo(s.on("DICE").on([s.data.firstPlayer]));
    }

    // tiles
    s.on("DECK")
      .on(types.tile)
      .getElements()
      .distributeTo(areas.boardTile, hexGridDistribution(2));

    // ports
    s.on("DECK")
      .on(types.port)
      .getElements()
      .distributeTo(areas.boardBorder, arrayDistribution(catanePortsPoints));

    // thief
    const desertPoint = s
      .getElements()
      .filterType(types.tile)
      .filterData({ type: CataneTileType.DESERT })
      .getPoints()
      .getFirst();
    s.on("DECK")
      .on(types.thief)
      .getElements()
      .moveTo(s.on("BOARD_TILE").on(desertPoint), 6);

    // token
    const tokenPoints_ = cataneTokenPoints.filter(excludePoints([desertPoint]));
    s.on("DECK")
      .on(types.token)
      .getElements()
      .distributeTo(areas.boardTile, arrayDistribution(tokenPoints_), 5);
  },
};

const playResolvers: CataneResolvers = {
  PLACE_PAWN: (s, { playerIndex, pawnType, point }) => {
    const playerData = s.data.players[playerIndex];

    if (playerData.installation < 4) {
      const expectedPawnType = playerData.installation % 2 ? CatanePawnType.ROAD : CatanePawnType.COLONY;
      if (pawnType !== expectedPawnType) throw new InvalidAction();
      updatePlayerData(s, playerIndex)({ installation: playerData.installation + 1 });
    } else if (playerData.freeRoads) {
      if (pawnType !== CatanePawnType.ROAD) throw new InvalidAction();
      updatePlayerData(s, playerIndex)({ freeRoads: playerData.freeRoads - 1 });
    } else {
      const cost = cataneCosts[pawnType].map((type) => (card: any) => card.data.type === type);
      s.on("PLAYER_HAND")
        .on(playerIndex)
        .getElements()
        .select(cost)
        .moveTo(s.on("DECK"));
    }

    if (pawnType === CatanePawnType.CITY) {
      s.getElements()
        .filterType(types.pawn)
        .filterArea(areas.boardCorner, point)
        .filterData({ playerIndex })
        .expectLength(1)
        .moveTo(s.on("PLAYER_BOARD").on(playerIndex));
    }

    const destination = pawnType === CatanePawnType.ROAD ? s.on("BOARD_BORDER") : s.on("BOARD_CORNER");
    s.on("PLAYER_BOARD")
      .on(playerIndex)
      .on(pawnType)
      .getElements()
      .take(1)
      .moveTo(destination.on(point), 1);

    if (playerData.installation === 3) {
      updatePlayerData(s, playerIndex)({ secondColony: point });
      createPoints(s)([point])
        .getHexCornerTiles()
        .getElementsFrom(areas.boardTile, s.getElements().filterType(types.tile))
        .excludeData({ type: CataneTileType.DESERT })
        .toArray()
        .forEach((tile) => {
          const type = tile.data.type;
          s.on("DECK")
            .on(type)
            .getElements()
            .take(1)
            .moveTo(s.on("PLAYER_HAND").on(playerIndex));
        });
    }
  },
  ROLL_DICE: (s, { playerIndex }) => {
    const values = range(2).map(() => s.fn.randomD6());

    s.getElements()
      .filterType(types.dice)
      .setData((_element, index) => ({ value: values[index] }))
      .incData("count")
      .moveTo(s.on("DICE").on([playerIndex]));

    const value = values.reduce((a, b) => a + b);
    if (value === 7) {
      for (const playerIndex_ of range(s.data.nbPlayers)) {
        const count = s
          .on("PLAYER_HAND")
          .on(playerIndex_)
          .getElements()
          .filterType(types.card)
          .toArray().length;
        if (count > 7) {
          updatePlayerData(s, playerIndex_)({ discardCards: Math.floor(count / 2) });
        }
      }
      updatePlayerData(s, playerIndex)({ moveThief: true });
    } else {
      s.getElements()
        .filterType(types.token)
        .filterData({ value })
        .getPoints()
        .exclude(
          s
            .getElements()
            .filterType(types.thief)
            .getPoints().includes,
        )
        .getElementsFrom(areas.boardTile, s.getElements().filterType(types.tile))
        .excludeData({ type: CataneTileType.DESERT })
        .forEach((tile) => {
          s.fn
            .createElements([tile])
            .getPoints()
            .getHexTileCorners()
            .getElementsFrom(areas.boardCorner, s.getElements().filterType(types.pawn))
            .forEach((pawn) => {
              s.on("DECK")
                .on(tile.data.type)
                .getElements()
                .take(pawn.data.type === CatanePawnType.CITY ? 2 : 1)
                .moveTo(s.on("PLAYER_HAND").on(pawn.data.playerIndex));
            });
        });
    }
  },
  PICK_DEV_CARD: (s, { playerIndex }) => {
    const cost = cataneCosts[types.devCard.name].map((type) => (card: any) => card.data.type === type);
    const playerHand = s.on("PLAYER_HAND").on(playerIndex);
    playerHand
      .getElements()
      .select(cost)
      .moveTo(s.on("DECK"));

    s.on("DECK")
      .on(types.devCard.name)
      .getElements()
      .take(1)
      .moveTo(playerHand);
  },
  MOVE_THIEF: (s, { playerIndex, point }) => {
    const cornerPawns = s
      .getElements()
      .filterType(types.pawn)
      .filterArea(areas.boardCorner);
    const opponents = s.fn
      .createPoints([point])
      .getHexTileCorners()
      .getElementsFrom(areas.boardCorner, cornerPawns)
      .excludeData({ playerIndex })
      .mapData("playerIndex");

    if (!opponents.length) throw new InvalidAction();
    updatePlayerData(s, playerIndex)({ moveThief: false, stealFrom: _.uniq(opponents) });

    s.getElements()
      .filterType(types.thief)
      .moveTo(s.on("BOARD_TILE").on(point));
  },
  DISCARD_CARDS: (s, { selection, playerIndex }) => {
    const playerData = s.data.players[playerIndex];
    if (!playerData.discardCards) throw new InvalidAction();
    s.on("PLAYER_HAND")
      .on(playerIndex)
      .getElements()
      .filter(filterIds(selection))
      .take(playerData.discardCards)
      .moveTo(s.on("DECK"));
    updatePlayerData(s, playerIndex)({ discardCards: 0 });
  },
  STEAL: (s, { playerIndex, opponentIndex }) => {
    const playerData = s.data.players[playerIndex];
    if (!playerData.stealFrom || !playerData.stealFrom.includes(opponentIndex)) throw new InvalidAction();

    updatePlayerData(s, playerIndex)({ stealFrom: null });
    s.on("PLAYER_HAND")
      .on(opponentIndex)
      .getElements()
      .filterType(types.card)
      .shuffle()
      .take(1, true)
      .moveTo(s.on("PLAYER_HAND").on(playerIndex));
  },
  GIVE: (s, { playerIndex, opponentIndex, selection }) => {
    s.on("PLAYER_HAND")
      .on(playerIndex)
      .getElements()
      .filter(filterIds(selection))
      .moveTo(s.on("PLAYER_HAND").on(opponentIndex));
  },
  EXCHANGE: (s, { playerIndex, selection, wantedType }) => {
    const playerHand = s.on("PLAYER_HAND").on(playerIndex);
    const selectedCards = playerHand
      .getElements()
      .forceType(types.card)
      .filter(filterIds(selection))
      .expectMinLength(1);
    const selectedType = selectedCards.mapData("type")[0];
    const playerPorts = s
      .getElements()
      .filterType(types.pawn)
      .filterArea(areas.boardCorner)
      .filterData({ playerIndex })
      .getPoints()
      .getHexCornerBorders()
      .getElementsFrom(areas.boardBorder, s.getElements().filterType(types.port))
      .toArray();
    const required = playerPorts.find((port) => port.data.type === selectedType)
      ? 2
      : playerPorts.find((port) => port.data.type === null)
      ? 3
      : 4;
    const cost = new Array(required).fill((element: any) => element.data.type === selectedType);
    selectedCards.select(cost).moveTo(s.on("DECK"));
    s.on("DECK")
      .on(wantedType)
      .getElements()
      .take(1)
      .moveTo(playerHand);
  },
  PLAY_DEV_CARD: (s, { cardType, playerIndex }) => {
    const playerHand = s.on("PLAYER_HAND").on(playerIndex);
    const playerBoard = s.on("PLAYER_BOARD").on(playerIndex);

    if (cardType === CataneDevCardType.KNIGHT) {
      playerHand
        .getElements()
        .forceType(types.devCard)
        .select([(card) => card.data.type === cardType])
        .moveTo(playerBoard);
      updatePlayerData(s, playerIndex)({ moveThief: true });
    } else {
      playerHand
        .getElements()
        .forceType(types.devCard)
        .select([(card) => card.data.type === cardType])
        .moveTo(s.on("DECK"), 0);
      if (cardType === CataneDevCardType.ROADS) {
        updatePlayerData(
          s,
          playerIndex,
        )({
          freeRoads: Math.min(2, playerBoard.on(CatanePawnType.ROAD).toArray().length),
        });
      } else if (cardType === CataneDevCardType.INVENTION) {
        updatePlayerData(s, playerIndex)({ freeResources: 2 });
      } else if (cardType === CataneDevCardType.MONOPOLY) {
        updatePlayerData(s, playerIndex)({ monopoly: true });
      } else throw new InvalidAction();
    }
  },
  FREE_RESOURCE: (s, { playerIndex, wantedType }) => {
    const playerData = s.data.players[playerIndex];
    if (!playerData.freeResources) throw new InvalidAction();
    updatePlayerData(s, playerIndex)({ freeResources: playerData.freeResources - 1 });

    s.on("DECK")
      .on(wantedType)
      .getElements()
      .take(1)
      .moveTo(s.on("PLAYER_HAND").on(playerIndex));
  },
  MONOPOLY: (s, { playerIndex, wantedType }) => {
    const playerData = s.data.players[playerIndex];
    if (!playerData.monopoly) throw new InvalidAction();
    updatePlayerData(s, playerIndex)({ monopoly: false });

    for (const opponentIndex of range(s.data.nbPlayers)) {
      if (opponentIndex === playerIndex) continue;
      s.on("PLAYER_HAND")
        .on(opponentIndex)
        .getElements()
        .filterData({ type: wantedType })
        .moveTo(s.on("PLAYER_HAND").on(playerIndex));
    }
  },
};

const updatePlayerData = (s: CataneState, playerIndex: number) => (partial: Partial<CataneDataPlayer>) => {
  Object.entries(partial).forEach(([key, value]) => s.updateData(`players[${playerIndex}].${key}`, value));
};

export const cataneResolvers = {
  INIT: initResolvers,
  PLAY: playResolvers,
};
