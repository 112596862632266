import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import { List } from "react-native-paper";
import { useRoom } from "../../engine/room";

const styles = StyleSheet.create({
  container: {},
  content: {
    overflow: "hidden",
    height: 275,
  },
  content2: {
    marginTop: -90,
    height: 450,
  },
});

export const RoomChat = () => {
  const [visible, setVisible] = useState(false);
  const [room] = useRoom();

  if (!open) return null;
  return (
    <View style={styles.container}>
      <List.Accordion
        title="Visioconf"
        left={(props) => <List.Icon {...props} style={{ height: "" }} icon="chat" />}
        expanded={visible}
        onPress={() => setVisible((v) => !v)}
      >
        {}
      </List.Accordion>
      {visible && (
        <View style={styles.content}>
          <View style={styles.content2}>
            <iframe
              width="350"
              height="450"
              src={`https://tico.chat/powercall?room=${room._id}&type=timeFirst&entry=embed`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </View>
        </View>
      )}
    </View>
  );
};
