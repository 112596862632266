import React from "react";
import { View } from "react-native";
import { Svg, G } from "react-native-svg";
import { sortEntityMovingIndex, GameEntity } from "./entities";
import { useComponentLayout } from "./utils";
import { useRenderEntity } from "./entityS";

export const RendererS = ({ useEntities }: { useEntities: () => GameEntity[] }) => {
  const entities = useEntities().sort(sortEntityMovingIndex);
  const [layout, onLayout] = useComponentLayout();
  const renderEntity = useRenderEntity();

  if (!layout) return <View style={{ flexGrow: 1 }} onLayout={onLayout} />;

  const scale = Math.min(layout.width / 1920, layout.height / 1080);

  return (
    <View style={{ flexGrow: 1 }} onLayout={onLayout}>
      <Svg style={{ flexGrow: 1 }}>
        <G x={layout.width / 2} y={layout.height / 2} scale={scale}>
          {entities.map(renderEntity)}
        </G>
      </Svg>
    </View>
  );
};
