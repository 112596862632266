import React from "react";
import { GameEntity } from "../../../engine/entities";
import { elementToPoint } from "../../../engine/points";
import { useRoomState } from "../../../engine/roomState";
import { ViewEntities } from "../../../engine/views";
import { RectSelectorObject } from "../../../objects/_old.viewT/rectSelector";
import { CodeNameAction } from "../resolvers";
import { codeNameElementTypes, CodeNamePhase, CodeNameState } from "../shared";

export const useCodeNameButtons: ViewEntities = (generator, { asSpy }): GameEntity[] => {
  const [s, dispatch] = useRoomState<CodeNameState, CodeNameAction>();
  if (s.phase !== CodeNamePhase.PLAY) return [];

  return s
    .getElements()
    .filterType(codeNameElementTypes.word)
    .filterData({ visible: false })
    .toArray()
    .map((word) =>
      generator.overElement(
        word,
        <RectSelectorObject
          width={asSpy ? 200 : 160}
          height={asSpy ? 100 : 80}
          radius={8}
          depth={asSpy ? 3 : 1}
          onClick={() => dispatch({ type: "DISCOVER", point: elementToPoint(word) })}
        />,
      ),
    );
};
