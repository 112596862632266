import React, { CSSProperties } from "react";
import { getShapeLineGeometry } from "../../engine/_old.viewT/geometry";
import { createUseFactory } from "../../engine/factory";
import { GeometryLineObject } from "./geometryLine";
import { getRectShape } from "./rect";

interface Props {
  width: number;
  height: number;
  radius?: number;
  color: string;
  children?: any;
  style?: CSSProperties;
}

export const RectLineObject = ({ width, height, radius, ...rest }: Props) => {
  const geometry = useRectLineFactory({ width, height, radius });

  return <GeometryLineObject geometry={geometry} {...rest} />;
};

const rectLineFactory = ({ width, height, radius }) => {
  const shape = getRectShape(width, height, 0, radius);

  return getShapeLineGeometry({ shape });
};

export const useRectLineFactory = createUseFactory(rectLineFactory);
