import { BrowserInfo, detect } from "detect-browser";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { AsyncStorage } from "react-native";
import { DeviceProvider } from "./device";
import { collections } from "./firebase";
import { useUser } from "./user";

export const DeviceLoader = ({ children, inApp }: { children: any; inApp?: string | null }) => {
  const [loading, setLoading] = useState(true);
  const [_id, setId] = useState();
  const [isInApp, setIsInApp] = useState(false);

  useEffect(() => {
    (async () => {
      if (inApp) {
        setId(inApp);
        setIsInApp(true);
      } else if (window && window.localStorage) {
        setId(window.localStorage.getItem("deviceId"));
      } else {
        setId(await AsyncStorage.getItem("deviceId"));
      }
      setLoading(false);
    })();
  }, [inApp]);

  return loading ? null : _id ? (
    <DeviceInfoLoader {...{ _id, isInApp, setId, children }} />
  ) : (
    <NewDevice setId={setId} />
  );
};

const browser = detect() as BrowserInfo;
const deviceType = browser ? browser.os : "Mobile";

const DeviceInfoLoader = ({ _id, isInApp, setId, children }) => {
  const [device, loading, error] = useDocumentData(collections.devices.doc(_id), { idField: "_id" });
  const [currentUser] = useUser();
  const returnRef = useRef<any>(null);

  const value = useMemo(() => ({ isInApp, ...(device || {}) }), [device, isInApp]);

  if (loading) return returnRef.current;
  if (error) throw error;

  if (!device) {
    if (window && window.localStorage) {
      window.localStorage.setItem("deviceId", "");
    } else {
      AsyncStorage.setItem("deviceId", "");
    }
    setId("");
    return null;
  }

  if (!isInApp) {
    const { type, user, userPhotoURL } = device;
    const deviceUser = currentUser ? currentUser.displayName : "Invité";
    const deviceUserPhotoURL = currentUser ? currentUser.photoURL : null;
    if (type !== deviceType || user !== deviceUser || userPhotoURL !== deviceUserPhotoURL) {
      collections.devices.doc(_id).update({ type: deviceType, user: deviceUser, userPhotoURL: deviceUserPhotoURL });
    }
  }

  returnRef.current = <DeviceProvider value={value}>{children}</DeviceProvider>;

  return returnRef.current;
};

const getDeviceId = async () => {
  const res = await collections.devices.add({});
  if (window && window.localStorage) {
    window.localStorage.setItem("deviceId", res.id);
  } else {
    await AsyncStorage.setItem("deviceId", res.id);
  }
  return res.id;
};

const NewDevice = ({ setId }) => {
  useEffect(() => {
    getDeviceId().then((id) => setId(id));
  }, [setId]);
  return null;
};
