import { range } from "lodash";
import { GamePosition } from "../../engine/position";
import { GameView, RoomView } from "../../engine/views";
import { BuzzerObjectS } from "./elements";
import { useBuzzerControlers, useBuzzerPlayer } from "./entities";
import { buzzerAreas as areas, buzzerElementTypes as types, BuzzerState, BuzzerSettings } from "./shared";

export const buzzerGetViewsS = ({ nbPlayers }: BuzzerSettings): RoomView[] => [
  ...range(nbPlayers).map((playerIndex) => ({
    _id: "player/" + playerIndex,
    label: `Joueur "${playerIndex + 1}"`,
    view: playerView,
    context: { playerIndex },
  })),
  { _id: "board", view: boardView, label: "Controller" },
];

const boardView = new GameView<BuzzerState>()
  .addEntities(useBuzzerPlayer)
  .addEntities(useBuzzerControlers)
  .setElementType(types.buzzer, BuzzerObjectS)
  .setArea(areas.board, ([p]) => new GamePosition(-800, 370).addLine(250, p).multiplyScale(2));

const playerView = new GameView<BuzzerState>()
  .addEntities(useBuzzerPlayer)
  .setElementType(types.buzzer, BuzzerObjectS)
  .setArea(areas.board, ([p], { playerIndex }) => (p === playerIndex ? new GamePosition().multiplyScale(10) : null));
