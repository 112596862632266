import * as Font from "expo-font";
import React, { useEffect } from "react";
import { Provider as PaperProvider, Portal } from "react-native-paper";
import { DeviceLoader } from "../engine/deviceLoader";
import { RoomLoader } from "../engine/roomLoader";
import { RoomStateLoader } from "../engine/roomStateLoader";
import { UserLoader } from "../engine/userLoader";
import fontUrl from "./assets/CaesarDressing-Regular.ttf";
import { Router } from "./components/Router";
import { paperTheme } from "./theme";

const inApp = !window
  ? null
  : window.IN_APP
  ? window.IN_APP
  : window.location && window.location.search && window.URLSearchParams
  ? new URLSearchParams(window.location.search).get("inApp")
  : null;

export const App = () => {
  // useCheckUpdates();
  useEffect(() => {
    Font.loadAsync({
      "caesar-dressing": fontUrl,
    });
  });

  return (
    <PaperProvider theme={paperTheme}>
      <UserLoader>
        <DeviceLoader inApp={inApp}>
          <RoomLoader>
            <RoomStateLoader>
              <Portal.Host>
                <Router />
              </Portal.Host>
            </RoomStateLoader>
          </RoomLoader>
        </DeviceLoader>
      </UserLoader>
    </PaperProvider>
  );
};
