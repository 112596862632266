import { GameDefinition } from "./definition";
import { ExtendedRoom } from "./room";
import { GameState, GameStatePatch } from "./state";

export const InvalidAction = class extends Error {
  constructor() {
    super("INVALID_ACTION");
  }
};

export interface GameAction {
  type: string;
  _previousState?: any;
}

export type LoadAction<S = any> = { type: "LOAD"; settings: S };

type GameResolver<S extends GameState = GameState, A extends GameAction = GameAction> = (
  s: S,
  action: A,
  room: any,
  game: GameDefinition<S, A>,
) => void;

export type GameResolvers<S extends GameState, A extends GameAction> = {
  [s in A["type"]]?: GameResolver<S, A extends { type: s } ? A : never>;
};

export const resolveAction = <S extends GameState = GameState, A extends GameAction = GameAction>(
  room: any,
  game: GameDefinition<S, A>,
) => (state: S, action: A): GameStatePatch => {
  try {
    const resolve = game.resolvers[state.phase][action.type];
    resolve(state, action, room, game);

    const subActions = state.currentSubActions;
    state.currentSubActions = [];
    return {
      action,
      subActions,
      randomState: state.randomState,
    };
  } catch (e) {
    console.error(e);
    console.error("Action:", action);
    console.error("Phase:", state.phase);
    console.error("Data:", state.data);
    throw e;
  }
};

export const getActionPatch = (state: GameState, room: ExtendedRoom, action: GameAction) => {
  const newState = state.clone();
  return resolveAction(room, room.game)(newState, action);
};
