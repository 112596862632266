import _, { range } from "lodash";
import { GameArea } from "./area";
import { GameElement } from "./element";
import { Elements } from "./elements";
import { shuffle } from "./random";
import { InvalidAction } from "./resolvers";
import { GameState } from "./state";

export class Stack2<S extends GameState, E extends GameElement> {
  private elements: E[] = this.loadElements();

  constructor(
    public state: S,
    private area: GameArea,
    private data: number[],
    private forceSort?: (elements: E[]) => E[],
  ) {}

  public add(elements: E[], z = this.elements.length) {
    elements.forEach(this.area.setOn(this.state, this.data));
    this.elements.splice(z, 0, ...elements);
    if (this.forceSort) {
      this.elements = this.forceSort(this.elements);
      this.updatePlacementIndex();
    } else {
      elements.forEach((element) => this.state.setElementPlacementData(element, this.area.stackIndex, z++));
    }
    return this;
  }

  public remove(elements: E[]) {
    elements.forEach((element) => {
      const index = this.elements.indexOf(element);
      this.elements.splice(index, 1);
    });
    this.updatePlacementIndex();
  }

  public clear() {
    this.elements = [];
    return this;
  }

  public getElements<EE extends E = E>(): Elements<S, EE> {
    return new Elements<S, EE>(this.state, this.elements.slice().reverse() as any, this);
  }

  public shuffle() {
    this.elements = shuffle(this.state)(this.elements);
    this.updatePlacementIndex();
  }

  public toArray() {
    return this.elements;
  }

  private loadElements() {
    return this.state.getElementsArray().filter(this.area.isOn(this.data)).sort(this.area.stackComparator);
  }

  private updatePlacementIndex() {
    const index = this.area.stackIndex;
    this.elements.forEach((element, z) => {
      if (element.placement.data[index] !== z) {
        this.state.setElementPlacementData(element, this.area.stackIndex, z);
      }
    });
  }
}

export class MultiStack2<S extends GameState, E extends GameElement, G extends StackGroups<E>> {
  public stacks = this.loadStacks();

  constructor(
    private state: S,
    private groups: G,
    private getGroup,
    private keys: any = {},
    private config: StackConfig<E>,
  ) {}

  public add(elements: E[], z?: number) {
    if (!this.getGroup) throw new InvalidAction();
    const groupedElements = _.groupBy(elements, this.getGroup);
    Object.entries(groupedElements).forEach(([key, elements]) => {
      if (this.keys[key] !== undefined) {
        key = this.keys[key].toString();
      }
      const child = this.stacks.get(key);
      if (!child) throw new InvalidAction();
      child.add(elements, z);
    });
    return this;
  }

  public clear() {
    this.stacks.forEach((stack) => stack.clear());
    return this;
  }

  public on<N extends keyof G>(group: number | N | { name: N }): AnyStack<G[N], S, E> {
    let key = typeof group === "number" ? group.toString() : typeof group === "object" ? group.name : group;
    if (this.keys[key] !== undefined) {
      key = this.keys[key].toString();
    }
    return this.stacks.get(key)! as any;
  }

  private loadStacks() {
    const stacks = new Map<any, any>();

    Object.entries<any>(this.groups).forEach(([group, def]) => {
      const stack = createAnyStack(this.state, def, this.config);
      stacks.set(group, stack);
    });

    return stacks;
  }
}

export class ArrayStack2<
  S extends GameState,
  E extends GameElement,
  C extends AnyStackDef<E>,
  K extends { [s: string]: number }
> {
  public stacks = this.loadStacks();

  constructor(
    private state: S,
    private length: number,
    private getChild: (i: number) => C,
    private getGroup: any,
    private keys: K,
    private config: StackConfig<E>,
  ) {}

  public add(elements: E[], z?: number) {
    if (!this.getGroup) throw new InvalidAction();
    const groupedElements = _.groupBy(elements, this.getGroup);
    Object.entries(groupedElements).forEach(([group, elements]) => {
      const key = this.keys[group] !== undefined ? this.keys[group] : parseInt(group, 10);
      const child = this.stacks[key];
      if (!child) throw new InvalidAction();
      child.add(elements, z);
    });
    return this;
  }

  public on<N extends keyof K>(group: number | N | { name: N }): AnyStack<C, S, E> {
    const key = typeof group === "number" ? group : this.keys[typeof group === "object" ? group.name : group];
    return this.stacks[key] as any;
  }

  private loadStacks() {
    return range(this.length).map((i) => {
      const def = this.getChild(i);
      return createAnyStack<S, E>(this.state, def, this.config);
    });
  }
}

export class AreaStack2<S extends GameState, E extends GameElement> {
  constructor(private state: S, private getGroup: any, private config: StackConfig<E>) {}

  public add(elements: E[], z?: number) {
    if (!this.getGroup) throw new InvalidAction();
    elements.forEach((element) => {
      const data = this.getGroup(element);
      const child = createAnyStack<S, E>(this.state, stack({ data }), this.config);
      if (!child) throw new InvalidAction();
      child.add([element], z);
    });
    return this;
  }

  public on(data: number[]): Stack2<S, E> {
    return createAnyStack<S, E>(this.state, stack({ data }), this.config) as any;
  }
}

export type AnyStackDef<E extends GameElement> =
  | StackDef<E>
  | MapStackDef<E, any>
  | ArrayStackDef<E, any>
  | AreaStackDef<E>;

interface StackGroups<E extends GameElement> {
  [s: string]: AnyStackDef<E>;
}

// stack

interface StackConfig<E extends GameElement> {
  area?: GameArea;
  data?: number[];
  forceSort?: (elements: any[]) => any[];
}
type StackDef<E extends GameElement> = { type: "STACK"; config: StackConfig<E> };
export const stack = <E extends GameElement>(config: StackConfig<E> = {}): StackDef<E> => ({
  type: "STACK",
  config,
});

// Mapstack

interface MapStackConfig<E extends GameElement, G extends StackGroups<E>> extends StackConfig<E> {
  children: G;
  getGroup?: (element: E) => keyof G | number;
}
export type MapStackDef<E extends GameElement, G extends StackGroups<E>> = {
  type: "MAP";
  config: MapStackConfig<E, G>;
};
export const mapStack = <E extends GameElement, G extends StackGroups<E>>(
  config: MapStackConfig<E, G>,
): MapStackDef<E, G> => ({
  type: "MAP",
  config,
});

// ArrayStack

interface ArrayStackConfig<E extends GameElement, G extends AnyStackDef<E>> extends StackConfig<E> {
  length: number;
  getChild?: (i: number) => G;
  keys?: { [s: string]: number };
  getGroup?: (element: E) => number | string;
}
type ArrayStackDef<E extends GameElement, G extends AnyStackDef<E>> = {
  type: "ARRAY";
  config: ArrayStackConfig<E, G>;
};
export const arrayStack = <E extends GameElement, G extends AnyStackDef<E>>(
  config: ArrayStackConfig<E, G>,
): ArrayStackDef<E, G> => ({
  type: "ARRAY",
  config,
});

// area

interface AreaStackConfig<E extends GameElement> extends StackConfig<E> {
  getGroup?: (element: E) => number | string;
}
type AreaStackDef<E extends GameElement> = { type: "AREA"; config: AreaStackConfig<E> };
export const areaStack = <E extends GameElement>(config: AreaStackConfig<E>): AreaStackDef<E> => ({
  type: "AREA",
  config,
});

// any

export type AnyStack<T extends AnyStackDef<any>, S extends GameState, E extends GameElement> = T extends MapStackDef<
  any,
  any
>
  ? MultiStack2<any, any, T["config"]["children"]>
  : T extends ArrayStackDef<E, any>
  ? ArrayStack2<S, E, T["config"]["getChild"] extends Function ? ReturnType<T["config"]["getChild"]> : StackDef<E>, any>
  : T extends AreaStackDef<E>
  ? AreaStack2<S, E>
  : T extends StackDef<E>
  ? Stack2<S, E>
  : never;

export const createAnyStack = <S extends GameState, E extends GameElement>(
  state: S,
  def: AnyStackDef<E>,
  parentConfig: StackConfig<E>,
) => {
  switch (def.type) {
    case "MAP": {
      const { children: groups, getGroup, ...stackConfig } = def.config;
      return new MultiStack2<S, E, typeof groups>(state, groups, getGroup, {}, { ...parentConfig, ...stackConfig });
    }
    case "ARRAY": {
      const { length, getGroup, keys = {}, getChild: child, ...stackConfig } = def.config;
      const _child = child ?? ((i: number) => stack({ data: [i] }));

      return new ArrayStack2<S, E, ReturnType<typeof _child>, typeof keys>(state, length, _child, getGroup, keys, {
        ...parentConfig,
        ...stackConfig,
      });
    }
    case "AREA": {
      const { getGroup, ...stackConfig } = def.config;
      return new AreaStack2<S, E>(state, getGroup, stackConfig);
    }
    case "STACK": {
      const { data, forceSort, area } = { ...parentConfig, ...def.config };
      if (!area) throw new InvalidAction();
      if (!data) throw new InvalidAction();
      return new Stack2<S, E>(state, area, data, forceSort as any);
    }
  }
};
