import React from "react";
import { Platform } from "react-native";
import { Button } from "react-native-paper";
import * as WebBrowser from "expo-web-browser";

const aStyle = { textDecoration: "none" };

interface Props {
  href: string;
  color?: string;
  icon?: string;
  target?: string;
  children: string;
}

export const Link = ({ href, icon, color, target, children }: Props) => {
  return Platform.OS === "web" ? (
    <a {...{ href, target }} style={aStyle}>
      <Button {...{ color, icon }}>{children}</Button>
    </a>
  ) : (
    <Button
      {...{ color, icon }}
      onPress={() => {
        WebBrowser.openBrowserAsync(href);
      }}
    >
      {children}
    </Button>
  );
};
