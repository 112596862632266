import "core-js/modules/es.object.from-entries";
import { registerRootComponent } from "expo";
import { activateKeepAwake } from "expo-keep-awake";
import { YellowBox } from "react-native";
// import Sentry from "sentry-expo";
import { App } from "./app";

if (__DEV__) {
  activateKeepAwake();
}
registerRootComponent(App as any);

YellowBox.ignoreWarnings(["Require cycle", "Setting a timer", "THREE.WebGLRenderer"]);

// Remove this once Sentry is correctly setup.
// Sentry.enableInExpoDevelopment = true;

// Sentry.config("https://3870b279624744c7bef5cc5b5a303ecc@sentry.io/1479609").install();
