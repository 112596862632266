import React, { useMemo } from "react";
import { useDocumentData, useCollectionData } from "react-firebase-hooks/firestore";
import { appGames } from "../games/games";
import { DeviceData, useDevice } from "./device";
import { collections, firebaseDatabase } from "./firebase";
import { createRandomState } from "./random";
import { resolveAction, LoadAction } from "./resolvers";
import { RoomProvider, RoomContextValue, ExtendedRoom, RoomAction, RoomData } from "./room";
import { GameState } from "./state";
import { useUser } from "./user";

export const RoomLoader = ({ children }) => {
  const [user] = useUser();
  const { _id: deviceId, roomId } = useDevice();

  const [roomData] = useDocumentData<any>(roomId ? collections.rooms.doc(roomId) : null, {
    idField: "_id",
  });

  const useDevices = useMemo(() => getUseDevices(roomData?._id), [roomData]);

  const room = useMemo(() => {
    if (!roomData) return null;
    return {
      ...roomData,
      roomId: roomData._id,
      isHost: user?.uid === roomData.hostId,
      game: appGames.find((game) => game._id === roomData.gameId),
      useDevices,
    } as ExtendedRoom;
  }, [roomData, useDevices, user]);

  const dispatch = useMemo(() => getRoomDispatch(user?.uid, deviceId, room), [deviceId, room, user]);

  const value = useMemo(() => [room, dispatch] as RoomContextValue<true>, [dispatch, room]);

  return <RoomProvider value={value}>{children}</RoomProvider>;
};

const getUseDevices = (roomId: string) => {
  return () => {
    const [devices = []] = useCollectionData<DeviceData>(collections.devices.where("roomId", "==", roomId), {
      idField: "_id",
    });
    return devices;
  };
};

const getRoomDispatch = (userId: string, deviceId: string, room: ExtendedRoom | null) => async (action: RoomAction) => {
  switch (action.type) {
    case "setDeviceView": {
      const { device, viewId } = action;
      if (!device) return;
      if (viewId !== device.viewId) {
        await collections.devices.doc(device._id).update({ viewId });
      }
      return;
    }
    case "updatePinCode": {
      const pinCode = Math.round(Math.random() * 900000) + 100000;
      const pinCodeUpdate = Date.now();
      await collections.devices.doc(deviceId).update({ pinCode, pinCodeUpdate });
      return;
    }
    case "connectDevice": {
      if (!room) throw new Error("Invalid room");
      const { code, viewId } = action;
      const pinCode = parseInt(code, 10);
      const res = await collections.devices.where("pinCode", "==", pinCode).get();
      if (res.empty) throw new Error("No device");
      const deviceId = res.docs[0].id;
      await collections.devices.doc(deviceId).update({ roomId: room._id, viewId });
      return;
    }
    case "leaveRoom": {
      await collections.devices.doc(deviceId).update({ roomId: null, viewId: null });
      return;
    }
    case "createRoom": {
      const { game, settings } = action;

      // create room
      const roomData: Omit<RoomData, "_id"> = {
        gameId: game._id,
        hostId: userId,
        initialRandomState: createRandomState(),
        settings,
      };

      const ref = await collections.rooms.add(roomData);

      // create room state
      const newRoom = { ...roomData, game, _id: ref.id, roomId: ref.id, isHost: true, useDevices: () => [] };
      const state = new GameState(newRoom.game.stackDef);
      state.randomState = newRoom.initialRandomState;

      const loadAction: LoadAction = { type: "LOAD", settings: newRoom.settings };
      const patch = resolveAction(newRoom, newRoom.game)(state, loadAction);

      await firebaseDatabase.ref(`roomStates/${newRoom._id}`).set([]);
      await firebaseDatabase.ref(`roomStates/${newRoom._id}/0`).set(patch);

      // set user game view
      const viewId = game.getViewsS(settings)[0]._id;
      if (room) {
        const snap = await collections.devices.where("roomId", "==", room._id).get();
        await Promise.all(snap.docs.map((snap) => snap.ref.update({ roomId: newRoom._id, viewId })));
      } else {
        await collections.devices.doc(deviceId).update({ roomId: newRoom._id, viewId });
      }
      return;
    }
  }
};
