import React, { useRef, useState } from "react";
import { range } from "../../../engine/elements";
import { GameEntity } from "../../../engine/entities";
import { GamePosition } from "../../../engine/position";
import { useRoomState } from "../../../engine/roomState";
import { ViewEntities } from "../../../engine/views";
import { CircleSelectorObject } from "../../../objects/_old.viewT/circleSelector";
import { CircleSelectorLineObject } from "../../../objects/_old.viewT/circleSelectorLine";
import { RectObject } from "../../../objects/_old.viewT/rect";
import { TransformObject } from "../../../objects/_old.viewT/transform";
import { TriangleObject } from "../../../objects/_old.viewT/triangle";
import { backgammonColors } from "../data";
import { BackgammonAction } from "../resolvers";
import { backgammonAreas as areas, BackgammonState } from "../shared";
import { backgammonCanMoveFrom, backgammonCanMoveTo, backgammonGetMaxDist } from "../utils";

export const useBackgammonBackground: ViewEntities = () => {
  return [
    {
      _id: "background",
      position: new GamePosition(0, 0),
      content: backGammonBoard,
    },
  ];
};

export const useBackgammonPlayerButtons: ViewEntities = (generator, { playerIndex }) => {
  const [s, dispatch] = useRoomState<BackgammonState, BackgammonAction>();
  const [selection, setSelection] = useState(-1);
  const selected = selection !== -1;
  const timeoutRef = useRef<any>();

  if (s.phase === "INIT") return [];

  if (playerIndex === "all") playerIndex = s.data.currentPlayer;

  if (s.data.currentPlayer !== playerIndex) return [];

  const entities: GameEntity[] = [];

  const dices = s
    .on("DICES")
    .on("BOARD")
    .getElements()
    .excludeData({ played: 1 })
    .mapData("value");

  if (dices[1] === dices[0]) dices.pop();
  const dist = backgammonGetMaxDist(s, playerIndex);

  let canPlay = false;
  for (const fromIndex of range(26)) {
    if (selected && selection !== fromIndex) continue;
    const [canMoveFrom, fromCount] = backgammonCanMoveFrom(s, playerIndex, fromIndex, dist);
    let playable = false;
    if (canMoveFrom) {
      for (const dice of dices) {
        const toIndex = playerIndex ? fromIndex + dice : fromIndex - dice;
        const [canMoveTo, toCount] = backgammonCanMoveTo(s, playerIndex, fromIndex, toIndex, dist);
        if (canMoveTo) {
          playable = true;
          if (selected) {
            entities.push(
              toCount === -1
                ? generator.fromPlacement(
                    `from_${fromIndex}_${fromCount}_to_${toIndex}_${toCount}`,
                    areas.out.getPlacement([playerIndex, -0.5]),
                    <CircleSelectorObject
                      size={77}
                      depth={160}
                      onClick={() => {
                        dispatch({ type: "MOVE", fromIndex, toIndex, playerIndex });
                        setSelection(-1);
                      }}
                      visible
                    />,
                  )
                : generator.fromPlacement(
                    `from_${fromIndex}_${fromCount}_to_${toIndex}_${toCount}`,
                    areas.board.getPlacement([toIndex], toCount),
                    <CircleSelectorObject
                      size={70}
                      depth={10}
                      onClick={() => {
                        dispatch({ type: "MOVE", fromIndex, toIndex, playerIndex });
                        setSelection(-1);
                      }}
                      visible
                    />,
                  ),
            );
          }
        }
      }
    }
    if (playable) {
      canPlay = true;
      entities.push(
        generator.fromPlacement(
          `from_${fromIndex}_${fromCount}`,
          areas.board.getPlacement([fromIndex], fromCount - 1),
          <CircleSelectorLineObject
            size={70}
            depth={10}
            around
            onClick={() => {
              setSelection(selection !== fromIndex ? fromIndex : -1);
            }}
            selected={selected}
            visible
          />,
        ),
      );
    } else if (selected) {
      setSelection(-1);
    }
  }

  if (timeoutRef.current) {
    clearTimeout(timeoutRef.current);
  }
  if (!canPlay) {
    timeoutRef.current = setTimeout(() => dispatch({ type: "NO_MOVE", playerIndex }), 1000);
  }
  return entities;
};

const boardColor = "#666666";
const backHeight = 900;
const backBorders = 30;
const centralBorder = 40;
const sectionWidth = 90;
const backWidth = centralBorder + 12 * sectionWidth + 2 * backBorders;
const backDepth = 20;

const getBackTriangle = (index) => (
  <TransformObject key={index} x={index * sectionWidth}>
    <TriangleObject
      depth={0.5}
      color={backgammonColors[index % 2]}
      width={sectionWidth}
      height={backHeight / 3}
      shadow={false}
    />
  </TransformObject>
);

const backGammonBoard = (
  <>
    <RectObject width={backWidth} height={backHeight} radius={8} color={boardColor} depth={5}>
      <TransformObject x={-centralBorder / 2 - 5.5 * sectionWidth} y={-backHeight / 3 + backBorders}>
        {range(6).map(getBackTriangle)}
      </TransformObject>
      <TransformObject x={+centralBorder / 2 + 0.5 * sectionWidth} y={-backHeight / 3 + backBorders}>
        {range(6).map(getBackTriangle)}
      </TransformObject>
      <TransformObject x={-centralBorder / 2 - 0.5 * sectionWidth} y={+backHeight / 3 - backBorders} rz={180}>
        {range(6).map(getBackTriangle)}
      </TransformObject>
      <TransformObject x={+centralBorder / 2 + 5.5 * sectionWidth} y={+backHeight / 3 - backBorders} rz={180}>
        {range(6).map(getBackTriangle)}
      </TransformObject>
    </RectObject>
    <RectObject width={centralBorder} height={backHeight} depth={backDepth} radius={8} color={boardColor} />
    <TransformObject y={backHeight / 2 - backBorders / 2}>
      <RectObject width={backWidth} height={backBorders} depth={backDepth} radius={8} color={boardColor} />
    </TransformObject>
    <TransformObject y={-backHeight / 2 + backBorders / 2}>
      <RectObject width={backWidth} height={backBorders} depth={backDepth} radius={8} color={boardColor} />
    </TransformObject>
    <TransformObject x={-backWidth / 2 + backBorders / 2}>
      <RectObject width={backBorders} height={backHeight} depth={backDepth} radius={8} color={boardColor} />
    </TransformObject>
    <TransformObject x={backWidth / 2 - backBorders / 2}>
      <RectObject width={backBorders} height={backHeight} depth={backDepth} radius={8} color={boardColor} />
    </TransformObject>
  </>
);
