import React, { useContext } from "react";

export type UserAction = { type: "facebookLogin" } | { type: "logout" };

export type User = firebase.User;

export type UserContextValue<N extends boolean = false> = [
  N extends true ? User | null : User,
  (action: UserAction) => any,
];

export const UserContext = React.createContext<UserContextValue<true>>([null, () => void 0]);

export const UserProvider = UserContext.Provider;

export const useUser = <N extends boolean = false>(): UserContextValue<N> => {
  return useContext(UserContext) as any;
};
