import _ from "lodash";
import { range } from "../../engine/elements";
import { GameResolvers, InvalidAction, LoadAction } from "../../engine/resolvers";
import { TheGamePhase, TheGameState, TheGameSettings, theGameElementTypes as types } from "./shared";

export type TheGameAction =
  | LoadAction<TheGameSettings>
  | { type: "START" }
  | { type: "DRAW"; playerIndex: number }
  | { type: "PLAY"; spotIndex: number; value: number; playerIndex: number };

export type TheGameResolvers = GameResolvers<TheGameState, TheGameAction>;

const notStartedResolvers: TheGameResolvers = {
  LOAD: (s, { settings: { nbPlayers } }) => {
    s.setData({ nbPlayers, cardsPerPlayer: Math.max(9 - nbPlayers, 6), spotsValues: [1, 1, 100, 100] });
    s.addElements(_.range(2, 99).map((value) => types.card.create(`card_${value}`, { value })))
      .shuffle()
      .moveTo(s.on("DECK"));
  },
  START: (s) => {
    s.setPhase(TheGamePhase.PLAY);
    for (const playerIndex of range(s.data.nbPlayers)) {
      s.on("DECK")
        .getElements()
        .take(s.data.cardsPerPlayer)
        .waitBetween()
        .moveTo(s.on("HAND").on(playerIndex));
    }
  },
};

const startedResolvers: TheGameResolvers = {
  PLAY: (s, { spotIndex, value, playerIndex }) => {
    const spotValue = s.data.spotsValues[spotIndex];
    const diff = (spotIndex <= 1 ? 1 : -1) * (spotValue - value);
    if (diff > 0 && diff !== 10) throw new InvalidAction();

    s.on("HAND")
      .on(playerIndex)
      .getElements()
      .filterData({ value })
      .take(1)
      .moveTo(s.on("SPOT").on(spotIndex));
    s.updateData(`spotsValues[${spotIndex}]`, value);
  },
  DRAW: (s, { playerIndex }) => {
    const cardsToDraw =
      s.data.cardsPerPlayer -
      s
        .on("HAND")
        .on(playerIndex)
        .toArray().length;
    if (cardsToDraw <= 0) throw new InvalidAction();

    s.on("DECK")
      .getElements()
      .take(cardsToDraw, true)
      .expect((elements) => elements.length > 0)
      .moveTo(s.on("HAND").on(playerIndex));
  },
};

export const theGameResolvers = {
  [TheGamePhase.INIT]: notStartedResolvers,
  [TheGamePhase.PLAY]: startedResolvers,
};
