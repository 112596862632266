import React from "react";
import { getShapeLineGeometry } from "../../engine/_old.viewT/geometry";
import { createUseFactory } from "../../engine/factory";
import { getCircleShape } from "./circle";
import { GeometryLineObject } from "./geometryLine";

interface Props {
  color: string;
  size: number;
  linewidth: number;
  children?: any;
}

export const CircleLineObject = ({ size, ...rest }: Props) => {
  const geometry = useCircleLineFactory({ size });

  return <GeometryLineObject {...{ geometry }} {...rest} />;
};

const circleLineFactory = ({ size }) => {
  const shape = getCircleShape(size);
  return getShapeLineGeometry({ shape });
};

export const useCircleLineFactory = createUseFactory(circleLineFactory);
