import { Object3D } from "three";
import { linear } from "../easing";
import { degToRad, GamePosition } from "../position";

const pTransformer = (p1, p2) => (p) => (p <= p1 ? 0 : p >= p2 ? 1 : (p - p1) / (p2 - p1));

export interface GameTransition {
  update(now: number): this;
  isRunning(): boolean;
  dispose(): this;
}

export class ObjectTransition implements GameTransition {
  private startAt = Date.now();
  private running = true;
  private transforms: ((p) => GamePosition)[] = [];

  constructor(private object: Object3D, private from: GamePosition, private duration: number, private speed = linear) {}

  public linearTo(to: GamePosition, p1 = 0, p2 = 1) {
    const diff = to.getDiff(this.from);
    return this.linear(diff, p1, p2);
  }

  public linear(diff: GamePosition, p1 = 0, p2 = 1) {
    const transformP = pTransformer(p1, p2);
    this.transforms.push((p) => diff.clone().multiply(transformP(p)));
    return this;
  }

  public triangle(diff: GamePosition, p1 = 0, p2 = 0.5, p3 = p2, p4 = 1) {
    const diff1 = diff.clone().multiply(-1);
    this.linear(diff, p1, p2);
    this.linear(diff1, p3, p4);
    return this;
  }

  private currentDiff(p: number) {
    return this.transforms.map((transform) => transform(p)).reduce((prev, diff) => prev.add(diff), new GamePosition());
  }

  public update(now) {
    let p_ = (now - this.startAt) / this.duration;
    if (p_ > 1) p_ = 1;
    const p = this.speed(p_);
    if (!this.running) return this;
    const object = this.object;
    const diff = this.currentDiff(p);
    const position = diff.add(this.from);
    object.position.set(position.x, position.y, position.z);
    object.rotation.set(degToRad * position.rx, degToRad * position.ry, degToRad * position.rz);
    object.scale.set(position.scale, position.scale, position.scale);
    if (p === 1) {
      this.running = false;
    }
    return this;
  }

  public isRunning() {
    return this.running;
  }

  public dispose() {
    this.running = false;
    return this;
  }
}

export class WaitTransition implements GameTransition {
  private startAt = Date.now();
  private running = true;

  constructor(private duration: number) {}

  public update(now: number) {
    if (now > this.startAt + this.duration) this.running = false;
    return this;
  }

  public isRunning() {
    return this.running;
  }

  public dispose() {
    this.running = false;
    return this;
  }
}
