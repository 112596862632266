import { GameElement } from "./element";
import { GamePosition, getViewPosition } from "./position";

export const MovingDuration = 600;

export const sortEntityMovingIndex = (a: GameEntity, b: GameEntity) =>
  !a.position || !b.position ? 0 : (a.movingIndex ?? 0) - (b.movingIndex ?? 0);

export interface GameEntity {
  _id: string;
  content: { type: any };
  position: GamePosition | null;
  movingIndex?: number;
  source?: any;
  version?: number;
}

export interface GamePlacement {
  area: string;
  data: number[];
}

export class EntityGenerator {
  constructor(private viewPosition, private context = {}) {}

  public fromPlacement = (
    _id: string,
    placement: GamePlacement,
    content: any,
    movingIndex?: number,
    source?: any,
    version?: number,
  ): GameEntity => {
    return {
      _id,
      content,
      position: getViewPosition(this.viewPosition, placement, this.context, source),
      movingIndex,
      version,
    };
  };

  public overEntity = (entity: GameEntity, content): GameEntity => {
    const position = entity.position;
    if (position && position.z) position.z += 0.5;
    return {
      _id: "clone_" + entity._id,
      content,
      position,
      movingIndex: entity.movingIndex,
    };
  };

  public overElement = (element: GameElement, content: React.ReactNode): GameEntity => {
    return this.fromPlacement("clone_" + element._id, element.placement, content, element.movingIndex + 0.5, undefined);
  };
}
