import React from "react";
import { BufferGeometry } from "three";
import { simpleLineMaterialFactory } from "../../engine/_old.viewT/material";
import { useLineObject } from "../../engine/_old.viewT/object";
import { TransformObject } from "./transform";

interface Props {
  geometry: BufferGeometry;
  color: string;
  children?: any;
}

export const GeometryLineObject = ({ geometry, color, children }: Props) => {
  const material = simpleLineMaterialFactory({ color });

  useLineObject(geometry, material);

  return children ? <TransformObject z={0.2}>{children}</TransformObject> : null;
};
