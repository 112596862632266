import { GamePosition } from "../../engine/position";
import { GameView } from "../../engine/views";
import { CodeNameWordObjectS, CodeNameAgentObjectS } from "./elements";
import { useCodeNameButtons } from "./entities";
import { codeNameAreas as areas, codeNameElementTypes as types, CodeNameState } from "./shared";

export const codeNameGetViewsS = () => [
  { _id: "board", view: boardView, label: "Joueurs" },
  { _id: "spy", view: spyView, label: "Espions" },
];

const agentWidth = 200;
const agentHeight = 100;
const agentMargin = 15;

const dx = agentWidth + agentMargin;
const dy = agentHeight + agentMargin;

const rootView = new GameView<CodeNameState>()
  .addEntities(useCodeNameButtons)
  .setElementType(types.word, CodeNameWordObjectS)
  .setElementType(types.agent, CodeNameAgentObjectS)
  .setArea(areas.board, ([x, y, z]) => new GamePosition(0, 0, z).addGrid(dx, -dy, x - 2, y - 1.4))
  .setArea(areas.deck, ([x, z]) => new GamePosition(0, 3 * dy).addLine(dx, x - 1.5).addZ(x < 4 ? 5 * z : z));

const boardView = rootView;

const spyView = rootView
  .fork()
  .addContext(() => ({ asSpy: true }))
  .setArea(areas.deck, () => null)
  .superSetArea(areas.board, (parent, _a, _b, element) =>
    !parent || types.agent.is(element) ? null : parent.addY(dy / 2).scaleAll(1.2),
  );
