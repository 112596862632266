import React from "react";
import { CircleObjectS } from "../../objects/circle";
import { DiceObjectS } from "../../objects/dice";
import { backgammonColors } from "./data";

export const BackgammonPawnObjectS = ({ data: { playerIndex } }) => (
  <CircleObjectS size={70} fill={backgammonColors[playerIndex]} stroke="black" />
);

export const BackgammonDiceObjectS = ({ data: { playerIndex, played, value, count } }) => {
  return (
    <DiceObjectS
      value={value}
      fill={backgammonColors[playerIndex]}
      size={50}
      played={played}
      count={count}
      stroke="black"
      dots={{ fill: "black" }}
    />
  );
};
