import React, { useState } from "react";
import { range } from "../../engine/elements";
import { EntityGenerator, GameEntity } from "../../engine/entities";
import { GamePosition } from "../../engine/position";
import { useRoomState } from "../../engine/roomState";
import { ViewEntities } from "../../engine/views";
import { ButtonObjectS } from "../../objects/button";
import { CardSelectorObjectS, CardObjectS } from "../../objects/card";
import { CircleSelectorObjectS } from "../../objects/circle";
import { HexagonWObjectS } from "../../objects/hexagonW";
import { RectSelectorObjectS } from "../../objects/rect";
import { TextObjectS } from "../../objects/text";
import BACK from "./assets/BACK.jpg";
import {
  CataneDevCardType,
  cataneImages,
  CatanePawnType,
  catanePlayersNames,
  cataneResources,
  CataneCardType,
} from "./data";
import { CataneAction } from "./resolvers";
import { cataneAreas as areas, CataneState, cataneElementTypes as types } from "./shared";

export const useCataneBack: ViewEntities = () => {
  return [
    {
      _id: "back",
      content: <HexagonWObjectS width={800} image={BACK} fill="white" fillOpacity={0.5} stroke="black" />,
      position: new GamePosition(30, -75),
    },
  ];
};

export const useCatanePlayerPlaceButtons: ViewEntities = (generator, { playerIndex, options, setOptions }) => {
  const [s, dispatch] = useRoomState<CataneState, CataneAction>();
  const { installation, secondColony } = s.data.players[playerIndex];

  if (options[0] !== "PLACE" || s.pending) return [];
  const pawnType = options[1];

  const pawns = s.getElements().filterType(types.pawn);
  const tiles = s.getElements().filterType(types.tile);

  const playerBorders = pawns
    .filterArea(areas.boardBorder)
    .filterData({ playerIndex })
    .getPoints();
  const playerCorners = pawns
    .filterArea(areas.boardCorner)
    .filterData({ playerIndex })
    .getPoints()
    .mergeWith(playerBorders.getHexBorderCorners())
    .filterUniq();

  // colony
  if (pawnType === CatanePawnType.COLONY) {
    let corners = playerCorners;
    if (installation < 4) {
      corners = tiles
        .getPoints()
        .getHexTileCorners()
        .filterUniq();
    }

    const filledCorners = pawns.filterArea(areas.boardCorner).getPoints();

    const excludedCorners = filledCorners
      .getHexCornerBorders()
      .filterUniq()
      .getHexBorderCorners()
      .filterUniq();

    const availableCorners = corners.excludePoints(excludedCorners);
    const buttons: GameEntity[] = availableCorners.toArray().map(([x, y]) => {
      return generator.fromPlacement(
        `colony_${x}_${y}`,
        areas.boardCorner.getPlacement([x, y], 5),
        <CircleSelectorObjectS
          size={30}
          visible
          onPress={async () => {
            await dispatch({ type: "PLACE_PAWN", pawnType: CatanePawnType.COLONY, playerIndex, point: [x, y] });
            setOptions(options.slice(0, -3));
          }}
        />,
        s.movingIndex,
      );
    });
    if (installation === 4) {
      buttons.push(getBackButton(setOptions, -2));
    }
    return buttons;
  }
  // city
  else if (pawnType === CatanePawnType.CITY) {
    const playerColonies = pawns
      .filterData({ type: CatanePawnType.COLONY, playerIndex })
      .filterArea(areas.boardCorner)
      .getPoints();

    const buttons: GameEntity[] = playerColonies.toArray().map(([x, y]) => {
      return generator.fromPlacement(
        `city_${x}_${y}`,
        areas.boardCorner.getPlacement([x, y], 1),
        <RectSelectorObjectS
          width={32}
          height={32}
          radius={5}
          visible
          onPress={async () => {
            await dispatch({ type: "PLACE_PAWN", pawnType: CatanePawnType.CITY, playerIndex, point: [x, y] });
            setOptions(options.slice(0, -3));
          }}
        />,
        s.movingIndex,
      );
    });
    if (installation === 4) {
      buttons.push(getBackButton(setOptions, -2));
    }
    return buttons;
  }
  // road
  else if (pawnType === CatanePawnType.ROAD) {
    const filledBorders = pawns.filterArea(areas.boardBorder).getPoints();

    const allBorders = tiles
      .getPoints()
      .getHexTileBorders()
      .filterUniq();
    const corners = installation === 3 && secondColony ? s.fn.createPoints([secondColony]) : playerCorners;
    const roads = corners
      .getHexCornerBorders()
      .filterUniq()
      .excludePoints(filledBorders)
      .filterPoints(allBorders);

    const buttons: GameEntity[] = roads.toArray().map(([x, y]) => {
      return generator.fromPlacement(
        `road_${x}_${y}`,
        areas.boardBorder.getPlacement([x, y], 5),
        <RectSelectorObjectS
          width={15}
          height={40}
          radius={5}
          visible
          onPress={async () => {
            await dispatch({ type: "PLACE_PAWN", pawnType: CatanePawnType.ROAD, playerIndex, point: [x, y] });
            setOptions(options.slice(0, -3));
          }}
        />,
        s.movingIndex,
      );
    });
    if (installation === 4) {
      buttons.push(getBackButton(setOptions, -2));
    }
    return buttons;
  }
  // thief
  else if (pawnType === types.thief.name) {
    const thiefPoint = s
      .getElements()
      .filterType(types.thief)
      .getPoints();
    const allTiles = tiles.getPoints();
    const availableTiles = pawns
      .filterArea(areas.boardCorner)
      .excludeData({ playerIndex })
      .getPoints()
      .getHexCornerTiles()
      .filterUniq()
      .filterPoints(allTiles)
      .excludePoints(thiefPoint);

    return availableTiles.toArray().map(([x, y]) => {
      return generator.fromPlacement(
        `thief_${x}_${y}`,
        areas.boardTile.getPlacement([x, y], 1),
        <CircleSelectorObjectS
          size={50}
          onPress={() => dispatch({ type: "MOVE_THIEF", playerIndex, point: [x, y] })}
        />,
      );
    });
  }
  return [];
};

export const useCatanePlayerChoiceButtons: ViewEntities = (generator, { playerIndex, options, setOptions }) => {
  const [s, dispatch] = useRoomState<CataneState, CataneAction>();
  const { discardCards } = s.data.players[playerIndex];
  const [selection, setSelection] = useState<string[]>([]);

  if (s.pending) return [];

  if (!options[0]) {
    return getChoiceButton([
      {
        content: <TextObjectS text={"Lancer les dés"} />,
        onPress: () => dispatch({ type: "ROLL_DICE", playerIndex }),
      },
      { content: <TextObjectS text={"Construire"} />, onPress: () => setOptions(["BUY"]) },
      {
        content: <TextObjectS text={"Acheter carte dev."} />,
        onPress: () => dispatch({ type: "PICK_DEV_CARD", playerIndex }),
      },
      { content: <TextObjectS text={"Jouer carte dev."} />, onPress: () => setOptions(["DEV"]) },
      { content: <TextObjectS text={"Commercer"} />, onPress: () => setOptions(["EXCHANGE"]) },
      { content: <TextObjectS text={"Donner des cartes"} />, onPress: () => setOptions(["GIVE"]) },
    ]);
  } else if (options[0] === "BUY") {
    const options = [CatanePawnType.ROAD, CatanePawnType.COLONY, CatanePawnType.CITY].map((type) => ({
      content: <TextObjectS text={`Construire ${type}`} />,
      onPress: () => setOptions(["BUY", "PAWN", type]),
    }));
    return [getBackButton(setOptions), ...getChoiceButton(options)];
  } else if (options[0] === "DISCARD") {
    const playerCards = s
      .on("PLAYER_HAND")
      .on(playerIndex)
      .getElements()
      .filterType(types.card)
      .toArray();
    return [
      ...getCardSelectionButtons(playerCards, selection, setSelection, generator),
      ...getChoiceButton([
        {
          content: <TextObjectS text={`Jeter ${discardCards} cartes`} />,
          onPress: async () => {
            await dispatch({ type: "DISCARD_CARDS", selection, playerIndex });
            setSelection([]);
          },
        },
      ]),
    ];
  } else if (options[0] === "EXCHANGE") {
    const playerCards = s
      .on("PLAYER_HAND")
      .on(playerIndex)
      .getElements()
      .filterType(types.card)
      .toArray();
    return [
      getBackButton(setOptions),
      ...getCardSelectionButtons(playerCards, selection, setSelection, generator),
      ...getResourceButtons(async (wantedType: CataneCardType) => {
        await dispatch({ type: "EXCHANGE", selection, playerIndex, wantedType });
        setSelection([]);
      }),
    ];
  } else if (options[0] === "GIVE") {
    const playerCards = s
      .on("PLAYER_HAND")
      .on(playerIndex)
      .getElements()
      .filterType(types.card)
      .toArray();
    const opponentsIndexes = range(s.data.nbPlayers).filter((index) => index !== playerIndex);
    const options = opponentsIndexes.map((opponentIndex) => ({
      content: <TextObjectS text={`Donner � ${catanePlayersNames[opponentIndex]}`} />,
      onPress: async () => {
        await dispatch({ type: "GIVE", playerIndex, opponentIndex, selection });
        setSelection([]);
      },
    }));
    return [
      getBackButton(setOptions),
      ...getChoiceButton(options),
      ...getCardSelectionButtons(playerCards, selection, setSelection, generator),
    ];
  } else if (options[0] === "STEAL") {
    const options = s.data.players[playerIndex]
      .stealFrom!.filter((index) => index !== playerIndex)
      .map((opponentIndex) => ({
        content: <TextObjectS text={`Voler "${catanePlayersNames[opponentIndex]}"`} />,
        onPress: () => dispatch({ type: "STEAL", playerIndex, opponentIndex }),
      }));
    return getChoiceButton(options);
  } else if (options[0] === "DEV") {
    const types = [
      CataneDevCardType.KNIGHT,
      CataneDevCardType.ROADS,
      CataneDevCardType.INVENTION,
      CataneDevCardType.MONOPOLY,
    ];
    const options = types.map((type) => ({
      content: <TextObjectS text={type} />,
      onPress: () => dispatch({ type: "PLAY_DEV_CARD", cardType: type, playerIndex }),
    }));
    return [getBackButton(setOptions), ...getChoiceButton(options)];
  } else if (options[0] === "MONOPOLY" || options[0] === "FREE_RESOURCE") {
    const type = options[0];
    return getResourceButtons((wantedType) => dispatch({ type, playerIndex, wantedType }));
  }
  return [];
};

const getBackButton = (setOptions, step = -1): GameEntity => ({
  _id: "choice_back",
  content: (
    <ButtonObjectS width={150} height={75} onPress={() => setOptions((options) => options.slice(0, step))}>
      <TextObjectS text={"Retour"} />
    </ButtonObjectS>
  ),
  position: new GamePosition(360, 200),
});

const getChoiceButton = (options: { content; onPress }[]): GameEntity[] =>
  options.map(({ content, onPress }, index) => {
    return {
      _id: "choice_" + index,
      content: <ButtonObjectS onPress={onPress}>{content}</ButtonObjectS>,
      position: getPlayerViewPlayerChoicePosition(index),
    };
  });

const getCardSelectionButtons = (cards, selection, setSelection, generator: EntityGenerator) =>
  cards.map((card) => {
    const cardIndex = selection.indexOf(card._id);
    return generator.overElement(
      card,
      <CardSelectorObjectS
        height={90}
        selected={cardIndex !== -1}
        onPress={() => {
          const selection_ = [...selection];
          if (cardIndex === -1) {
            selection_.push(card._id);
          } else {
            selection_.splice(cardIndex, 1);
          }
          setSelection(selection_);
        }}
      />,
    );
  });

const getResourceButtons = (onPress): GameEntity[] =>
  cataneResources.map((resourceType, index) => {
    return {
      _id: "resource_" + index,
      content: (
        <>
          <CardObjectS height={110} image={cataneImages[resourceType]} />
          <CardSelectorObjectS height={110} onPress={() => onPress(resourceType)} />
        </>
      ),
      position: getPlayerViewPlayerChoicePosition(0.4 * index - 0.3),
    };
  });

const getPlayerViewPlayerChoicePosition = (x) => new GamePosition(550, 200).addInlineGrid(215, -115, 2, x);
