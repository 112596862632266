import React from "react";
import { StyleSheet, View } from "react-native";
import { GameDefinition } from "../../engine/definition";
import { appGames } from "../../games/games";
import { GameItem } from "./GameItem";

const styles = StyleSheet.create({
  grid: {
    flexDirection: "row",
    flexWrap: "wrap",
    padding: 2,
  },
  item: {
    height: 180,
    padding: 2,
    flexBasis: 180,
  },
});

export const GameList = ({ onSelect }: { onSelect?: (game: GameDefinition) => any }) => {
  return (
    <View style={styles.grid}>
      {appGames.map((game) => (
        <View key={game._id} style={styles.item}>
          <GameItem game={game} onPress={onSelect ? () => onSelect(game) : undefined} />
        </View>
      ))}
    </View>
  );
};
