import { GameDefinition } from "../../engine/definition";
import { cataneGetViewsT } from "./_old.viewT/views";
import cover from "./assets/cover.jpg";
import { CataneAction, cataneResolvers } from "./resolvers";
import { CataneState, CataneSettings, cataneStackDef } from "./shared";
import { cataneGetViewsS } from "./views";

export const cataneGame: GameDefinition<CataneState, CataneAction, CataneSettings> = {
  _id: "catane",
  label: "Catane",
  players: "3-4",
  timer: "60mn",
  cover,
  youtube: "tvI8eFfm6Bk",
  description: [
    "Exploitez vos ressources et canalisez votre croissance en vous méfiant de vos voisins. Lancez-vous à la conquête d’une île vierge mais pleine de ressources.",
    "Saurez-vous construire vos villes et colonies plus vite que vos adversaires ? Construisez votre route vers la victoire !",
  ],
  amazon: "B01G1I2GZQ",
  rules: "http://www.ludovalais.ch/img_docjeux/Les+colons+de+Catane.pdf",
  stage: 2,
  version: 1,
  getViewsT: cataneGetViewsT,
  getViewsS: cataneGetViewsS,
  stackDef: cataneStackDef,
  resolvers: cataneResolvers,
  settingsFields: [{ name: "nbPlayers", label: "Joueurs", type: "number", defaultValue: 2, min: 2, max: 4 }],
};
