import React from "react";
import { BufferGeometry, FrontSide, DoubleSide } from "three";
import { useSimpleMaterialFactory } from "../../engine/_old.viewT/material";
import { useMeshObject } from "../../engine/_old.viewT/object";
import { useRenderer } from "../../engine/contexts";
import { TransformObject } from "./transform";

interface Props {
  geometry: BufferGeometry;
  depth?: number;
  opacity?: number;
  color: string;
  backColor?: string;
  image?: string;
  imageCenter?: [number, number];
  imageRepeat?: [number, number];
  children?: any;
  shadow?: boolean;
}

export const GeometryObject = ({
  geometry,
  depth = 1,
  color,
  backColor,
  image,
  imageCenter,
  imageRepeat,
  children,
  opacity = 1,
  shadow = true,
}: Props) => {
  const { engine } = useRenderer();

  const multiMaterial = geometry.type === "ExtrudeBufferGeometry";
  const basic = engine.compatMode;
  const material1 = useSimpleMaterialFactory({
    color,
    opacity,
    image,
    engine,
    imageCenter,
    imageRepeat,
    side: !multiMaterial && depth !== 0 ? DoubleSide : FrontSide,
    basic,
  });
  const material2 = useSimpleMaterialFactory({ color: backColor ? backColor : color, opacity, basic });
  const material = multiMaterial ? [material1, material2] : material1;

  useMeshObject(geometry, material, { shadow, opacity });

  return children ? <TransformObject z={depth + 0.2}>{children}</TransformObject> : null;
};
