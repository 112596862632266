import { GamePosition } from "../../engine/position";
import { GameView, RoomView } from "../../engine/views";
import { BackgammonPawnObjectS, BackgammonDiceObjectS } from "./elements";
import { useBackgammonBackgroundS, useBackgammonPlayerButtonsS } from "./entities";
import { backgammonAreas as areas, backgammonElementTypes as types, BackgammonState } from "./shared";

export const backgammonGetViewsS = (): RoomView[] => [
  { _id: "player/0", label: "Joueur 1", view: playerView, context: { playerIndex: 0 } },
  { _id: "player/1", label: "Joueur 2", view: playerView, context: { playerIndex: 1 } },
  { _id: "player/all", label: "Joueur 1 et 2", view: playerView, context: { playerIndex: "all" } },
  { _id: "board", label: "Observateurs", view: boardView },
];

const boardView = new GameView<BackgammonState>()
  .addEntities(useBackgammonBackgroundS)
  .setElementType(types.pawn, BackgammonPawnObjectS)
  .setElementType(types.dice, BackgammonDiceObjectS)
  .setArea(areas.out, ([p, z]) =>
    p
      ? new GamePosition(-650, 370)
          .addVerticalLine(-10, z)
          .addRX(90)
          .addZ(1)
      : new GamePosition(-650, -370)
          .addVerticalLine(10, z)
          .addRX(-90)
          .addZ(1),
  )
  .setArea(areas.board, ([index, z]) =>
    index === 0
      ? new GamePosition(0, -36).addVerticalLine(-72, z)
      : index === 25
      ? new GamePosition(0, 36).addVerticalLine(72, z)
      : index < 13
      ? new GamePosition(0, -370)
          .addX(index > 6 ? 25 : -25)
          .addLine(90, index - 6.5)
          .addVerticalLine(72, z % 5)
          .addY(15 * Math.floor(z / 5))
          .addZ(1)
      : new GamePosition(0, 370)
          .addX(index > 18 ? -25 : 25)
          .addLine(-90, index - 18.5)
          .addVerticalLine(-72, z % 5)
          .addY(-15 * Math.floor(z / 5))
          .addZ(1),
  )
  .setArea(areas.dices, ([i, z]) =>
    i === 2
      ? new GamePosition(250, 0).addLine(60, z).addZ(1)
      : i === 0
      ? new GamePosition(650, -360).addVerticalLine(60, z).addZ(1)
      : new GamePosition(650, 360).addVerticalLine(-60, z).addZ(1),
  );

const playerView = boardView.fork().addEntities(useBackgammonPlayerButtonsS);
