import { useState } from "react";
import { range } from "../../engine/elements";
import { GamePosition } from "../../engine/position";
import { useRoomState } from "../../engine/roomState";
import { RoomView, GameView } from "../../engine/views";
import { CatanePawnType, catanePlayersNames } from "./data";
import {
  CataneCardObjectS,
  CataneDevCardObjectS,
  CataneDiceObjectS,
  CatanePawnObjectS,
  CataneThiefObjectS,
  CataneTileObjectS,
  CataneTokenObjectS,
  CatanePortObjectS,
} from "./elements";
import { useCatanePlayerPlaceButtons, useCatanePlayerChoiceButtons } from "./entities";
import { cataneAreas as areas, CatanePhase, CataneState, cataneElementTypes as types } from "./shared";

export const cataneGetViewsS = ({ nbPlayers }): RoomView[] => [
  ...range(nbPlayers).map((playerIndex) => ({
    _id: "player/" + playerIndex,
    label: `Joueur "${catanePlayersNames[playerIndex]}"`,
    view: playerView,
    context: { playerIndex },
  })),
  { _id: "board", view: boardView, label: "Observateurs" },
];

const boardView = new GameView<CataneState>()
  // .addEntities(useCataneBack)
  .setElementType(types.card, CataneCardObjectS)
  .setElementType(types.devCard, CataneDevCardObjectS)
  .setElementType(types.dice, CataneDiceObjectS)
  .setElementType(types.pawn, CatanePawnObjectS)
  .setElementType(types.port, CatanePortObjectS)
  .setElementType(types.thief, CataneThiefObjectS)
  .setElementType(types.tile, CataneTileObjectS)
  .setElementType(types.token, CataneTokenObjectS)
  .setArea(areas.deck, ([x, z], _, element) =>
    new GamePosition(-2.5 * dx + 30, 400)
      .addLine(dx, x)
      .addZ(types.tile.is(element) || types.port.is(element) ? 5 * z : z),
  )
  .setArea(areas.boardTile, ([x, y, z], _, element) =>
    boardPosition
      .clone()
      .addHexGrid(tillDiag, x, y)
      .addY(types.token.is(element) ? -30 : 0)
      .addZ(z),
  )
  .setArea(areas.boardCorner, ([x, y, z]) =>
    boardPosition
      .clone()
      .addHexCornerGrid(tillDiag, x, y)
      .addZ(z),
  )
  .setArea(areas.boardBorder, ([x, y, z], _, source) =>
    boardPosition
      .clone()
      .addHexBorderGrid(tillDiag, x, y)
      .addRz(types.port.is(source) && x + y > 4 ? -90 : 90)
      .addZ(z),
  )
  .setArea(areas.dice, ([p, x]) => new GamePosition(-425, 250).addVerticalLine(-180, p).addLine(45, x))
  .setArea(areas.playerBoard, ([p, l, x]) =>
    new GamePosition(-800, 300).addVerticalLine(-180, p).add(getPlayerBoardPosition(1, l, x)),
  )
  .setArea(areas.playerHand, ([p, z]) =>
    new GamePosition(-600, 175)
      .addVerticalLine(-180, p)
      .addLine(10, z)
      .addZ(z),
  );

const tillDiag = 150;
const cardWidth = 60;
const cardMargin = 10;

const dx = cardWidth + cardMargin;

const boardPosition = new GamePosition(-2 * 0.866 * tillDiag + 30, tillDiag);
const getPlayerBoardPosition = (dir, l, x) =>
  l === 0
    ? new GamePosition(dir === 1 ? 5 : 150, 0)
    : l === 1
    ? new GamePosition(0, -50).addLine(dir * 21, x).addRz(30)
    : l === 2
    ? new GamePosition(0, -100).addLine(dir * 35, x)
    : l === 3
    ? new GamePosition(0, -150).addLine(dir * 37, x)
    : l === 4
    ? new GamePosition(0, -300).addLine(dir * 30, x).multiplyScale(0.5)
    : null;

// player

const usePlayerContext = ({ playerIndex }) => {
  const [s] = useRoomState<CataneState>();
  const playerState = s.data.players[playerIndex];
  const { installation, discardCards, moveThief, stealFrom, freeRoads, freeResources, monopoly } = playerState;

  const [options, setOptions] = useState([]);
  const context = { playerIndex, options, setOptions };

  return s.phase === CatanePhase.INIT
    ? { ...context, options: ["WAIT"] }
    : installation < 4
    ? { ...context, options: ["PLACE", installation % 2 ? CatanePawnType.ROAD : CatanePawnType.COLONY] }
    : discardCards
    ? { ...context, options: ["DISCARD"] }
    : moveThief
    ? { ...context, options: ["PLACE", types.thief.name] }
    : stealFrom
    ? { ...context, options: ["STEAL"] }
    : freeRoads
    ? { ...context, options: ["PLACE", CatanePawnType.ROAD] }
    : freeResources
    ? { ...context, options: ["FREE_RESOURCE"] }
    : monopoly
    ? { ...context, options: ["MONOPOLY"] }
    : options[0] === "BUY" && options[1] === "PAWN"
    ? { ...context, pawnType: options[2] }
    : context;
};

const playerView = boardView
  .fork()
  .addContext(usePlayerContext)
  .addEntities(useCatanePlayerPlaceButtons)
  .addEntities(useCatanePlayerChoiceButtons)
  .superSetArea(areas.playerHand, (parent, [p, z], { playerIndex }) =>
    p !== playerIndex
      ? parent
      : new GamePosition(-7 * dx, -330)
          .addLine(0.8 * dx, z)
          .addZ(z)
          .scaleAll(1.5),
  );
