import React, { useState, useMemo } from "react";
import { StyleSheet } from "react-native";
import { Button, Surface, IconButton, Portal } from "react-native-paper";
import { useRoom } from "../../engine/room";
import { useRoomState } from "../../engine/roomState";
import { GameDescriptionDialog } from "../dialogs/GameDescriptionDialog";
import { LeaveDialog } from "../dialogs/LeaveDialog";
import { NewGameScreen } from "../screens/NewGameScreen";
import { Participants } from "../screens/ParticipantsScreen";
import { paperTheme } from "../theme";
import { MenuOffset } from "./MenuOffset";

const styles = StyleSheet.create({
  menuStyle: {
    width: 300,
  },
  toolbar: {
    alignContent: "center",
    backgroundColor: paperTheme.colors.accent,
    borderRadius: 4,
    flexDirection: "row",
  },
  toolbarButton: {
    margin: 4,
  },
  toolbarIconButton: {
    margin: 4,
  },
});

export const RoomToolbar = () => {
  const [{ game }] = useRoom();
  const [participantsVisible, setParticipantsVisible] = useState(false);
  const [desciptionVisible, setDescriptionVisible] = useState(false);
  const [newGameVisible, setNewGameVisible] = useState(false);
  const [leaveVisible, setLeaveVisible] = useState(false);

  return (
    <Surface style={styles.toolbar}>
      <ControlButton />
      <MenuOffset
        visible={participantsVisible}
        onDismiss={() => setParticipantsVisible(false)}
        contentStyle={styles.menuStyle}
        top={56}
        left={-69}
      >
        <Participants />
      </MenuOffset>
      <ToolbarButton icon="account-plus" label="Participants" onPress={() => setParticipantsVisible(true)} />
      <ToolbarIconButton icon="information-outline" label="Description" onPress={() => setDescriptionVisible(true)} />
      <MenuOffset
        visible={newGameVisible}
        onDismiss={() => setNewGameVisible(false)}
        contentStyle={styles.menuStyle}
        top={56}
        left={-133}
      >
        <NewGameScreen />
      </MenuOffset>
      <ToolbarIconButton icon="plus" label="Jeux" onPress={() => setNewGameVisible(true)} />
      <ToolbarIconButton icon="fullscreen" label="Plein écran" onPress={() => void 0} />
      <ToolbarIconButton icon="close" label="Fermer" onPress={() => setLeaveVisible(true)} />
      <Portal>
        <GameDescriptionDialog visible={desciptionVisible} onDismiss={() => setDescriptionVisible(false)} game={game} />
        <LeaveDialog visible={leaveVisible} onDismiss={() => setLeaveVisible(false)} />
      </Portal>
    </Surface>
  );
};

const ControlButton = () => {
  const [s, dispatch] = useRoomState();

  return useMemo(
    () =>
      s.phase === "INIT" ? (
        <ToolbarButton icon="play" label="Démarrer" onPress={() => dispatch({ type: "START" })} />
      ) : (
        <ToolbarButton icon="undo" label="Annuler" onPress={() => dispatch({ type: "UNDO" })} />
      ),
    [dispatch, s.phase],
  );
};

const ToolbarButton = ({ icon, label, onPress }) => (
  <Button style={styles.toolbarButton} color={"white"} icon={icon} onPress={onPress}>
    {label}
  </Button>
);

const ToolbarIconButton = ({ icon, onPress, label }) => (
  <IconButton
    style={styles.toolbarIconButton}
    icon={icon}
    onPress={onPress}
    color={"white"}
    accessibilityLabel={label}
  />
);
