import _ from "lodash";
import { GameElement } from "./element";
import { GamePlacement } from "./entities";
import { GamePosition } from "./position";
import { GameState } from "./state";

export const sortPlacement = (index = 0) => (a: GameElement, b: GameElement) =>
  a.placement.data[index] - b.placement.data[index];

export class GameArea {
  public stackComparator: (a: GameElement, b: GameElement) => number;

  constructor(public name: string, public stackIndex: number) {
    this.stackComparator = sortPlacement(stackIndex);
  }

  public isOn(indexes: number[] = []) {
    return (element: GameElement | GamePlacement) => {
      // @ts-ignore
      const placement: GamePlacement = element.placement ? element.placement : element;
      return placement.area === this.name && _.isEqual(placement.data.slice(0, indexes.length), indexes);
    };
  }

  public setOn(s: GameState, indexes: number[], z = 0) {
    return (element: GameElement) => {
      s.setElementPlacement(element, this.getPlacement(indexes, z++));
      return element;
    };
  }

  public add(s: GameState, elements: GameElement[], indexes: number[] = [], z = 0) {
    elements.forEach(this.setOn(s, indexes, z));
  }

  public getPlacement(indexes: number[], z = 0): GamePlacement {
    return { area: this.name, data: [...indexes, z] };
  }
}

export type AreaGetPosition = (data: number[], ctx: any, source: any) => GamePosition | null;
export type AreaGetPositionSuperSet = (
  parent: GamePosition | null,
  data: number[],
  ctx: any,
  source: any,
) => GamePosition | null;
