import React from "react";
import { GeometrySelectorObject } from "./geometrySelector";
import { useRectFactory } from "./rect";

interface Props {
  width: number;
  height: number;
  around?: boolean;
  radius?: number;
  depth?: number;
  depthRadius?: number;
  onClick: () => void;
  visible?: boolean;
  selected?: boolean;
  children?: any;
  style?: any;
}

export const RectSelectorObject = ({
  width,
  height,
  depth = 1,
  depthRadius = Math.min(depth / 3, 2),
  radius = 2 * depthRadius,
  ...rest
}: Props) => {
  const geometry = useRectFactory({ width, height, depth, depthRadius, radius });

  return <GeometrySelectorObject {...{ geometry, depth }} {...rest} />;
};
