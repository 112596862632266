export enum CodeNameAgentType {
  BLUE = "BLUE",
  RED = "RED",
  NEUTRAL = "NEUTRAL",
  ASSASSIN = "ASSASSIN",
}

export const codeNameColors = {
  [CodeNameAgentType.BLUE]: "#1976d2",
  [CodeNameAgentType.RED]: "#d32f2f",
  [CodeNameAgentType.NEUTRAL]: "#ffa000",
  [CodeNameAgentType.ASSASSIN]: "#455a64",
  word: "#ffe0b2",
};

export const codeNameWords = [
  "Page",
  "Uniforme",
  "Eau",
  "Boeuf",
  "Sens",
  "Majeur",
  "Cercle",
  "Chat",
  "Feuille",
  "Gorge",
  "Paris",
  "Himalaya",
  "Bourse",
  "Quartier",
  "Chine",
  "Champagne",
  "Bouchon",
  "Machine",
  "Bottes",
  "Chaîne",
  "But",
  "Radio",
  "Clé",
  "Somme",
  "Astérix",
  "Lumière",
  "Jeux",
  "Histoire",
  "Carrière",
  "Portable",
  "Roi",
  "Chance",
  "Vague",
  "Critique",
  "Napoléon",
  "Australie",
  "Noeud",
  "Argent",
  "Entrée",
  "Mine",
  "Lune",
  "Moustache",
  "Pigeon",
  "Angleterre",
  "Jungle",
  "Hôtel",
  "Cheval",
  "Vent",
  "Égalité",
  "Atlantic",
  "Mode",
  "Nain",
  "Rouleau",
  "Front",
  "Poire",
  "Boulet",
  "Louche",
  "Carreau",
  "Sortie",
  "Lunettes",
  "Plat",
  "Canon",
  "Chausson",
  "Pyramide",
  "Reine",
  "Bouteille",
  "Coton",
  "Temple",
  "Glace",
  "Ferme",
  "Licorne",
  "Poison",
  "Don",
  "Pendule",
  "Ballon",
  "Soldat",
  "Camembert",
  "Français",
  "Tennis",
  "Oiseau",
  "Banque",
  "Pomme",
  "Tambour",
  "Cellule",
  "Marque",
  "Plante",
  "Chef",
  "Vol",
  "Crochet",
  "Chevalier",
  "Vampire",
  "Canard",
  "Arc",
  "Cafard",
  "Égypte",
  "Résistance",
  "Trésor",
  "Maladie",
  "Charme",
  "Volume",
  "Roulette",
  "Dinosaure",
  "Chocolat",
  "Robot",
  "Ligne",
  "Membre",
  "Titre",
  "Cartouche",
  "Ronde",
  "Grain",
  "Bureau",
  "Religieuse",
  "Essence",
  "Main",
  "Jour",
  "Kiwi",
  "Étude",
  "Espion",
  "Plume",
  "Club",
  "Cabinet",
  "Bâton",
  "Sirène",
  "Patron",
  "Ampoule",
  "Palais",
  "Bande",
  "Atout",
  "Barre",
];
