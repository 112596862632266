import React from "react";

const messages = {
  onError: "Une erreur est intervenue.",
};

export class ErrorCatcher extends React.Component<{ errorComponent?; children }, {}> {
  public static getDerivedStateFromError(error) {
    // tslint:disable-next-line:no-console
    console.error(error);
    return { hasError: error };
  }

  public state = {
    hasError: false,
  };

  public shouldComponentUpdate() {
    if (this.state.hasError) {
      this.setState({ hasError: false });
    }
    return true;
  }

  public render() {
    if (this.state.hasError) {
      const Component = this.props.errorComponent || DefaultErrorComponent;
      return <Component error={this.state.hasError} />;
    }
    return this.props.children;
  }
}

const DefaultErrorComponent = ({ error }) => {
  return (
    <div>
      <h3>{messages.onError}</h3>
      <p>{error ? error.stack || error.message : null}</p>
    </div>
  );
};

export class EntityErrorCatcher extends React.Component<{ entity }> {
  public componentDidCatch(error): void {
    console.error(this.props.entity);
    throw error;
  }

  public render() {
    return this.props.children;
  }
}
