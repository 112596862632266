import { NavigationNativeContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { ErrorCatcher } from "../../engine/error";
import { HomePage } from "../pages/HomePage";

const Stack = createStackNavigator();

export const Router = () => (
  <ErrorCatcher>
    <NavigationNativeContainer>
      <Stack.Navigator initialRouteName="Root" screenOptions={{ header: () => null }}>
        <Stack.Screen name="Root" component={HomePage}></Stack.Screen>
      </Stack.Navigator>
    </NavigationNativeContainer>
  </ErrorCatcher>
);
