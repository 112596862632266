import React, { useMemo } from "react";
import { Path, CommonPathProps } from "react-native-svg";
import { PathD, selectorDefaultProps } from "../engine/svg";
import { ImageObjectS } from "./image";
import { TouchableObjectS } from "./touchable";

interface Props extends CommonPathProps {
  width: number;
  image?: any;
  radius?: number;
  opacity?: number;
}

export const HexagonWObjectS = ({ width, radius, onPress, image, ...rest }: Props) => {
  const d = useMemo(() => getHexagonWPath(width, radius), [radius, width]);

  return (
    <TouchableObjectS onPress={onPress}>
      {image && <ImageObjectS width={width} height={0.866 * width} image={image} d={d} />}
      <Path fill="none" d={d} {...rest} />
    </TouchableObjectS>
  );
};

export const HexagonWSelectorObjectS = (props: Props) => {
  return <HexagonWObjectS {...selectorDefaultProps} {...props} />;
};

export const getHexagonWPath = (width = 0, _radius = 0) => {
  const d = new PathD();

  const dy = 0.5 * 0.866 * width;
  const dx = 0.5 * width;
  // const r = Math.min(radius, dx);

  // const rc = 0.866 * r;
  // const rs = 0.5 * r;

  // if (r) {
  //   d.moveTo(-rc, -dy + rs)
  //     .curveTo(rc, -dy + rs, 0, -dy)
  //     .lineTo(dx - rc, -dy / 2 - rs)
  //     .curveTo(dx, -dy / 2 + r, dx, -dy / 2)
  //     .lineTo(dx, dy / 2 - r)
  //     .curveTo(dx - rc, dy / 2 + rs, dx, dy / 2)
  //     .lineTo(rc, dy - rs)
  //     .curveTo(-rc, dy - rs, 0, dy)
  //     .lineTo(-dx + rc, dy / 2 + rs)
  //     .curveTo(-dx, dy / 2 - r, -dx, dy / 2)
  //     .lineTo(-dx, -dy / 2 + r)
  //     .curveTo(-dx + rc, -dy / 2 - rs, -dx, -dy / 2)
  //     .close();
  // } else {
  d.moveTo(-dx, 0)
    .lineTo(-dx / 2, dy)
    .lineTo(dx / 2, dy)
    .lineTo(dx, 0)
    .lineTo(dx / 2, -dy)
    .lineTo(-dx / 2, -dy)
    .close();
  // }

  return d.toString();
};
