import _ from "lodash";
import { GameArea } from "./area";
import { GameElement } from "./element";
import { Elements } from "./elements";
import { GameState } from "./state";

export type Point = [number, number];

export const pointToString = ([x, y]: Point) => `${x}_${y}`;

export const elementToPoint = (element: GameElement): Point => element.placement.data.slice(0, 2) as any;

export const areEqualPoint = ([x1, y1], [x2, y2]) => x1 === x2 && y1 === y2;

export const excludePoints = (excludedPoints: Point[]) => {
  const pointStrings = excludedPoints.map(pointToString);
  return (point: Point): boolean => !pointStrings.includes(pointToString(point));
};

export const filterPoints = (allowedPoints: Point[]) => {
  const pointStrings = allowedPoints.map(pointToString);
  return (point: Point): boolean => pointStrings.includes(pointToString(point));
};

export const filterUniqPoints = () => {
  const pointStrings: string[] = [];
  return (point: Point): boolean => {
    const pointString = pointToString(point);
    if (!pointStrings.includes(pointString)) {
      pointStrings.push(pointString);
      return true;
    } else return false;
  };
};

export const gridDistribution = (columns: number) => {
  let x = 0;
  let y = 0;
  const getNextPoint = () => {
    const point: Point = [x, y];
    x++;
    if (x === columns) {
      x = 0;
      y++;
    }
    return point;
  };
  return () => getNextPoint();
};

export const hexGridDistribution = (radius: number) => {
  let x = radius / 2;
  let y = 0;
  const getNextPoint = () => {
    const point: Point = [x, y];
    x++;
    const xEnd = y <= radius ? 2 * radius + y / 2 : 3 * radius - y / 2;
    if (x === xEnd) {
      y++;
      x = y <= radius ? (radius - y) / 2 : (y - radius) / 2;
    }
    return point;
  };
  return () => getNextPoint();
};

export const arrayDistribution = (indexes: number[][]) => {
  let index = 0;
  const getNextPoint = () => {
    return indexes[index++];
  };
  return () => getNextPoint();
};

export const isOnePointOf = (points: Point[]) => (point: Point) =>
  !!points.find((point_) => areEqualPoint(point, point_));

export const getHexTileCorners = ([x, y]: Point): Point[] => {
  return [
    [x, y],
    [x + 0.5, y],
    [x + 1, y],
    [x, y + 1],
    [x + 0.5, y + 1],
    [x + 1, y + 1],
  ];
};

export const getHexTileBorders = ([x, y]: Point): Point[] => {
  return [
    [x + 0.25, y],
    [x + 0.75, y],
    [x, y + 0.5],
    [x + 1, y + 0.5],
    [x + 0.25, y + 1],
    [x + 0.75, y + 1],
  ];
};

export const getHexCornerBorders = ([x, y]: Point): Point[] => {
  const Dy = (x * 2 + y) % 2;
  return [
    [x + 0.25, y],
    [x - 0.25, y],
    [x, y + 0.5 - Dy],
  ];
};

export const getHexCornerTiles = ([x, y]: Point): Point[] => {
  return (x * 2 + y) % 2
    ? [
        [x - 0.5, y],
        [x - 1, y - 1],
        [x, y - 1],
      ]
    : [
        [x - 0.5, y - 1],
        [x - 1, y],
        [x, y],
      ];
};

export const getHexBorderCorners = ([x, y]: Point): Point[] => {
  return (y * 2) % 2
    ? [
        [x, y + 0.5],
        [x, y - 0.5],
      ]
    : [
        [x + 0.25, y],
        [x - 0.25, y],
      ];
};

export class Points {
  constructor(private state: GameState, private points: Point[]) {}

  public mergeWith(...sources: Points[]) {
    const points = [this, ...sources].flatMap((source) => source.toArray());
    return new Points(this.state, points);
  }

  public getElementsFrom(area: GameArea, group: Elements) {
    const filteredElements = group.filter(area.isOn()).toArray();
    const groupedElements = _.groupBy(filteredElements, (element) => pointToString(elementToPoint(element)));
    const selectedElements = this.points.flatMap((point) => {
      const key = pointToString(point);
      return groupedElements[key] || [];
    });
    return new Elements(this.state, selectedElements);
  }

  public filter(condition: (point: Point) => boolean) {
    const selected = this.points.filter(condition);
    return new Points(this.state, selected);
  }

  public filterPoints(points: Points | Point[]) {
    const array = Array.isArray(points) ? points : points.toArray();
    return this.filter(filterPoints(array));
  }

  public filterUniq() {
    return this.filter(filterUniqPoints());
  }

  public exclude(condition: (point: Point) => boolean) {
    const selected = this.points.filter((point) => !condition(point));
    return new Points(this.state, selected);
  }

  public excludePoints(points: Points | Point[]) {
    const array = Array.isArray(points) ? points : points.toArray();
    return this.filter(excludePoints(array));
  }

  public getFirst() {
    return this.points[0];
  }

  public toArray() {
    return this.points;
  }

  public includes = (point: Point) => this.points.includes(point);

  public getHexTileCorners() {
    const points = this.points.flatMap(getHexTileCorners);
    return new Points(this.state, points);
  }
  public getHexTileBorders() {
    const points = this.points.flatMap(getHexTileBorders);
    return new Points(this.state, points);
  }
  public getHexCornerTiles() {
    const points = this.points.flatMap(getHexCornerTiles);
    return new Points(this.state, points);
  }
  public getHexCornerBorders() {
    const points = this.points.flatMap(getHexCornerBorders);
    return new Points(this.state, points);
  }
  public getHexBorderCorners() {
    const points = this.points.flatMap(getHexBorderCorners);
    return new Points(this.state, points);
  }
}

export const createPoints = (state: GameState) => (points: Point[]) => {
  return new Points(state, points);
};
