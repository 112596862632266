import { useState } from "react";
import Animated from "react-native-reanimated";
import { CommonPathProps, G } from "react-native-svg";
import { GamePosition } from "./position";

// see https://github.com/mathisonian/svg-path-generator
export class PathD {
  private d = "";

  public moveTo(x: number, y: number) {
    this.d += `M${x} ${y}`;
    return this;
  }

  public lineTo(x: number, y: number) {
    this.d += `L${x} ${y}`;
    return this;
  }

  public curveTo(x: number, y: number, cx: number, cy: number) {
    this.d += `Q${cx} ${cy} ${x} ${y}`;
    return this;
  }

  public close() {
    this.d += "Z";
    return this.d;
  }

  public toString() {
    return this.d;
  }
}

export const getSvgX = (position: GamePosition) => position.x * (1 + position.z / 750);
export const getSvgY = (position: GamePosition) => -position.y * (1 + position.z / 750);
export const getSvgR = (position: GamePosition) => -position.rz;
export const getSvgScale = (position: GamePosition) => position.scale;
export const AnimatedG = Animated.createAnimatedComponent(G);

export interface SelectorObjectS {
  visible?: boolean;
  selected?: boolean;
}

export const selectorDefaultProps: CommonPathProps = {
  // @ts-ignore
  cursor: "pointer",
  fillOpacity: 0.3,
};

let cpt = 1;
export const useSvgId = () => {
  const [id] = useState(() => cpt++);
  return "i" + id;
};
