import React from "react";
import { RectObject } from "./rect";
import { RectLineObject } from "./rectLine";
import { TransformObject } from "./transform";

interface Props {
  height: number;
  border?: number;
  back?: boolean;
  color?: string;
  image?: string;
  innerBorder?: string;
  children?: any;
}

export const CardFaceObject = ({
  height,
  border = 0.066 * height,
  color,
  image,
  back,
  innerBorder,
  children,
}: Props) => {
  return (
    <TransformObject {...(back ? { ry: 180, z: -0.2 } : { z: 1.2 })}>
      {color || image ? (
        <RectObject
          width={0.666 * height - border}
          height={height - border}
          depth={0}
          radius={0.02 * height}
          image={image}
          color={color || "#ffffff"}
        >
          {children}
        </RectObject>
      ) : innerBorder ? (
        <RectLineObject
          width={0.666 * height - border}
          height={height - border}
          color={innerBorder}
          radius={0.02 * height}
        >
          {children}
        </RectLineObject>
      ) : (
        children
      )}
    </TransformObject>
  );
};
