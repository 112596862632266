import { GameDefinition } from "../engine/definition";
import { backgammonGame } from "./backgammon/game";
import { buzzerGame } from "./buzzer/game";
import { cataneGame } from "./catane/game";
import { codeNameGame } from "./codeName/game";
import { theGameGame } from "./theGame/game";

export const appGames: GameDefinition[] = [
  codeNameGame,
  theGameGame,
  backgammonGame,
  cataneGame,
  buzzerGame,
  // dudoGame,
  // beloteGame,
  // coincheGame,
  // tockGame,
];
