import React, { useEffect, useRef } from "react";
import { BufferGeometry, Event } from "three";
import { invisibleMaterial } from "../../engine/_old.viewT/material";
import { useMeshObject } from "../../engine/_old.viewT/object";
import { GeometryObject } from "./geometry";
import { TransformObject } from "./transform";

interface Props {
  geometry: BufferGeometry;
  around?: boolean;
  visible?: boolean;
  selected?: boolean;
  depth?: number;
  onClick: () => void;
  children?: any;
}

export const GeometrySelectorObject = ({
  geometry,
  around,
  onClick,
  visible,
  selected,
  depth = 0,
  children = null,
}: Props) => {
  const object = useMeshObject(geometry, invisibleMaterial, { shadow: false });

  const onClickRef = useRef(onClick);
  if (onClickRef.current !== onClick) onClickRef.current = onClick;

  useEffect(() => {
    const onClickHandler = (event: Event) => {
      event.cancelBubble = true;
      onClickRef.current();
    };
    object.addEventListener("click", onClickHandler);

    return () => {
      object.removeEventListener("click", onClickHandler);
    };
  }, [object]);

  return (
    <>
      {children}
      {visible || selected ? (
        <TransformObject z={0.05} scale={around ? 1.05 : undefined}>
          <GeometryObject
            geometry={geometry}
            shadow={false}
            depth={depth * 1.1}
            color={selected ? "#4caf50" : "#ffffff"}
            opacity={0.5}
          />
        </TransformObject>
      ) : null}
    </>
  );
};
