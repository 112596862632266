import React, { useEffect, useMemo } from "react";
import { Group } from "three";
import { degToRad } from "../../engine/position";
import { GroupObject } from "./group";

interface Props {
  x?: number;
  y?: number;
  z?: number;
  rx?: number;
  ry?: number;
  rz?: number;
  scale?: number;
  children: any;
}

export const TransformObject = ({ x = 0, y = 0, z = 0, rx = 0, ry = 0, rz = 0, scale = 1, children }: Props) => {
  const group = useMemo(() => new Group(), []);

  useEffect(() => {
    group.position.set(x, y, z);
    group.rotation.set(degToRad * rx, degToRad * ry, degToRad * rz);
    group.scale.set(scale, scale, scale);
  }, [x, y, z, rx, ry, rz, group.position, group.rotation, group.scale, scale]);

  return <GroupObject group={group}>{children}</GroupObject>;
};
