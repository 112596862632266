import { GameDefinition } from "../../engine/definition";
import { codeNameGetViewsT } from "./_old.viewsT/views";
import cover from "./assets/cover.jpg";
import { CodeNameAction, codeNameResolvers } from "./resolvers";
import { CodeNameState, codeNameStackDef } from "./shared";
import { codeNameGetViewsS } from "./views";

export const codeNameGame: GameDefinition<CodeNameState, CodeNameAction> = {
  _id: "codeName",
  label: "Code Name",
  players: "2-8+",
  timer: "15mn",
  description: [
    "Codenames est un jeu d’association d’idées pour 2 à 8 joueurs (voire plus) dans lequel les joueurs, répartis en deux équipes, devront tour à tour faire deviner à leurs coéquipiers un ensemble de mots qui leur sont attribués.",
    "Une seule règle : à chaque manche la personne qui fait deviner ne peut donner qu’un seul indice, suivi d’un chiffre correspondant au nombre de mots à trouver.",
    "Prenez donc garde de ne pas découvrir les mots de vos concurrents ou pire … le mot Piège.",
  ],
  rules: "https://www.iello.fr/regles/Codenames_regles.pdf",
  youtube: "szYTmx0bYyw",
  amazon: "B01FJRLCMI",
  cover,
  stage: 2,
  version: 3,
  getViewsS: codeNameGetViewsS,
  getViewsT: codeNameGetViewsT,
  stackDef: codeNameStackDef,
  resolvers: codeNameResolvers,
};
