import { CSSProperties, useEffect } from "react";
import { Mesh, MeshPhongMaterial, ShapeBufferGeometry } from "three";
import { useRenderer } from "../../engine/contexts";

interface Props {
  text: string;
  width?: number;
  height?: number;
  size?: number;
  bold?: boolean;
  italic?: boolean;
  color?: string;
  opacity?: number;
  style?: CSSProperties;
}

export const TextObject = ({ text, size = 20, opacity = 1, color = "#000000" }: Props) => {
  const { group, engine } = useRenderer();

  useEffect(() => {
    const text_ = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    const shapes = engine.font.generateShapes(text_, 0.75 * size);
    const geometry = new ShapeBufferGeometry(shapes, Math.ceil(0.1 * size * engine.density));
    geometry.center();
    const opacityObject = opacity < 1 ? { transparent: true, opacity } : {};
    const material = new MeshPhongMaterial({ color, ...opacityObject });
    const object = new Mesh(geometry, material);
    group.add(object);
    return () => {
      group.remove(object);
      geometry.dispose();
      material.dispose();
    };
  }, [group, size, text, color, opacity, engine.font, engine.density]);

  return null;
};
