import React, { useState } from "react";
import { StyleSheet } from "react-native";
import { Dialog, Text, TextInput, Button } from "react-native-paper";
import { useRoom } from "../../engine/room";

const styles = StyleSheet.create({
  dialog: { width: "100%", maxWidth: 300, alignSelf: "center" },
  text: { textAlign: "center" },
  textInput: { marginTop: 24 },
});

interface Props {
  visible: boolean;
  onDismiss: () => any;
}

export const AddDeviceDialog = ({ visible, onDismiss }: Props) => {
  const [code, setCode] = useState("");
  const [{ game, settings }, roomDispatch] = useRoom();
  const views = game.getViewsS(settings);

  return (
    <Dialog {...{ visible, onDismiss }} onDismiss={onDismiss} style={styles.dialog}>
      <Dialog.Title>Ajouter un participant</Dialog.Title>
      <Dialog.Content>
        <Text style={styles.text}>
          Ouvrez &quot;Rejoindre une partie&quot; dans l&apos;application web puis renseignez le code à six chiffres
        </Text>
        <TextInput
          mode="outlined"
          value={code}
          onChangeText={(v) => setCode(v)}
          style={styles.textInput}
          label={`Code joueur`}
        />
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          onPress={async () => {
            await roomDispatch({ type: "connectDevice", viewId: views[0]._id, code });
            onDismiss();
          }}
        >
          Ajouter
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
