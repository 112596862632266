import React from "react";
import { StyleSheet } from "react-native";
import { Dialog } from "react-native-paper";
import { GameDefinition } from "../../engine/definition";
import { GameList } from "../components/GameList";

const styles = StyleSheet.create({
  dialog: { width: "100%", maxWidth: 180 * 5 + 4, alignSelf: "center" },
  content: { paddingLeft: 0, paddingRight: 0, paddingBottom: 0 },
});

interface Props {
  visible: boolean;
  onDismiss: () => any;
  onSelect: (game: GameDefinition) => any;
}

export const SelectGameDialog = ({ visible, onDismiss, onSelect }: Props) => {
  return (
    <Dialog {...{ visible, onDismiss }} style={styles.dialog}>
      <Dialog.Title>Choix du jeu</Dialog.Title>
      <Dialog.Content style={styles.content}>
        <GameList onSelect={onSelect} />
      </Dialog.Content>
    </Dialog>
  );
};
