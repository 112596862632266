import React from "react";
import { useDevice } from "../../engine/device";
import { useRoom } from "../../engine/room";
import { useRoomState } from "../../engine/roomState";
import { LayoutPage } from "../components/Layout";
import { RoomPage } from "./RoomPage";
import { WelcomePage } from "./WelcomePage";

export const HomePage = () => {
  const { roomId } = useDevice();
  const [room] = useRoom();
  const [s] = useRoomState();
  return !roomId ? <WelcomePage /> : room && s ? <RoomPage /> : <Loading />;
};

const Loading = () => {
  return <LayoutPage>{}</LayoutPage>;
};
