import { useNavigation } from "@react-navigation/native";
import { StackActions } from "@react-navigation/routers";
import React from "react";
import { Image, StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";
import { useDevice, useDevicePinUpdater } from "../../engine/device";

const styles = StyleSheet.create({
  container: { alignItems: "center", height: "100%", justifyContent: "center" },
  qrCode: { alignItems: "center", margin: 24 },
  qrCodeSkeleton: { height: 132 },
  text: { margin: 24, marginTop: 0, textAlign: "center" },
});

export const JoinGameScreen = () => {
  const { qrCode, pinCode, roomId } = useDevice();
  const navigation = useNavigation();
  useDevicePinUpdater();
  if (roomId) {
    navigation.dispatch(StackActions.popToTop());
  }

  return (
    <View style={styles.container}>
      <View style={styles.qrCode}>
        {qrCode ? (
          <Image source={typeof qrCode === "string" ? { uri: qrCode } : qrCode} width={132} height={132} />
        ) : (
          <View style={styles.qrCodeSkeleton} />
        )}
      </View>
      <View>
        {/* <Text style={styles.text}>Scannez ce qrcode avec l&apos;application ou entrez ce code</Text> */}
        <Text style={styles.text}>Indiquez ce code à l{"'"}organisateur.</Text>
      </View>
      <View>
        <Text style={styles.text}>{pinCode}</Text>
      </View>
    </View>
  );
};
