import React from "react";
import { ComponentOf } from "../../engine/elementType";
import { CardObjectS } from "../../objects/card";
import { CircleObjectS } from "../../objects/circle";
import { DiceObjectS } from "../../objects/dice";
import { HalfCircleObjectS } from "../../objects/halfCircle";
import { HexagonObjectS } from "../../objects/hexagon";
import { RectObjectS } from "../../objects/rect";
import { TextObjectS } from "../../objects/text";
import { TransformObjectS } from "../../objects/transform";
import { cataneImages, CatanePawnType, catanePlayersColors } from "./data";
import { cataneAreas as areas, cataneElementTypes as types, CataneContext } from "./shared";

export const CataneTileObjectS = ({ data: { type } }: ComponentOf<typeof types.tile, CataneContext>) => (
  <>
    <HexagonObjectS height={150} fill="white" stroke="black" radius={5} />
    <HexagonObjectS height={140} image={cataneImages[type]} radius={5} />
  </>
);

export const CataneCardObjectS = ({
  data: { type },
  context: { playerIndex },
  placement,
}: ComponentOf<typeof types.card, CataneContext>) => {
  const visible =
    playerIndex === undefined
      ? areas.deck.isOn()(placement)
      : areas.deck.isOn()(placement) || areas.playerHand.isOn([playerIndex])(placement);
  return visible ? (
    <CardObjectS height={90} image={cataneImages[type]} stroke="black" />
  ) : (
    <CardObjectS height={90} fill="#333333" stroke="black" />
  );
};

export const CataneDevCardObjectS = ({
  data: { type },
  context: { playerIndex },
  placement,
}: ComponentOf<typeof types.devCard, CataneContext>) => {
  const visible =
    playerIndex === undefined
      ? areas.playerBoard.isOn()(placement)
      : areas.playerBoard.isOn()(placement) || areas.playerHand.isOn([playerIndex])(placement);
  return visible ? (
    <>
      <CardObjectS height={90} fill={"#333333"} stroke="black" />
      <TextObjectS fill="white" size={20} text={type} />
    </>
  ) : (
    <CardObjectS height={90} fill="#333333" stroke="black" />
  );
};

export const CataneTokenObjectS = ({ data: { value } }: ComponentOf<typeof types.token>) => (
  <>
    <CircleObjectS size={32} fill="#b6a05a" stroke="black" />
    <TextObjectS text={value.toString()} size={20} />
  </>
);

export const CatanePawnObjectS = ({ data: { playerIndex, type } }: ComponentOf<typeof types.pawn>) =>
  type === CatanePawnType.ROAD ? (
    <RectObjectS width={15} height={40} radius={5} fill={catanePlayersColors[playerIndex]} stroke="black" />
  ) : type === CatanePawnType.COLONY ? (
    <CircleObjectS size={30} fill={catanePlayersColors[playerIndex]} stroke="black" />
  ) : type === CatanePawnType.CITY ? (
    <HexagonObjectS height={32} fill={catanePlayersColors[playerIndex]} stroke="black" />
  ) : null;

export const CataneDiceObjectS = ({ data: { value, count } }: ComponentOf<typeof types.dice>) => (
  <DiceObjectS value={value} size={30} count={count} fill="white" stroke="black" dots={{ fill: "black" }} />
);

export const CataneThiefObjectS = () => <CircleObjectS size={50} fill={"#333333"} stroke="black" />;

export const CatanePortObjectS = ({ data: { type } }: ComponentOf<typeof types.port>) => (
  <>
    <HalfCircleObjectS height={75} width={37.5} fill={"white"} stroke="black" />
    <TransformObjectS x={5}>
      <HalfCircleObjectS
        height={65}
        width={27.5}
        image={type ? cataneImages[type] : undefined}
        fill={type ? "none" : "#BBBBBB"}
      />
    </TransformObjectS>
  </>
);
