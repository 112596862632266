import React from "react";
import { RectObject } from "./rect";
import { RectSelectorObject } from "./rectSelector";

interface Props {
  width?: number;
  height?: number;
  radius?: number;
  color?: string;
  image?: string;
  onClick: () => void;
  selected?: boolean;
  children?: any;
  style?: any;
}

export const ButtonObject = ({
  width = 200,
  height = 100,
  selected,
  color = selected ? "#4caf50" : "#ffffff",
  onClick,
  ...rest
}: Props) => {
  return (
    <RectSelectorObject width={width} height={height} depth={1} onClick={onClick} visible={false}>
      <RectObject
        width={width}
        height={height}
        depth={selected ? 5 : 10}
        radius={0.04 * height}
        color={color}
        {...rest}
      />
    </RectSelectorObject>
  );
};
