import React, { useEffect, useRef } from "react";
import { BufferGeometry, Event } from "three";
import { invisibleMaterial } from "../../engine/_old.viewT/material";
import { useMeshObject } from "../../engine/_old.viewT/object";
import { GeometryLineObject } from "./geometryLine";
import { TransformObject } from "./transform";

interface Props {
  lineGeometry: BufferGeometry;
  surfaceGeometry: BufferGeometry;
  around?: boolean | number;
  visible?: boolean;
  selected?: boolean;
  depth?: number;
  onClick: () => void;
  children?: any;
}

export const GeometrySelectorLineObject = ({
  lineGeometry,
  surfaceGeometry,
  around,
  onClick,
  visible,
  selected,
  depth = 0,
  children = null,
}: Props) => {
  const object = useMeshObject(surfaceGeometry, invisibleMaterial, { shadow: false });

  const onClickRef = useRef(onClick);
  if (onClickRef.current !== onClick) onClickRef.current = onClick;

  useEffect(() => {
    const onClickHandler = (event: Event) => {
      event.cancelBubble = true;
      onClickRef.current();
    };
    object.addEventListener("click", onClickHandler);
    object.scale.set(1.2, 1.2, 1.2);

    return () => {
      object.removeEventListener("click", onClickHandler);
    };
  }, [object]);

  return (
    <>
      {children}
      {visible || selected ? (
        <TransformObject z={depth + 0.2} scale={!around ? undefined : around === true ? 1.2 : around}>
          <GeometryLineObject geometry={lineGeometry} color={selected ? "#4caf50" : "#ffffff"} />
        </TransformObject>
      ) : null}
    </>
  );
};
