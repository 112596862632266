import React from "react";
import { Shape } from "three";
import { getShapeGeometry } from "../../engine/_old.viewT/geometry";
import { createUseFactory } from "../../engine/factory";
import { GeometryObject } from "./geometry";

interface Props {
  color: string;
  backColor?: string;
  image?: string;
  size: number;
  opacity?: number;
  depth?: number;
  depthRadius?: number;
  children?: any;
}

export const CircleObject = ({ size, depth = 1, depthRadius = Math.min(depth / 3, 2), ...rest }: Props) => {
  const geometry = useCircleFactory({ size, depth, depthRadius });

  return (
    <GeometryObject
      {...{ geometry, depth, imageCenter: [-size / 2, -size / 2], imageRepeat: [1 / size, 1 / size] }}
      {...rest}
    />
  );
};

const circleFactory = ({ size, depth, depthRadius }) => {
  const shape = getCircleShape(size - depthRadius);
  return getShapeGeometry({ shape, depth, depthRadius, shapeSegments: size });
};

export const useCircleFactory = createUseFactory(circleFactory);

export const getCircleShape = (size) => {
  const shape = new Shape();

  shape.arc(0, 0, size / 2, 0, 2 * Math.PI, true);

  shape.autoClose = true;
  return shape;
};
