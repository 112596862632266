import { GameDefinition } from "../../engine/definition";
import { backgammonGetViewsT } from "./_old.viewsT/views";
import cover from "./assets/cover.jpg";
import { BackgammonAction, backgammonResolvers } from "./resolvers";
import { BackgammonState, backgammonStackDef } from "./shared";
import { backgammonGetViewsS } from "./views";

export const backgammonGame: GameDefinition<BackgammonState, BackgammonAction> = {
  _id: "backgammon",
  label: "Backgammon",
  players: "2",
  timer: "15mn",
  description: [
    "Le backgammon est un jeu de hasard raisonné pour deux joueurs pratiqué sur un tablier avec des dés. Un joueur gagne lorsqu'il retire tous ses pions du tablier.",
    "Le backgammon fait partie de la famille des jeux de tables, une des plus anciennes catégories de jeu de tablier.",
  ],
  youtube: "iczFArXfrGk",
  amazon: "B002D2Q4NK",
  rules: "http://www.jeu-backgammon.net/regles-backgammon.html",
  cover,
  stage: 2,
  version: 1,
  getViewsT: backgammonGetViewsT,
  getViewsS: backgammonGetViewsS,
  stackDef: backgammonStackDef,
  resolvers: backgammonResolvers,
};
