import React, { useMemo } from "react";
import { Path, CommonPathProps } from "react-native-svg";
import { PathD, selectorDefaultProps } from "../engine/svg";
import { ImageObjectS } from "./image";
import { TouchableObjectS } from "./touchable";

interface Props extends CommonPathProps {
  width: number;
  height: number;
  image?: any;
  radius?: number;
  opacity?: number;
}

export const HalfCircleObjectS = ({ width, height, radius, onPress, image, ...rest }: Props) => {
  const d = useMemo(() => getHalfCirclePath(width, height, radius), [height, radius, width]);
  return (
    <TouchableObjectS onPress={onPress}>
      {image && <ImageObjectS width={2 * width} height={height} image={image} d={d} />}
      <Path fill="none" d={d} {...rest} />
    </TouchableObjectS>
  );
};

export const HalfCircleSelectorObjectS = (props: Props) => {
  return <HalfCircleObjectS {...selectorDefaultProps} {...props} />;
};

export const getHalfCirclePath = (width, height, radius = 0) => {
  const d = new PathD();

  const dx = width;
  const dy = height / 2;
  const r = Math.min(radius, dx, dy);

  if (r) {
    d.moveTo(0, -dy + r)
      .curveTo(r, -dy + r, 0, -dy)
      .curveTo(dx, 0, dx, -dy)
      .curveTo(r, dy, dx, dy)
      .curveTo(0, dy - r, 0, dy);
  } else {
    d.moveTo(0, -dy)
      .curveTo(dx, 0, dx, -dy)
      .curveTo(0, dy, dx, dy)
      .close();
  }

  return d.toString();
};
