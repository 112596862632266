import React from "react";
import { Dialog } from "react-native-paper";

interface Props {
  visible: boolean;
  onDismiss: () => any;
  youtubeId: string;
}

export const GameVideoDialog = ({ visible, onDismiss, youtubeId }: Props) => {
  return (
    <Dialog {...{ visible, onDismiss }}>
      <iframe
        style={{
          position: "absolute",
          width: 560,
          height: 315,
          top: "50%",
          left: "50%",
          transform: "translate(-280px, -157px)",
          backgroundColor: "#000000",
        }}
        width="560"
        height="315"
        src={"https://www.youtube.com/embed/" + youtubeId}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Dialog>
  );
};
