import { GameArea } from "./area";
import { GameElement } from "./element";
import { GameElementType, ElementOf } from "./elementType";
import { Point, Points } from "./points";
import { shuffle } from "./random";
import { InvalidAction } from "./resolvers";
import { GameState } from "./state";

export const filterConditionsElement = (conditions: ((element) => boolean)[]) => {
  const remainingConditions = conditions.slice();
  return (element) => {
    const index = remainingConditions.findIndex((condition) => condition(element));
    if (index !== -1) {
      remainingConditions.splice(index, 1);
      return true;
    }
    return false;
  };
};

export const filterIds = (ids) => (element) => ids.includes(element._id);
export const range = (n: number) => new Array(n).fill(null).map((_v, index) => index);

interface Destination {
  add(elements: GameElement[], z?: number): any;
}

interface Source {
  remove(elements: GameElement[]): any;
}

export class Elements<S extends GameState = GameState, E extends GameElement = GameElement> {
  private $waitBetween = false;
  private $waitBefore = false;
  private $waitAfter = false;

  constructor(protected state: S, private elements: E[], private source?: Source) {}

  public count() {
    return this.elements.length;
  }

  public expect(condition: (elements: E[]) => boolean) {
    if (!condition(this.elements)) throw new InvalidAction();
    return this;
  }

  public expectLength(expected: number) {
    return this.expect((elements) => elements.length === expected);
  }

  public expectMinLength(expected: number) {
    return this.expect((elements) => elements.length >= expected);
  }

  public remove() {
    if (this.source) {
      this.source.remove(this.elements);
      this.source = undefined;
    }
    return this;
  }

  public forEach(iterator: (element: E, index: number) => void) {
    this.elements.forEach(iterator);
    return this;
  }

  public take(quantity: number, allowPartial?: boolean): Elements<S, E> {
    if (!allowPartial) this.expectMinLength(quantity);
    const selected = this.elements.slice(0, quantity);
    return new Elements<S, E>(this.state, selected, this.source);
  }

  public select(conditions: ((element: E) => boolean)[], allowPartial?: boolean) {
    const selected = this.elements.filter(filterConditionsElement(conditions));
    if (!allowPartial && selected.length !== conditions.length) throw new InvalidAction();
    return new Elements<S, E>(this.state, selected, this.source);
  }

  public filter<EE extends E>(condition: ((element: E) => element is EE) | ((element: E) => boolean)) {
    const selected = this.elements.filter(condition);
    return new Elements<S, EE>(this.state, selected as any, this.source as any);
  }

  public exclude(condition: (element: E) => boolean) {
    const selected = this.elements.filter((element) => !condition(element));
    return new Elements<S, E>(this.state, selected, this.source);
  }

  public filterType<TT extends GameElementType>(type: TT): Elements<S, ElementOf<TT>> {
    return this.filter(type.is) as any;
  }

  public forceType<TT extends GameElementType>(_type: TT): Elements<S, ElementOf<TT>> {
    return this as any;
  }

  public excludeType<TT extends GameElementType>(type: TT): Elements<S, E extends ElementOf<TT> ? never : E> {
    return this.exclude(type.is) as any;
  }

  public filterArea(area: GameArea, indexes?: number[]): Elements<S, E> {
    return this.filter(area.isOn(indexes));
  }

  public excludeArea(area: GameArea, indexes?: number[]): Elements<S, E> {
    return this.exclude(area.isOn(indexes));
  }

  public filterData(data: Partial<E["data"]>): Elements<S, E> {
    const conds = Object.entries(data);
    const condition = (element: E) => {
      for (const [key, value] of conds) {
        if (element.data[key] !== value) return false;
      }
      return true;
    };
    return this.filter(condition);
  }

  public excludeData(data: Partial<E["data"]>) {
    const conds = Object.entries(data);
    const condition = (element: E) => {
      for (const [key, value] of conds) {
        if (element.data[key] !== value) return false;
      }
      return true;
    };
    return this.exclude(condition);
  }

  public moveTo(destination: Destination, z?: number) {
    this.remove();

    if (this.$waitBefore) {
      this.state.wait();
    }

    if (this.$waitBetween) {
      let zz = z;
      this.elements.forEach((element, index) => {
        if (index) {
          this.state.wait();
        }
        destination.add([element], zz);
        if (zz) zz++;
      });
    } else {
      destination.add(this.elements, z);
    }

    if (this.$waitAfter) {
      this.state.wait();
    }

    return new Elements(this.state, this.elements);
  }

  public distributeTo(area: GameArea, distribution: (element: GameElement) => number[], z?: number) {
    this.remove();
    return this.elements.map((element) => {
      const indexes = distribution(element);
      area.add(this.state, [element], indexes, z);
      this.state.wait();
      return element;
    });
  }

  public setData(updater: Partial<E["data"]> | ((element: E, index: number) => Partial<E["data"]>)) {
    const updater_ = typeof updater === "object" ? () => updater : updater;
    this.forEach((element, index) => {
      const data = updater_(element, index);
      this.state.setElementData(element, data);
    });
    return this;
  }

  public incData(name: keyof E["data"], inc = 1) {
    this.forEach((element) => {
      this.state.setElementData(element, { [name]: element.data[name] + inc });
    });
    return this;
  }

  public getFirst(): E | undefined {
    return this.elements[0];
  }

  public getPoints() {
    const points = this.elements.map((element) => element.placement.data.slice(0, 2) as Point);
    return new Points(this.state, points);
  }

  public includes = (element: E) => this.elements.includes(element);

  public shuffle() {
    this.elements = shuffle(this.state)(this.elements);
    return this;
  }

  public reverse() {
    this.elements.reverse();
    return this;
  }

  public toArray() {
    return this.elements;
  }

  public mapData<N extends keyof E["data"]>(name: N): E["data"][N][] {
    return this.elements.map((element) => element.data[name]);
  }

  public waitBetween() {
    this.$waitBetween = true;
    return this;
  }
  public waitBefore() {
    this.$waitBefore = true;
    return this;
  }
  public waitAfter() {
    this.$waitAfter = true;
    return this;
  }
}

export const createElements = (state: GameState) => (elements: GameElement[]) => new Elements(state, elements);
