import React from "react";
import { Shape } from "three";
import { getShapeGeometry } from "../../engine/_old.viewT/geometry";
import { createUseFactory } from "../../engine/factory";
import { GeometryObject } from "./geometry";

interface Props {
  width: number;
  height: number;
  color: string;
  backColor?: string;
  image?: any;
  opacity?: number;
  radius?: number;
  depth?: number;
  depthRadius?: number;
  shadow?: boolean;
  children?: any;
}

export const TriangleObject = ({
  width,
  height,
  radius = 0,
  depth = 1,
  depthRadius = Math.min(depth / 3, 2),
  ...rest
}: Props) => {
  const repeat = 1 / Math.max(width, height);
  const geometry = useTriangleFactory({ width, height, depth, depthRadius, radius });

  return (
    <GeometryObject
      {...{ geometry, depth, imageCenter: [-width / 2, -height / 2], imageRepeat: [repeat, repeat] }}
      {...rest}
    />
  );
};

const triangleFactory = ({ width, height, depth, depthRadius, radius }) => {
  const shape = getTriangleShape(
    width - 2 * depthRadius,
    height - 2 * depthRadius,
    Math.min(radius, width / 2 - depthRadius, height / 2 - depthRadius),
  );
  return getShapeGeometry({ shape, depth, depthRadius, shapeSegments: 4 });
};

const getTriangleShape = (width, height, _radius) => {
  const shape = new Shape();
  const dx = width / 2;
  const dy = height / 2;

  shape.moveTo(-dx, -dy);
  shape.lineTo(dx, -dy);
  shape.lineTo(0, dy);
  shape.autoClose = true;

  return shape;
};

const useTriangleFactory = createUseFactory(triangleFactory);
