import React, { useMemo } from "react";
import { useFirebaseAuth, logout, facebookLogin } from "./firebase";
import { UserAction, UserContextValue, UserContext } from "./user";

const dispatch = (action: UserAction) => {
  switch (action.type) {
    case "facebookLogin":
      return facebookLogin();
    case "logout":
      return logout();
  }
};

export const UserLoader = ({ children }) => {
  const [user, initialising] = useFirebaseAuth();

  const value = useMemo(() => [user || null, dispatch] as UserContextValue<true>, [user]);

  return initialising ? null : <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
