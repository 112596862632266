import { ExtrudeBufferGeometry, ShapeBufferGeometry, BufferGeometry } from "three";

export const getShapeGeometry = ({
  shape,
  shapeSegments = 1,
  depth = 1,
  depthRadius = Math.min(depth / 3, 2),
  density = 1,
}) => {
  return depth && depth * density >= 1
    ? new ExtrudeBufferGeometry(shape, {
        steps: 1,
        curveSegments: Math.ceil(shapeSegments * density),
        depth: depth - 2 * depthRadius,
        bevelEnabled: true,
        bevelThickness: depthRadius,
        bevelSize: depthRadius,
        bevelSegments: Math.ceil(depthRadius),
      }).translate(0, 0, depthRadius)
    : new ShapeBufferGeometry(shape, shapeSegments).translate(0, 0, depth);
};

export const getShapeLineGeometry = ({ shape }) => {
  return new BufferGeometry().setFromPoints(shape.getPoints());
};
