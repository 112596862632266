import { Point } from "../../engine/points";
import clayImage from "./assets/CLAY3.jpg";
import cornImage from "./assets/CORN4.jpg";
import desertImage from "./assets/DESERT3.jpg";
import oreImage from "./assets/ORE3.jpg";
import woodImage from "./assets/WOOD3.jpg";
import woolImage from "./assets/WOOL3.jpg";

export enum CataneTileType {
  ORE = "ORE",
  CLAY = "CLAY",
  CORN = "CORN",
  WOOD = "WOOD",
  WOOL = "WOOL",
  DESERT = "DESERT",
}

export enum CataneCardType {
  ORE = "ORE",
  CLAY = "CLAY",
  CORN = "CORN",
  WOOD = "WOOD",
  WOOL = "WOOL",
}
export enum CataneDevCardType {
  KNIGHT = "KNIGHT",
  POINT = "POINT",
  MONOPOLY = "MONOPOLY",
  ROADS = "ROADS",
  INVENTION = "INVENTION",
}

export enum CatanePawnType {
  COLONY = "COLONY",
  CITY = "CITY",
  ROAD = "ROAD",
}

export const catanePlayersColors = ["#2196f3", "#f44336", "#ff9800", "#ffffff"];
export const catanePlayersNames = ["Bleu", "Rouge", "Orange", "Blanc"];

export const cataneResources = [
  CataneTileType.WOOD,
  CataneTileType.WOOL,
  CataneTileType.CORN,
  CataneTileType.CLAY,
  CataneTileType.ORE,
];

export const cataneCosts = {
  [CatanePawnType.ROAD]: [CataneTileType.WOOD, CataneTileType.CLAY],
  [CatanePawnType.COLONY]: [CataneTileType.WOOD, CataneTileType.CLAY, CataneTileType.CORN, CataneTileType.WOOL],
  [CatanePawnType.CITY]: [
    CataneTileType.CORN,
    CataneTileType.CORN,
    CataneTileType.ORE,
    CataneTileType.ORE,
    CataneTileType.ORE,
  ],
  DEV_CARD: [CataneTileType.CORN, CataneTileType.WOOL, CataneTileType.ORE],
};

export const cataneImages = {
  [CataneTileType.CLAY]: clayImage,
  [CataneTileType.CORN]: cornImage,
  [CataneTileType.DESERT]: desertImage,
  [CataneTileType.ORE]: oreImage,
  [CataneTileType.WOOD]: woodImage,
  [CataneTileType.WOOL]: woolImage,
};

export const cataneColors = {
  [CataneTileType.CLAY]: "#ff7000",
  [CataneTileType.CORN]: "#ffd280",
  [CataneTileType.DESERT]: "#fdff96",
  [CataneTileType.ORE]: "#73868d",
  [CataneTileType.WOOD]: "#03800c",
  [CataneTileType.WOOL]: "#fffcd6",
};

export const cataneTilesTypes = [
  ...new Array(4).fill(CataneTileType.WOOD),
  ...new Array(3).fill(CataneTileType.ORE),
  ...new Array(4).fill(CataneTileType.WOOL),
  ...new Array(4).fill(CataneTileType.CORN),
  ...new Array(3).fill(CataneTileType.CLAY),
  ...new Array(1).fill(CataneTileType.DESERT),
];

export const cataneDevCardTypes = [
  ...new Array(14).fill(CataneDevCardType.KNIGHT),
  ...new Array(5).fill(CataneDevCardType.POINT),
  ...new Array(2).fill(CataneDevCardType.MONOPOLY),
  ...new Array(2).fill(CataneDevCardType.INVENTION),
  ...new Array(2).fill(CataneDevCardType.ROADS),
];

export const catanePawnTypes = [
  ...new Array(4).fill(CatanePawnType.CITY),
  ...new Array(5).fill(CatanePawnType.COLONY),
  ...new Array(15).fill(CatanePawnType.ROAD),
];

export const catanePortTypes = [...cataneResources, ...new Array(4).fill(null)];

export const cataneCardsTypes = cataneResources.flatMap((type) => new Array(19).fill(type));

export const cataneTokens = [5, 2, 6, 3, 8, 10, 9, 12, 11, 4, 8, 10, 9, 4, 5, 6, 3, 11];

export const cataneTokenPoints: Point[] = [
  [0, 2],
  [0.5, 3],
  [1, 4],
  [2, 4],
  [3, 4],
  [3.5, 3],
  [4, 2],
  [3.5, 1],
  [3, 0],
  [2, 0],
  [1, 0],
  [0.5, 1],
  [1, 2],
  [1.5, 3],
  [2.5, 3],
  [3, 2],
  [2.5, 1],
  [1.5, 1],
  [2, 2],
];

export const catanePortsPoints: Point[] = [
  [2.25, 0],
  [3.75, 0],
  [4.5, 1.5],
  [4.5, 3.5],
  [3.75, 5],
  [2.25, 5],
  [0.75, 4],
  [0, 2.5],
  [0.75, 1],
];
