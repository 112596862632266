import { DefaultTheme, Theme } from "react-native-paper";

export const paperTheme: Theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: "#cf1717",
    accent: "#f2af58",
  },
};
