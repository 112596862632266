import React, { useState, useEffect, useRef } from "react";
import { MovingDuration } from "../engine/entities";
import { GamePosition } from "../engine/position";
import { getSvgX, getSvgY, getSvgR, getSvgScale } from "../engine/svg";

const transition = `transform ${MovingDuration / 1000}s`;

export interface AnimatedObjectState {
  ready: boolean;
  prevX: number;
  prevY: number;
  prevR: number;
  prevScale: number;
  target: GamePosition;
  toAnimate: number;
}

interface Props {
  target: GamePosition;
  state?: AnimatedObjectState;
  children: React.ReactNode;
}

export const AnimatedObjectS = ({ target, state: externalState, children }: Props) => {
  const [state] = useState(() => externalState ?? ({} as AnimatedObjectState));
  const animationRef = useRef(0);
  const [, setVersion] = useState(0);

  if (!state.ready) {
    state.prevX = getSvgX(target);
    state.prevY = getSvgY(target);
    state.prevR = getSvgR(target);
    state.prevScale = getSvgScale(target);
    state.target = target;
    state.toAnimate = 0;
    state.ready = true;
  }

  if (
    state.target.x !== target.x ||
    state.target.y !== target.y ||
    state.target.z !== target.z ||
    state.target.rz !== target.rz ||
    state.target.scale !== target.scale
  ) {
    state.target = target;
    state.toAnimate!++;
  }

  useEffect(() => {
    if (state.toAnimate !== animationRef.current) {
      state.prevX = getSvgX(state.target);
      state.prevY = getSvgY(state.target);
      state.prevR = getSvgR(state.target);
      state.prevScale = getSvgScale(state.target);
      animationRef.current = state.toAnimate!;
      setVersion((v) => v + 1); // reload
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.toAnimate]);

  return (
    <g
      style={{
        transition,
        transform: `translate(${state.prevX}px, ${state.prevY}px) rotate(${state.prevR}deg) scale(${state.prevScale})`,
      }}
    >
      {children}
    </g>
  );
};
