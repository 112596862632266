import { backgammonAreas, BackgammonState } from "./shared";

export const backgammonCanMoveFrom = (
  s: BackgammonState,
  playerIndex: number,
  fromIndex: number,
  dist: number,
): [boolean, number] => {
  if (dist === 25 && fromIndex !== 0 && fromIndex !== 25) {
    return [false, 0];
  }
  const elements = s
    .on("BOARD")
    .on(fromIndex)
    .toArray();
  return [!!elements.length && elements[0].data.playerIndex === playerIndex, elements.length];
};

export const backgammonCanMoveTo = (s: BackgammonState, playerIndex, fromIndex, toIndex, dist): [boolean, number] => {
  if (toIndex < 1 || toIndex > 24) {
    if (dist <= 6) {
      const fromDist = backgammonGetDist(playerIndex)(fromIndex);
      if (toIndex === 0 || toIndex === 25 || fromDist === dist) {
        return [true, -1];
      }
    }
    return [false, 0];
  }
  const elements = s
    .on("BOARD")
    .on(toIndex)
    .toArray();
  return [elements.length <= 1 || elements[0].data.playerIndex === playerIndex, elements.length];
};

export const backgammonGetMaxDist = (s: BackgammonState, playerIndex) => {
  const dists = s
    .getElements()
    .filterArea(backgammonAreas.board)
    .filterData({ playerIndex })
    .toArray()
    .map((element) => element.placement.data[0])
    .map(backgammonGetDist(playerIndex));

  return Math.max(0, ...dists);
};

export const backgammonGetDist = (playerIndex) => (index) => (playerIndex ? 25 - index : index);
