import { range } from "../../engine/elements";
import { GameResolvers, InvalidAction, LoadAction } from "../../engine/resolvers";
import { BackgammonPhase, BackgammonState, backgammonElementTypes as types } from "./shared";
import { backgammonCanMoveFrom, backgammonCanMoveTo, backgammonGetMaxDist } from "./utils";

export type BackgammonAction =
  | LoadAction
  | { type: "START" }
  | { type: "MOVE"; playerIndex: number; fromIndex: number; toIndex: number }
  | { type: "NO_MOVE"; playerIndex: number };

export type BackgammonResolvers = GameResolvers<BackgammonState, BackgammonAction>;

const initResolvers: BackgammonResolvers = {
  LOAD: (s) => {
    s.setData({ scores: [0, 0], currentPlayer: 0 });

    const pawns = range(2).flatMap((playerIndex) =>
      range(15).map((index) => types.pawn.create(`pawn_${playerIndex}_${index}`, { playerIndex })),
    );
    s.addElements(pawns).moveTo(s.on("OUT"));

    const dices = range(2).flatMap((playerIndex) =>
      range(2).map((index) =>
        types.dice.create(`dice_${playerIndex}_${index}`, {
          playerIndex,
          played: 0,
          value: 6,
          count: 0,
        }),
      ),
    );
    s.addElements(dices).moveTo(s.on("DICES"));
  },
  START: (s) => {
    s.setPhase(BackgammonPhase.PLAY);

    const place = (playerIndex: number, quantity: number, to: number) => {
      return s
        .on("OUT")
        .on(playerIndex)
        .getElements()
        .take(quantity)
        .reverse()
        .waitAfter()
        .moveTo(s.on("BOARD").on(to));
    };

    place(0, 2, 24);
    place(1, 2, 1);

    place(0, 5, 6);
    place(1, 5, 19);

    place(0, 3, 8);
    place(1, 3, 17);

    place(0, 5, 13);
    place(1, 5, 12);

    let values: number[];
    do {
      values = range(2).map(() => s.fn.randomD6());
    } while (values[0] === values[1]);

    s.wait(750);

    range(2).forEach((playerIndex) => {
      s.on("DICES")
        .on(playerIndex)
        .getElements()
        .forceType(types.dice)
        .take(1)
        .setData({ value: values[playerIndex] })
        .incData("count")
        .moveTo(s.on("DICES").on("BOARD"));
    });

    s.setData({ currentPlayer: values[0] > values[1] ? 0 : 1 });

    return s;
  },
};

const playResolvers: BackgammonResolvers = {
  MOVE: (s, { playerIndex, fromIndex, toIndex }) => {
    const dist = backgammonGetMaxDist(s, playerIndex);
    if (
      s.data.currentPlayer !== playerIndex ||
      !backgammonCanMoveFrom(s, playerIndex, fromIndex, dist) ||
      !backgammonCanMoveTo(s, playerIndex, fromIndex, toIndex, dist)
    ) {
      throw new InvalidAction();
    }

    const dices = s.on("DICES").on("BOARD").getElements().forceType(types.dice);
    // check double
    const values = dices.mapData("value");
    const double = values[0] === values[1];

    // check dices
    const diff = playerIndex ? toIndex - fromIndex : fromIndex - toIndex;
    dices
      .excludeData({ played: 1 })
      .filterData({ value: diff })
      .take(1)
      .incData("played", double ? 0.5 : 1);

    let destination = s.on("BOARD").on(toIndex);
    if (toIndex < 1 || toIndex > 24) {
      destination = s.on("OUT").on(playerIndex);
    } else {
      // check capture
      const jail = s.on("BOARD").on(playerIndex ? 25 : 0);
      destination
        .getElements()
        .filterData({ playerIndex: 1 - playerIndex })
        .moveTo(jail);
    }

    // move dice
    s.on("BOARD").on(fromIndex).getElements().take(1).moveTo(destination);

    // check next turn
    if (!s.on("DICES").on("BOARD").getElements().excludeData({ played: 1 }).toArray().length) {
      nextPlayer(s, 1 - playerIndex);
    }

    return s;
  },
  // remote it
  NO_MOVE: (s, { playerIndex }) => {
    if (s.data.currentPlayer !== playerIndex) throw new InvalidAction();
    nextPlayer(s, 1 - playerIndex);
  },
};

const nextPlayer = (s: BackgammonState, playerIndex: number) => {
  // return dice
  s.wait(500);
  s.on("DICES").on("BOARD").getElements().setData({ played: 0 }).moveTo(s.on("DICES"));

  // throw dices
  s.setData({ currentPlayer: playerIndex });
  s.on("DICES")
    .on(playerIndex)
    .getElements()
    .forceType(types.dice)
    .take(2)
    .setData(() => ({ value: s.fn.randomD6() }))
    .incData("count")
    .moveTo(s.on("DICES").on("BOARD"));
};

export const backgammonResolvers = {
  INIT: initResolvers,
  PLAY: playResolvers,
};
