import React, { useMemo, useCallback, useState, useRef } from "react";
import { useObjectVal } from "react-firebase-hooks/database";
import { firebaseDatabase } from "./firebase";
import { GameAction, getActionPatch } from "./resolvers";
import { useRoom } from "./room";
import { RoomStateProvider, RoomStateContextValue, useStatePatches } from "./roomState";
import { GameState, GameStatePatch } from "./state";

interface Props {
  children: React.ReactNode;
}

export const RoomStateLoader = ({ children }: Props) => {
  const [room] = useRoom<true>();
  const [patches] = useObjectVal<GameStatePatch[]>(room ? firebaseDatabase.ref(`roomStates/${room._id}`) : null);
  const [version, setVersion] = useState(0);
  const stateRef = useRef<GameState | null>(null);

  const dispatch = useCallback(
    async (action: GameAction) => {
      if (!room || !patches || !stateRef.current) return;
      if (action.type === "UNDO") {
        if (patches.length <= 1) return;
        await firebaseDatabase.ref(`roomStates/${room._id}/${patches.length - 1}`).remove();
      } else {
        const patch = getActionPatch(stateRef.current!, room, action);
        await firebaseDatabase.ref(`roomStates/${room._id}/${patches.length}`).set(patch);
      }
    },
    [patches, room],
  );

  useStatePatches(stateRef as any, room, patches!, setVersion);

  const value = useMemo(() => [stateRef.current, dispatch, version] as RoomStateContextValue, [dispatch, version]);

  return <RoomStateProvider value={value}>{children}</RoomStateProvider>;
};
