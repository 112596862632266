import { useEffect, useMemo } from "react";
import { Mesh, Object3D, Vector3, Line } from "three";
import { useRenderer } from "../contexts";
import { degToRad, GamePosition } from "../position";

export const useObject = (object) => {
  const { group } = useRenderer();

  useEffect(() => {
    group.add(object);

    return () => {
      group.remove(object);
    };
  }, [group, object]);
};

export const useMeshObject = (geometry, material, { shadow = true, opacity = 1 }) => {
  const object = useMemo(() => new Mesh(geometry, material), [geometry, material]);
  useObject(object);

  useEffect(() => {
    if (shadow && opacity === 1) {
      object.castShadow = true;
    }
    object.receiveShadow = true;
  }, [object, shadow, opacity]);

  return object;
};

export const useLineObject = (geometry, material) => {
  const object = useMemo(() => new Line(geometry, material), [geometry, material]);
  useObject(object);

  return object;
};

export const setObjectPosition = (object: Object3D, position: GamePosition) => {
  object.position.set(position.x, position.y, position.z);
  object.rotation.set(degToRad * position.rx, 0, degToRad * position.rz);
  object.scale.set(position.scale, position.scale, position.scale);
};

export const getObjectPosition = (object: Object3D, group: Object3D) => {
  if (object.parent === group) {
    const p = object.position;
    const s = object.scale;
    const rx = object.rotation.x / degToRad;
    const ry = object.rotation.y / degToRad;
    const rz = object.rotation.z / degToRad;

    return new GamePosition(p.x, p.y, p.z, rx, ry, rz).multiplyScale(s.x);
  } else {
    const p = object.getWorldPosition(new Vector3());
    const s = object.getWorldScale(new Vector3());
    group.worldToLocal(p);
    group.worldToLocal(s);
    const r = object.rotation.z;
    const rx = object.rotation.x + object.parent!.rotation.x - group.rotation.x;

    return new GamePosition(p.x, p.y, p.z)
      .multiplyScale(s.x)
      .addRz(r / degToRad)
      .addRX(rx / degToRad);
  }
};
