import React, { useMemo } from "react";
import { Path, CommonPathProps } from "react-native-svg";
import { PathD, selectorDefaultProps } from "../engine/svg";
import { TouchableObjectS } from "./touchable";

interface Props extends CommonPathProps {
  width: number;
  height: number;
  opacity?: number;
}

export const TriangleObjectS = ({ width, height, onPress, ...props }: Props) => {
  const d = useMemo(() => getTrianglePath(width, height), [height, width]);
  return (
    <TouchableObjectS onPress={onPress}>
      <Path fill="none" d={d} {...props} />
    </TouchableObjectS>
  );
};

export const TriangleSelectorObjectS = (props: Props) => {
  return <TriangleObjectS {...selectorDefaultProps} {...props} />;
};

export const getTrianglePath = (width = 0, height = 0) => {
  const d = new PathD();

  const dx = width / 2;
  const dy = height / 2;

  d
    .moveTo(-dx, dy)
    .lineTo(dx, dy)
    .lineTo(0, -dy).close;

  return d.toString();
};
