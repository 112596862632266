import { gridDistribution, Point } from "../../engine/points";
import { GameResolvers, LoadAction } from "../../engine/resolvers";
import { CodeNameAgentType, codeNameWords } from "./data";
import {
  codeNameElementTypes as elementTypes,
  codeNameAreas as areas,
  CodeNamePhase,
  CodeNameState,
  codeNameElementTypes as types,
} from "./shared";

export type CodeNameAction = LoadAction | { type: "START" } | { type: "DISCOVER"; point: Point };
export type CodeNameResolvers = GameResolvers<CodeNameState, CodeNameAction>;

const initResolvers: CodeNameResolvers = {
  LOAD: (s) => {
    const blueFirst = s.fn.randomBoolean();
    const agents = [
      ...new Array(1).fill(CodeNameAgentType.ASSASSIN),
      ...new Array(7).fill(CodeNameAgentType.NEUTRAL),
      ...new Array(8).fill(CodeNameAgentType.BLUE),
      ...new Array(8).fill(CodeNameAgentType.RED),
      blueFirst ? CodeNameAgentType.BLUE : CodeNameAgentType.RED,
    ];
    const words = s.fn.shuffle(codeNameWords).slice(0, 25);
    const wordsTypes = s.fn.shuffle(agents);

    s.setData({ firstTeam: blueFirst ? CodeNameAgentType.BLUE : CodeNameAgentType.RED });

    s.addElements(
      words.map((word, index) => types.word.create(`word_${index}`, { word, type: wordsTypes[index], visible: false })),
    ).moveTo(s.on("DECK"));

    s.addElements(agents.map((type, index) => types.agent.create(`agent_${type}_${index}`, { type }))).moveTo(
      s.on("DECK"),
    );
  },
  START: (s) => {
    s.setPhase(CodeNamePhase.PLAY);

    s.on("DECK")
      .on(elementTypes.word)
      .getElements()
      .distributeTo(areas.board, gridDistribution(5));
  },
};

const playResolvers: CodeNameResolvers = {
  DISCOVER: (s, { point }) => {
    const word = s
      .on("BOARD")
      .on(point)
      .getElements()
      .filterType(elementTypes.word)
      .filterData({ visible: false })
      .take(1)
      .setData({ visible: true })
      .getFirst()!;

    const type = word.data.type;

    s.on("DECK")
      .on(type)
      .getElements()
      .take(1)
      .moveTo(s.on("BOARD").on(point));
  },
};

export const codeNameResolvers = {
  INIT: initResolvers,
  PLAY: playResolvers,
};
