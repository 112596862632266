import React from "react";
import { CardObjectS } from "../../objects/card";
import { TextObjectS } from "../../objects/text";
import { theGameColors } from "./data";
import { theGameAreas as areas } from "./shared";

export const TheGameCardObjectS = ({ data: { value }, context: { playerIndex }, placement }) => {
  const visible =
    playerIndex === undefined
      ? areas.spot.isOn()(placement)
      : areas.spot.isOn()(placement) || areas.playerHand.isOn([playerIndex])(placement);

  return visible ? (
    <>
      <CardObjectS height={300} fill={theGameColors.card} stroke="black" />
      <TextObjectS text={value.toString()} fill="#ffffff" size={100} />
    </>
  ) : (
    <CardObjectS height={300} fill={theGameColors.card} stroke="black" />
  );
};
