import React from "react";
import { Circle, CircleProps } from "react-native-svg";
import { SelectorObjectS, selectorDefaultProps } from "../engine/svg";
import { TouchableObjectS } from "./touchable";

interface Props extends CircleProps {
  size: number;
}

export const CircleObjectS = ({ size, onPress, ...rest }: Props) => {
  return (
    <TouchableObjectS onPress={onPress}>
      <Circle fill="none" r={size / 2} {...rest} />
    </TouchableObjectS>
  );
};

export const CircleSelectorObjectS = ({ visible, selected, ...rest }: Props & SelectorObjectS) => {
  return <CircleObjectS fill={selected ? "grey" : visible ? "white" : "none"} {...selectorDefaultProps} {...rest} />;
};
