import React, { useContext, useEffect, useRef } from "react";
import { useRoom } from "./room";

export interface DeviceData {
  _id: string;
  user: string | null;
  type: string | null;
  roomId: string | null;
  viewId: string | null;
  userPhotoURL: string;
}

export type DeviceContextValue = {
  _id: string;
  qrCode: string;
  pinCode?: number;
  pinCodeUpdate?: number;
  roomId: string | null;
  viewId: string | null;
  inApp?: string;
};

const DeviceContext = React.createContext<DeviceContextValue>({
  _id: "",
  qrCode: "",
  pinCode: 0,
  pinCodeUpdate: 0,
  roomId: null,
  viewId: null,
});

export const DeviceProvider = DeviceContext.Provider;

export const useDevice = () => useContext(DeviceContext);

const delay = 30000;

export const useDevicePinUpdater = () => {
  const { _id: deviceId, pinCode, pinCodeUpdate } = useDevice();
  const [, roomDispatch] = useRoom();

  const updatingRef = useRef(false);

  useEffect(() => {
    if (pinCodeUpdate === Infinity) return;
    const updatePinCode = async () => {
      if (updatingRef.current) return;
      updatingRef.current = true;
      await roomDispatch({ type: "updatePinCode" });
      updatingRef.current = false;
    };

    const now = Date.now();
    if (!pinCode || !pinCodeUpdate || pinCodeUpdate <= now - delay) {
      updatePinCode();
    } else {
      const timeout = setTimeout(updatePinCode, +pinCodeUpdate - now + delay);
      return () => clearTimeout(timeout);
    }
  }, [pinCodeUpdate, pinCode, deviceId, roomDispatch]);
};
