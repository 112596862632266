import { GameArea } from "../../engine/area";
import { GameElementType, ElementOf } from "../../engine/elementType";
import { mapStack } from "../../engine/stack2";
import { GameState } from "../../engine/state";

// settings

export interface BuzzerSettings {
  nbPlayers: number;
}

// areas

export const buzzerAreas = {
  board: new GameArea("BOARD", 1),
};

// elements

export const buzzerElementTypes = {
  buzzer: new GameElementType<"BUZZER", { playerIndex: number; locked: boolean; score: number; active: boolean }>(
    "BUZZER",
  ),
};

export type BuzzerElement = ElementOf<typeof buzzerElementTypes.buzzer>;

// stack

export const buzzerStackDef = mapStack({
  children: {},
});

// state

export enum BuzzerPhase {
  INIT = "INIT",
  PLAY = "PLAY",
}

export interface BuzzerData {
  nbPlayers: number;
  activated: number;
}

export type BuzzerState = GameState<BuzzerElement, BuzzerData, BuzzerPhase, typeof buzzerStackDef>;
