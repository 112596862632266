import React from "react";
import { View, StyleSheet } from "react-native";
import { Card, Divider } from "react-native-paper";
import { LayoutPage } from "../components/Layout";
import { JoinGameScreen } from "../screens/JoinGameScreen";
import { NewGameScreen } from "../screens/NewGameScreen";
import { paperTheme } from "../theme";

const styles = StyleSheet.create({
  card: { flexBasis: 300, margin: 20, opacity: 0.98, elevation: 7 },
  cardTitle: { color: paperTheme.colors.primary },
});

export const WelcomePage = () => {
  return (
    <LayoutPage>
      <View style={{ justifyContent: "center", flexGrow: 1 }}>
        <View style={{ flexDirection: "row", alignItems: "stretch", justifyContent: "center" }}>
          <Card style={styles.card}>
            <Card.Title title="Rejoindre une partie" titleStyle={styles.cardTitle}></Card.Title>
            <Divider />
            <Card.Content>
              <JoinGameScreen />
            </Card.Content>
          </Card>
          <Card style={styles.card}>
            <Card.Title title="Créer une partie" titleStyle={styles.cardTitle}></Card.Title>
            <Divider />
            <Card.Content style={{ padding: 0 }}>
              <NewGameScreen />
            </Card.Content>
          </Card>
        </View>
      </View>
    </LayoutPage>
  );
};
