import React, { useContext, MutableRefObject, useLayoutEffect, useRef } from "react";
import { GameAction, resolveAction } from "./resolvers";
import { ExtendedRoom } from "./room";
import { GameState, GameStatePatch } from "./state";

export type RoomStateContextValue<S = GameState, A = GameAction> = [S, (action: A) => any, number];

const RoomStateContext = React.createContext<RoomStateContextValue>([{} as any, () => void 0, 0]);

export const RoomStateProvider = RoomStateContext.Provider;

export const useRoomState = <S extends GameState = GameState, A extends GameAction = any>(): [
  S,
  (action: A) => any,
  number,
] => {
  return useContext(RoomStateContext) as any;
};

export const useStatePatches = (
  stateRef: MutableRefObject<GameState | null>,
  room: ExtendedRoom | null,
  patches: GameStatePatch[] | undefined,
  setVersion: (f: (v: number) => number) => void,
) => {
  const currentRoomRef = useRef(room);
  if (currentRoomRef.current?._id !== room?._id) {
    stateRef.current = null;
  }
  if (room && patches) {
    if (!stateRef.current || patches.length <= stateRef.current.patchState.patchIndex)
      stateRef.current = createState(room, patches);
    const state = stateRef.current;
    const memory = state.patchState;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (memory.subActionIndex < patches[memory.patchIndex].subActions.length - 1) {
        // apply subAction
        memory.subActionIndex++;
        const action = patches[memory.patchIndex].subActions[memory.subActionIndex];
        state.dispatch(action);
        if (action.type === "WAIT") {
          memory.waitUntill = Date.now() + action.time;
          state.pending = true;
          break;
        }
      } else if (memory.patchIndex < patches.length - 1) {
        // apply next patch
        memory.patchIndex++;
        memory.subActionIndex = -1;
      } else {
        // stop
        state.pending = false;
        break;
      }
    }
    state.reloadStacks();
  }

  const waitUntill = stateRef.current?.patchState.waitUntill;
  useLayoutEffect(() => {
    if (waitUntill) {
      const delay = Math.max(0, waitUntill - Date.now());
      const timeout = setTimeout(() => {
        setVersion((v) => v + 1);
        stateRef.current!.patchState.waitUntill = 0;
      }, delay);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [setVersion, stateRef, waitUntill]);
};

export const createState = (room: ExtendedRoom, patches: GameStatePatch[]) => {
  const state = new GameState(room.game.stackDef);
  state.randomState = room.initialRandomState;

  for (const patch of patches) {
    resolveAction(room, room.game)(state, patch.action);
  }

  state.patchState = {
    patchIndex: patches.length - 1,
    subActionIndex: patches[patches.length - 1].subActions.length - 1,
    waitUntill: 0,
  };

  return state;
};
