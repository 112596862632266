import React from "react";
import { RectObjectS } from "../../objects/rect";
import { TextObjectS } from "../../objects/text";
import { buzzerBackColors, buzzerColors } from "./data";

export const BuzzerObjectS = ({ data: { score, playerIndex, locked, active } }) => (
  <>
    <RectObjectS
      width={100}
      height={50}
      fill={active ? buzzerColors[playerIndex] : buzzerBackColors[playerIndex]}
      stroke={active ? buzzerBackColors[playerIndex] : buzzerColors[playerIndex]}
      opacity={locked ? 0.5 : 1}
    />
    <TextObjectS
      text={score}
      opacity={locked ? 0.5 : 1}
      fill={active ? buzzerBackColors[playerIndex] : buzzerColors[playerIndex]}
    />
  </>
);
