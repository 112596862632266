import { GameArea } from "../../engine/area";
import { GameElementType, ElementOf } from "../../engine/elementType";
import { mapStack, stack, arrayStack } from "../../engine/stack2";
import { GameState } from "../../engine/state";

// areas

export const theGameAreas = {
  deck: new GameArea("DECK", 1),
  playerHand: new GameArea("PLAYER_HAND", 1),
  spot: new GameArea("SPOT", 1),
};
const areas = theGameAreas;

// elements

export const theGameElementTypes = {
  card: new GameElementType<"CARD", { value: number }>("CARD"),
};

export type TheGameElement = ElementOf<typeof theGameElementTypes.card>;

// stacks

const compareCards = (a, b) => a.data.value - b.data.value;
const sortCards = (elements) => elements.sort(compareCards);

export const theGameStackDef = mapStack({
  children: {
    DECK: stack({ area: areas.deck, data: [4] }),
    SPOT: arrayStack({
      length: 4,
      area: areas.spot,
    }),
    HAND: arrayStack({
      length: 6,
      area: areas.playerHand,
      forceSort: sortCards,
    }),
  },
});

// state

export enum TheGamePhase {
  INIT = "INIT",
  PLAY = "PLAY",
}

interface TheGameData {
  nbPlayers: number;
  spotsValues: number[];
  cardsPerPlayer: number;
}

export type TheGameSettings = { nbPlayers: number };
export type TheGameState = GameState<TheGameElement, TheGameData, TheGamePhase, typeof theGameStackDef>;
