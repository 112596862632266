import { GameArea } from "../../engine/area";
import { GameElementType, ElementOf } from "../../engine/elementType";
import { mapStack, arrayStack } from "../../engine/stack2";
import { GameState } from "../../engine/state";

// areas

export const backgammonAreas = {
  board: new GameArea("BOARD", 1),
  out: new GameArea("OUT", 1),
  dices: new GameArea("DICE", 1),
};
const areas = backgammonAreas;

// elements

export const backgammonElementTypes = {
  pawn: new GameElementType<"PAWN", { playerIndex: number }>("PAWN"),
  dice: new GameElementType<"DICE", { playerIndex: number; played: number; value: number; count: number }>("DICE"),
};

export type BackgammonElement =
  | ElementOf<typeof backgammonElementTypes.pawn>
  | ElementOf<typeof backgammonElementTypes.dice>;

// stack

export const backgammonStackDef = mapStack({
  children: {
    BOARD: arrayStack({ length: 26, area: areas.board }),
    OUT: arrayStack({
      length: 2,
      area: areas.out,
      getGroup: (element) => element.data.playerIndex,
    }),
    DICES: arrayStack({
      length: 3,
      keys: { BOARD: 2 },
      area: backgammonAreas.dices,
      getGroup: (element) => element.data.playerIndex,
    }),
  },
});

// state

export enum BackgammonPhase {
  INIT = "INIT",
  PLAY = "PLAY",
}

export interface BackgammonData {
  scores: number[];
  currentPlayer: number;
}

export type BackgammonState = GameState<BackgammonElement, BackgammonData, BackgammonPhase, typeof backgammonStackDef>;
