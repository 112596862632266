import { GamePosition } from "../../../engine/position";
import { GameView, RoomView } from "../../../engine/views";
import { backgammonAreas as areas, backgammonElementTypes as types, BackgammonState } from "../shared";
import { useBackgammonBackground, useBackgammonPlayerButtons } from "./entities";
import { BackgammonPawnObjectT, BackgammonDiceObjectT } from "./elements";

export const backgammonGetViewsT = (): RoomView[] => [
  { _id: "player/0", label: "Joueur 1", view: playerView, context: { playerIndex: 0 } },
  { _id: "player/1", label: "Joueur 2", view: playerView, context: { playerIndex: 1 } },
  { _id: "player/all", label: "Joueur 1 et 2", view: playerView, context: { playerIndex: "all" } },
  { _id: "board", label: "Observateurs", view: boardView },
];

const boardView = new GameView<BackgammonState>()
  .addEntities(useBackgammonBackground)
  .setElementType(types.pawn, BackgammonPawnObjectT)
  .setElementType(types.dice, BackgammonDiceObjectT)
  .setArea(areas.out, ([p, z]) =>
    p
      ? new GamePosition(-650, 400)
          .addVerticalLine(-10, z)
          .addZ(35)
          .addRX(90)
      : new GamePosition(-650, -400)
          .addVerticalLine(10, z)
          .addZ(35)
          .addRX(-90),
  )
  .setArea(areas.board, ([index, z]) =>
    index === 0
      ? new GamePosition(0, -36).addVerticalLine(-72, z).addZ(20)
      : index === 25
      ? new GamePosition(0, 36).addVerticalLine(72, z).addZ(20)
      : index < 13
      ? new GamePosition(0, -370)
          .addX(index > 6 ? 20 : -20)
          .addLine(90, index - 6.5)
          .addVerticalLine(72, z % 5)
          .addZ(6 + 5 * Math.floor(z / 5))
          .addY(15 * Math.floor(z / 5))
      : new GamePosition(0, 370)
          .addX(index > 18 ? -20 : 20)
          .addLine(-90, index - 18.5)
          .addVerticalLine(-72, z % 5)
          .addZ(6 + 10 * Math.floor(z / 5))
          .addY(-15 * Math.floor(z / 5)),
  )
  .setArea(areas.dices, ([i, z]) =>
    i === 2
      ? new GamePosition(250, 0).addLine(50, z).addZ(5)
      : new GamePosition(700, -300).addVerticalLine(600, i).addLine(-50, z),
  );

const playerView = boardView.fork().addEntities(useBackgammonPlayerButtons);
