import React from "react";
import { CommonPathProps } from "react-native-svg";
import { RectSelectorObjectS, RectObjectS } from "./rect";

interface Props extends CommonPathProps {
  disabled?: boolean;
  width?: number;
  height?: number;
  radius?: number;
  children?: React.ReactNode;
}

export const ButtonObjectS = ({ width = 200, height = 100, children, ...rest }: Props) => {
  const r = Math.min(width, height) / 10;
  return (
    <>
      <RectObjectS
        width={width}
        height={height}
        fill="white"
        stroke="black"
        radius={r}
        fillOpacity={0.5}
        strokeOpacity={0.5}
        {...rest}
      />
      {children}
      <RectSelectorObjectS width={width} height={height} fill="transparent" radius={r} {...rest} />
    </>
  );
};
