import React, { useEffect, useMemo } from "react";
import { Group } from "three";
import { getObjectPosition } from "../../engine/_old.viewT/object";
import { ObjectTransition } from "../../engine/_old.viewT/transitions";
import { useRenderer } from "../../engine/contexts";
import { easeOutQuad } from "../../engine/easing";
import { degToRad, GamePosition } from "../../engine/position";
import { GroupObject } from "./group";
import { RectObject } from "./rect";
import { TextObject } from "./text";
import { TransformObject } from "./transform";

interface Props {
  value: number;
  color?: string;
  count?: number;
  size: number;
  played?: number;
  mapFaces?: { [s: string]: any };
}

export const DiceObject = ({ value, played = 0, size, count, mapFaces, color = "#ffffff" }: Props) => {
  const { engine } = useRenderer();
  const group = useMemo(() => new Group(), []);

  useEffect(() => {
    const [rx, ry, rz] = valueRotations[value - 1];
    if (!group.position.z) {
      group.position.setZ(size / 2);
      group.rotation.set(degToRad * rx, degToRad * ry, degToRad * rz);
    } else {
      const from = getObjectPosition(group, group.parent!);
      const to = new GamePosition(
        0,
        0,
        size / 2,
        Math.round(Math.random() * 10) * 360 + rx,
        Math.round(Math.random() * 10) * 360 + ry,
        Math.round(Math.random() * 2) * 360 + rz,
      );
      const transition = new ObjectTransition(group, from, 700, easeOutQuad).linearTo(to);
      engine.transitions.push(transition);
    }
  }, [value, count, size, group, engine.transitions]);

  const dice = useMemo(() => {
    const opacity = 1 - 0.5 * played;
    const textProps = { size: 0.8 * size, opacity };

    const faces_ = mapFaces ? { ...faces, ...mapFaces } : faces;

    return (
      <>
        <TransformObject z={-size / 2}>
          <RectObject
            {...{ color, opacity, height: size, width: size, radius: 0.1, depth: size, depthRadius: size / 10 }}
          />
        </TransformObject>
        <TransformObject z={size / 2 + 0.2}>
          <TextObject {...textProps} text={faces_["1"]} />
        </TransformObject>
        <TransformObject y={-size / 2 - 0.2} rx={90}>
          <TextObject {...textProps} text={faces_["2"]} />
        </TransformObject>
        <TransformObject x={-size / 2 - 0.2} ry={-90}>
          <TextObject {...textProps} text={faces_["3"]} />
        </TransformObject>
        <TransformObject x={size / 2 + 0.2} ry={90}>
          <TextObject {...textProps} text={faces_["4"]} />
        </TransformObject>
        <TransformObject y={size / 2 + 0.2} rx={-90}>
          <TextObject {...textProps} text={faces_["5"]} />
        </TransformObject>
        <TransformObject z={-size / 2 - 0.2} ry={180}>
          <TextObject {...textProps} text={faces_["6"]} />
        </TransformObject>
      </>
    );
  }, [size, color, played, mapFaces]);

  return <GroupObject group={group}>{dice}</GroupObject>;
};

const faces = {
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
};

const valueRotations: [number, number, number][] = [
  [0, 0, 0],
  [-90, 0, 0],
  [0, 90, 0],
  [0, -90, 0],
  [90, 0, 0],
  [0, -180, 0],
];
