import { useState, useCallback } from "react";
import { LayoutChangeEvent, LayoutRectangle } from "react-native";
import { createElements } from "./elements";
import { createPoints } from "./points";
import { randomBoolean, randomD6, randomInt, shuffle } from "./random";
import { GameState } from "./state";

export const getFn = (state: GameState) => ({
  createElements: createElements(state),
  createPoints: createPoints(state),
  randomInt: randomInt(state),
  randomBoolean: randomBoolean(state),
  randomD6: randomD6(state),
  shuffle: shuffle(state),
});

export const capitalize = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

export const useComponentLayout = () => {
  const [layout, setLayout] = useState<LayoutRectangle | null>(null);

  const onLayout = useCallback((event: LayoutChangeEvent) => {
    setLayout(event.nativeEvent.layout);
  }, []);

  return [layout, onLayout] as [typeof layout, typeof onLayout];
};
