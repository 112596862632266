import React from "react";
import { StyleSheet } from "react-native";
import { Dialog, List, RadioButton } from "react-native-paper";
import { DeviceData } from "../../engine/device";
import { RoomView } from "../../engine/views";

const styles = StyleSheet.create({
  dialog: { width: "100%", maxWidth: 300, alignSelf: "center" },
  content: { paddingLeft: 0, paddingRight: 0 },
  item: { paddingLeft: 16 },
});

interface Props {
  visible: boolean;
  onDismiss: () => any;
  views: RoomView[];
  device?: DeviceData;
  onSelect: (value) => any;
}

export const ChangeViewDialog = ({ visible, onDismiss, views, device, onSelect }: Props) => {
  return (
    <Dialog {...{ visible, onDismiss }} style={styles.dialog}>
      <Dialog.Title>{device && device.user}</Dialog.Title>
      <Dialog.Content style={styles.content}>
        {views.map((view) => (
          <List.Item
            style={styles.item}
            key={view._id}
            title={view.label}
            onPress={() => {
              onSelect(view._id);
            }}
            left={() => (
              <RadioButton
                status={device && view._id === device.viewId ? "checked" : "unchecked"}
                value={view._id}
                onPress={() => onSelect(view._id)}
              />
            )}
          />
        ))}
      </Dialog.Content>
    </Dialog>
  );
};
