import React, { useContext } from "react";
import { GameDefinition } from "./definition";
import { DeviceData } from "./device";

export type RoomAction =
  | { type: "setDeviceView"; device: DeviceData; viewId: string }
  | { type: "connectDevice"; viewId: string; code: string }
  | { type: "leaveRoom" }
  | { type: "updatePinCode" }
  | { type: "createRoom"; game: GameDefinition; settings: any }
  | { type: "changeGame"; game: GameDefinition; settings: any };

export interface RoomData {
  _id: string;
  gameId: string;
  settings: any;
  hostId: any;
  initialRandomState: any;
}

export interface ExtendedRoom extends RoomData {
  roomId: string;
  game: GameDefinition;
  isHost: boolean;
  useDevices: () => DeviceData[];
}

export type RoomContextValue<N extends boolean> = [
  N extends true ? ExtendedRoom | null : ExtendedRoom,
  (action: RoomAction) => any,
];

const RoomContext = React.createContext<RoomContextValue<true>>([null, () => void 0]);

export const RoomProvider = RoomContext.Provider;

export const useRoom = <N extends boolean = false>() => useContext(RoomContext) as RoomContextValue<N>;
