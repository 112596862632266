import React from "react";
import { CommonPathProps, G, CircleProps } from "react-native-svg";
import { CircleObjectS } from "./circle";
import { RectObjectS } from "./rect";

interface Props extends CommonPathProps {
  size: number;
  value: number;
  played?: number;
  count?: number;
  dots?: CircleProps;
  opacity?: number;
}

export const DiceObjectS = (props: Props) => {
  const { size, value, dots = {}, played = 0 } = props;
  const face = faces[value - 1] ?? [];
  const opacity = 1 - played / 2;
  return (
    <G fillOpacity={opacity} strokeOpacity={opacity}>
      <RectObjectS width={size} height={size} radius={size / 8} {...props} />
      <G scale={size}>
        {face.map(({ x, y }, index) => (
          <CircleObjectS size={0.2} cx={x} cy={y} key={index} {...dots} />
        ))}
      </G>
    </G>
  );
};

const faces = [
  [{ x: 0, y: 0 }],
  [
    { x: -0.25, y: -0.25 },
    { x: 0.25, y: 0.25 },
  ],
  [
    { x: -0.25, y: -0.25 },
    { x: 0, y: 0 },
    { x: 0.25, y: 0.25 },
  ],
  [
    { x: -0.25, y: -0.25 },
    { x: -0.25, y: 0.25 },
    { x: 0.25, y: -0.25 },
    { x: 0.25, y: 0.25 },
  ],
  [
    { x: -0.25, y: -0.25 },
    { x: -0.25, y: 0.25 },
    { x: 0, y: 0 },
    { x: 0.25, y: -0.25 },
    { x: 0.25, y: 0.25 },
  ],
  [
    { x: -0.25, y: -0.25 },
    { x: -0.25, y: 0 },
    { x: -0.25, y: 0.25 },
    { x: 0.25, y: -0.25 },
    { x: 0.25, y: 0 },
    { x: 0.25, y: 0.25 },
  ],
];
